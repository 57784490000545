<triggered-page layout="none">
  <div class="page-content" fxLayout="column" fxLayoutGap="2em" fxLayoutAlign="start center" class="bg-card">

    <section id="our-mission-section" fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1200px"
            fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="space-between center"
            fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">
        <!-- Our Mission Text -->
        <div fxFlex="0 1 600px" fxLayout="column" fxLayoutGap="1em"
            fxFlex.lt-sm="1 1 auto" fxFlexOrder.lt-sm="2">
          <div fxLayout="column">
            <h1 class="mat-title margin-0">Our Mission</h1>
            <h2 class="mat-subheading-2 margin-0">Instant Support and Accountability</h2>
          </div>
          <mat-divider></mat-divider>
          <div>
            <p class="mat-body-1 margin-0">
              Our mission is to provide instant accountability and support to those struggling with addictions, unwanted behaviors and negative thought patterns.
            </p>
          </div>
        </div>

        <!-- World -->
        <div class="image-container"
            fxFlex="0 1 400px"
            fxFlex.lt-sm="1 1 auto" fxFlexOrder.lt-sm="1">
          <img src="assets/images/triggered-world.png" height="330" width="330" alt="A transformed world">
        </div>
      </div>
    </section>

    <section id="our-vision-section" class="bg-accent" fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1200px"
           fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="space-between center"
           fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">


        <!-- World -->
        <div class="image-container"
             fxFlex="0 1 400px"
             fxFlex.lt-sm="1 1 auto">
          <img src="assets/images/triggered-world-countries.png" width="400" height="218" alt="Countries with red outlines">
        </div>

        <!-- Our Mission Text -->
        <div fxFlex="0 1 600px" fxLayout="column" fxLayoutGap="1em"
             fxFlex.lt-sm="1 1 auto">
          <div fxLayout="column">
            <h1 class="mat-title margin-0">Our Vision</h1>
            <h2 class="mat-subheading-2 margin-0">A World Transformed</h2>
          </div>

          <mat-divider></mat-divider>

          <div>
            <p class="mat-body-1 margin-0">
              We desire to see individual lives restored, families rebuilt, communities strengthened and the world changed.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="our-values-section"  fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1200px" class="padding-bottom-0"
            fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="space-between center"
            fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">

        <!-- Our Mission Text -->
        <div fxFlex="0 1 600px" fxLayout="column" fxLayoutGap="1em"
             fxFlex.lt-sm="1 1 auto">
          <div fxLayout="column" class="w-100">
            <h1 class="mat-title margin-0">Our Values</h1>
            <h2 class="mat-subheading-2 margin-0">Because YOU Matter</h2>
          </div>
          <mat-divider></mat-divider>
          <div>
            <ul class="mat-body-1 margin-0">
              <li>You are loved</li>
              <li>You are not alone</li>
              <li>You can overcome</li>
              <li>You are never too far gone</li>
              <li>You will make a difference</li>
            </ul>
          </div>
        </div>

        <!-- World -->
        <div class="image-container"
            fxFlex="0 1 400px"
            fxFlex.lt-sm="1 1 auto">
          <img src="assets/images/triggered-family.jpg" width="400" height="530" alt="Man with his family">
        </div>
      </div>
    </section>

  </div>
</triggered-page>
