export type EventTypes =
  'initialized' |
  'error' |
  'warning' |
  'account_deleted' |
  'alert_sent' |
  'alert_abort' |
  'login' |
  'logout' |
  'logout_cancel' |
  'invite_accepted' |
  'invite_invalid_code' |
  'invite_skip_download' |
  'plan_selected' |
  'plan_subscribed' |
  'plan_cancelled' |
  'plan_cancelled_error' |
  'coupon_applied' |
  'coupon_removed' |
  'coupon_invalid' |
  'beta_group_invalid';

export abstract class EventLogger {
  abstract setUserId(userId: string);
  abstract logEvent(event: EventTypes | string, data?: any);
}
