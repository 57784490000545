import { Component, ContentChild, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'triggered-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  // NOTE: To have consistent heights, apply mat-card class here
  @HostBinding('class') readonly hostClass = '';

  @ContentChild('image') imageTemplate: TemplateRef<any>;
  @ContentChild('cardHeader') headerTemplate: TemplateRef<any>;
  @ContentChild('headerAction') headerAction: TemplateRef<any>;
  @ContentChild('content') contentTemplate: TemplateRef<any>;
  @ContentChild('actions') actionTemplate: TemplateRef<any>;

  @Input() margins: 0 | 1 | 2 | 3 = 1;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() header: string;
  @Input() subheader: string;
  @Input() hideActions: boolean;

  @Input() loading: boolean;
  @Input() loadingText?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
