import { IRecord } from '../../data/record';

/**
 * Days of week per ISO 8601
 */
 export enum Day {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface IAlertDetails extends IRecord {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  dayOfWeek: DayOfWeek;
  weekYear: number;
  week: number;

  partnerCount: number;
}

