import { Form } from '@triggered/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from '@triggered/common';

export class SubscriptionForm extends Form<Subscription> {

  constructor(value?: Subscription) {
    const init = value || new Subscription();
    super({
      id: new FormControl(init.id, []),
      planId: new FormControl(init?.planId, [Validators.required]),
      planName: new FormControl(init?.planName, [Validators.required]),
      ownerId: new FormControl(init?.ownerId, [Validators.required]),
    });

    this.originalValue = init;
    this.isNew = this.originalValue.id == null;
  }
}
