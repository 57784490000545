import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'triggered-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  @Input() layout: string = 'layout-1';
  @ContentChild('pageHeader', { static: true}) headerTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
