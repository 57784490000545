
import { DateTime as LuxonDate } from 'luxon';

export interface FirebaseTimestamp {
  seconds: number;
  nanoseconds: number;

  _seconds?: number;
  _nanoseconds?: number;
}

export class DateHelper {
  /**
   * Returns the Monday of the corresponding week
   * Per ISO 8601 standards
   **/
  static getStartOfWeek(date: Date) {
    const startOfWeek = LuxonDate.fromJSDate(date).toLocal().startOf('week');
    return startOfWeek.toJSDate();
  }

  /**
   * Returns the Sunday of the corresponding week
   * Per ISO 8601 standards
   **/
  static getEndOfWeek(date: Date) {
    const endOfWeek = LuxonDate.fromJSDate(date).toLocal().endOf('week');

    return endOfWeek.toJSDate();
  }

  static getWeek(year: number, weekNumber: number) {
    const weekDate = LuxonDate.fromObject({ weekNumber, weekYear: year });
    return {
      startOfWeek: weekDate.startOf('week').toJSDate(),
      endOfWeek: weekDate.endOf('week').toJSDate(),
    }
  }

  static getLocalDate(date?: Date, timezone?: string) {
    if (date && timezone) {
      return LuxonDate.fromJSDate(date).setZone(timezone);
    } else if (timezone) {
      return LuxonDate.now().setZone(timezone);
    } else if (date){
      return LuxonDate.fromJSDate(date).toLocal();
    } else {
      return LuxonDate.local();
    }
  }

  /**
   * Returns the Sunday of the corresponding week
   * Per ISO 8601 standards
   **/
  static getWeekCount(date: Date) {
    return LuxonDate.fromJSDate(date).toLocal().weekNumber;
  }

  static addDays(days: number, date?: Date) {
    const returnDate = date || new Date();
    returnDate.setDate(returnDate.getDate() + days);
    return returnDate;
  }

  static addYears(years: number, date?: Date) {
    const returnDate = date || new Date();
    returnDate.setFullYear(returnDate.getFullYear() + years);
    return returnDate;
  }
}

export class DateTimestamp extends Date {

  constructor(dateOrTimestamp?: FirebaseTimestamp | Date) {
    const date = DateTimestamp.convertToDate(dateOrTimestamp) || undefined;
    if(date) {
      super(date);
    } else {
      super();
    }
  }

  static local() {
    return LuxonDate.local();
  }

  static convertToDate(firebaseDate?: FirebaseTimestamp | Date): Date | null {
    if (firebaseDate == null) { return null; }

    if (firebaseDate instanceof Date) { return firebaseDate; }

    const milliseconds = (firebaseDate._seconds ?? firebaseDate.seconds) * 1000 + (firebaseDate._nanoseconds ?? firebaseDate.nanoseconds) / 1e6;
    return Number.isNaN(milliseconds) ? new Date(firebaseDate as any) : new Date(milliseconds);
  }

  /**
   *
   * @param first
   * @param second
   * @returns firstDate > secondDate = 1, secondDate > firstDate = -1, firstDate == secondDate = 0
   */
  static compare(first: FirebaseTimestamp | Date , second: FirebaseTimestamp | Date) {
    if(first == null && second == null) { return 0; }
    if(first == null) { return 1; }
    if(second == null) { return -1; }

    const firstDate = new DateTimestamp(first);
    const secondDate = new DateTimestamp(second);
    if (firstDate > secondDate) { return  1; }
    if (firstDate < secondDate) { return  -1; }
    return 0;
  }
}
