import { Plan } from '@triggered/common';
import { PlanForm } from './plan.model';
import { SubscriptionSettingsForm, SubscriptionSettingsIds, SubscriptionSettingsModel } from './subscription-settings.model';

export class SubscriptionPlanModel extends Plan implements SubscriptionSettingsModel {
  id: string | any = SubscriptionSettingsIds.plan;
}

/** */
export class SubscriptionPlanForm extends PlanForm implements SubscriptionSettingsForm<SubscriptionPlanModel> {
  isSaving: boolean;

  get id(): string { return SubscriptionSettingsIds.plan; }

  constructor(value?: Partial<SubscriptionPlanModel>) {
    const init = new SubscriptionPlanModel(value);
    init.id = SubscriptionSettingsIds.plan;
    super(init);
    this.originalValue = init;
  }
}
