import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';

export interface IPartner {
  /** This partners unique ID */
  id?: string;

  /** The partner's first name */
  firstName: string;

  /** The partner's last name */
  lastName: string;

  /** The partner's phone number */
  phoneNumber: string;

  /** The partner's email address */
  emailAddress: string;

  // Readonly
  userId?: string;
  preferences?: {
    emailUnsubscribed: boolean;
  }
  /** @deprecated use invite instead */
  inviteRef?: any;

  /** The reference to the sent out invite */
  invite?: {
    ref: any,
    code: string
  };

  /** Whether the mentor has removed themselves */
  status?: 'removed' | 'accepted' | 'declined' | 'invited';

  acceptedAt?: DateTimestamp | FirebaseTimestamp;
}

export interface Partner extends IPartner { }
export class Partner extends Model<Partner> {
  acceptedAt?: DateTimestamp;

  patchValue(model: Partial<Partner>) {
    delete model.inviteRef;
    super.patchValue(model);
    if(model?.acceptedAt) this.acceptedAt = new DateTimestamp(this.acceptedAt);
  }
}
