import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { BehaviorSubject, Subject, Observable, combineLatest } from 'rxjs';
import { tap, switchMap, map, shareReplay, startWith, filter } from 'rxjs/operators';
import { ICollectionPager, IRecord, QueryOptions } from '@triggered/common';
import { CollectionQuery } from './firestore.query';
import { FirestoreQueryDocument } from './firestore.document';

export abstract class BasePager<TRecord extends IRecord> implements ICollectionPager<TRecord>  {
  protected _pageIndex = -1;
  protected _totalCount: number;
  protected reachedEnd: boolean;
  protected pageCounts: {[index: number]: number} = {};
  protected lastDocumentOfPage: {[index: number]: QueryDocumentSnapshot<TRecord>} = {};

  private readonly _isLoading = new BehaviorSubject<boolean>(true);
  private readonly next = new BehaviorSubject<true>(true);
  private readonly previous = new Subject<true>();
  private readonly options$ = new Subject<QueryOptions<TRecord>>();

  isRollingPager: boolean = true;

  readonly pageSize: number;
  readonly data$: Observable<TRecord[]>;
  readonly hasMore$: Observable<boolean>;
  readonly isLoading$: Observable<boolean> = this._isLoading.asObservable();

  get pageIndex() { return this._pageIndex; }
  get totalCount() { return this._totalCount; }

  constructor(options?: QueryOptions<TRecord>) {
    this.pageSize = options?.limit || 10;

    // Only listen to the 'next' event which loads more documents
    let pageData: Observable<TRecord[]>[] = [];

    const options$ = this.options$.pipe(
      tap(() => {
        // Filter options changed. Reset
        this._pageIndex = 0;
        pageData = [];
        this.pageCounts = {};
        this.lastDocumentOfPage = {};
      }),
      startWith(options)
    );

    const next$ = this.next.pipe(tap(() => this._pageIndex++));
    const previous$ = this.previous.pipe(tap(() => this._pageIndex--), startWith(true));
    this.data$ = combineLatest([options$, next$, previous$]).pipe(
      // When next is fired, get the query of data
      tap(() => this._isLoading.next(true)),
      switchMap(([queryOptions]) => {
        const existingPage = pageData[this.pageIndex];
        if (existingPage == null) {
          const test = this.executeQuery(this.getFilters(queryOptions))
          // const test = query(collectionPath, this.getFilters(queryOptions))                            ;
          pageData.push(test.pipe(
            tap(docs => this.updateLastDocument(docs)),
            tap(docs => this.updatePageCounts(docs)),
            map(docs => docs.map(doc => doc.record)),
            shareReplay(1)
          ));
        }

        if(this.isRollingPager) {
          return combineLatest(pageData).pipe(
            map(arrays => [].concat(...arrays))
          );
        } else {
          return pageData[this._pageIndex];
        }

      }),
      tap(() => this._isLoading.next(false)),
      tap(data => console.log(`Fetched ${data.length} records`)),
      filter(data => (this.isRollingPager || this._pageIndex == 0  || data.length > 0)),
      // TODO: There may be duplicates if data has changed. Is it worth performance?
      shareReplay(1)
    );

    this.hasMore$ = this.data$.pipe(
      map(() => !this.reachedEnd),
      shareReplay(1)
    );
  }

  filter(options: QueryOptions<TRecord>) {
    this.options$.next(options);
  }

  onNext() {
    this.next.next(true);
  }

  onPrevious() {
    this.previous.next(true);
  }

  protected abstract executeQuery(options: QueryOptions<TRecord>): Observable<FirestoreQueryDocument<any>[]>;


  private updateLastDocument(docs: FirestoreQueryDocument<any>[]) {
    if (docs.length === 0) { return; }
    this.lastDocumentOfPage[this._pageIndex] = docs[docs.length - 1].snapshot;
  }

  private updatePageCounts(docs: FirestoreQueryDocument<any>[]) {
    const previousCounts = this.pageCounts[this._pageIndex];
    this.pageCounts[this._pageIndex] = docs.length;

    if(previousCounts !== docs.length) {
      // Reset reached end since the number of documents changed in this page
      this.reachedEnd = false;
    }

    if (this.reachedEnd) { return; }

    // Firebase does not have a count method, so we have to just assume that if we got the full page size back, there are more records
    if (this.pageCounts[this._pageIndex] === this.pageSize) {
      this.reachedEnd = false;
      this._totalCount = 1000000;
    } else {
      // We have reached the end!
      this.reachedEnd = true;
      this._totalCount = (this.pageSize * this._pageIndex) + docs.length;

      if (docs.length === 0) {
        // Return index to previous
        this._pageIndex = Math.max(0, this._pageIndex - 1);
      }
    }
  }

  private getFilters(options?: QueryOptions<TRecord>): QueryOptions<TRecord> {
    const queryOptions = Object.assign({}, options);
    if (this._pageIndex !== 0 && this.lastDocumentOfPage[this._pageIndex - 1]) {
      queryOptions.startAfterSnapshot = this.lastDocumentOfPage[this._pageIndex - 1];
    }
    queryOptions.limit = this.pageSize;
    return queryOptions;
  }
}
export class FirestorePaginator<TModel extends IRecord> extends BasePager<TModel> {
  constructor(private collectionPath: string, private query: CollectionQuery<TModel>, readonly options?: QueryOptions<TModel>) {
    super(options);
  }

  executeQuery(options: QueryOptions<TModel>) {
    return this.query(this.collectionPath, options);
  }
}
