import { FormControl, Validators } from '@angular/forms';
import { PaymentFrequency } from '@triggered/common';
import { Form, NumberControl } from '@triggered/core';

export class PaymentFrequencyForm extends Form<PaymentFrequency> {

  constructor(value?: PaymentFrequency) {
    const init = value || new PaymentFrequency();
    super({
      frequency: new FormControl(init.frequency, [Validators.required]),
      price: new NumberControl(init.price, [Validators.required, Validators.min(.01)]),
      unitPrice: new NumberControl(init.unitPrice, [Validators.min(.01)]),
      productId: new FormControl(init.productId, [Validators.required]),
      priceBreakdown: new FormControl(init.priceBreakdown || [])
    });
    this.originalValue = init;
  }
}
