export class MetaTags {
  /**
   * The Title of the Page - Range: 55-60 characters
   * Reference: https://www.semrush.com/blog/on-page-seo-basics-page-titles/
   */
  readonly title: string;

  /**
   * The generic description of the Page - Range:  ~200 characters
   */
  readonly description: string;

  /**
   * The og:title - Range: 60-90 characters
   * Optional: Will use title if not set
   * Reference: https://neilpatel.com/blog/open-graph-meta-tags/
   */
  readonly ogTitle: string;

  /**
   * The og:description - Range: ~200 characters. (Won't affect SEO)
   * Optional: Will use description if not set
   * Reference: https://neilpatel.com/blog/open-graph-meta-tags/
   */
  readonly ogDescription: string;

  /**
   * The og:type - Range: website, article, blog, etc.
   * Reference: https://ogp.me/#types
   */
  readonly ogType: string;

  /**
   * The og:image - Dimensions: 1200 x 630 pixels: Size < 8 MB
   * Reference: https://neilpatel.com/blog/open-graph-meta-tags/
   */
  readonly ogImage: string;

  /**
   * The twitter:title - TODO: Requirements
   * Optional: Will use title if not set
   */
  readonly twitterTitle: string;

  /**
   * The twitter:description - TODO: Requirements
   * Optional: Will use description if not set
   */
  readonly twitterDescription: string;

  /**
   * The twitter:image:src - TODO: Requirements
   */
  readonly twitterImageSrc: string;

  constructor(metaTags?: Partial<MetaTags>){
    if(metaTags) {
      Object.assign(this, metaTags);
    }
  }
}
