import { IRecord, Record } from '../../data/record';

export type CountByHour = {[hour: number]: number};
export type CountByHourByDay = {
  /** Monday */
  1?: CountByHour,
  /** Tuesday */
  2?: CountByHour,
  /** Wednesday */
  3?: CountByHour,
  /** Thursday */
  4?: CountByHour;
  /** Friday */
  5?: CountByHour;
  /** Saturday */
  6?: CountByHour;
  /** Sunday */
  7?: CountByHour
};


export interface IAlertsByHour extends IRecord {
  /** The total count of alerts */
  total: number;

  /** The days and hours of each alert */
  days: CountByHourByDay;
}

export interface IWeeklyAlertsByHour extends IAlertsByHour {
  /** The four digit year associated to the alert */
  year: number;

  /** The week number of the given year corresponding to the alert */
  week: number;

  /** The year combined with the week, as a number: i.e. - '202203' */
  yearWeek: number;
}


export interface WeeklyAlertsByHour extends IWeeklyAlertsByHour { }
export class WeeklyAlertsByHour extends Record<WeeklyAlertsByHour> {

  static uniqueYearWeek(year: number, week: number) {
    //Returns '202203' as a number
    return +`${year}${week.toString().padStart(2, '0')}`;
  }

  /**
   * Creates a unique identifier using the current week values (in users local date)
   **/
  static createId(year: number, week: number) {
    return `${WeeklyAlertsByHour.uniqueYearWeek(year, week)}`;
  }
}
