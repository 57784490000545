import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Devices, MenuItem } from '@triggered/core';
import { MatDrawerContainer } from '@angular/material/sidenav';

@Component({
  selector: 'triggered-sidenav-router',
  templateUrl: './sidenav-router.component.html',
  styleUrls: ['./sidenav-router.component.scss']
})
export class SidenavRouterComponent implements OnChanges, AfterViewInit {

  /**
  * Whether the sidenav is expanded.
  * @default true
  */
  private _expanded: boolean = true;

  /**
   * Whether the side nav is available.
   */
  opened: boolean = false;

  /** Whether we should display the routing menu */
  showMenu: boolean = true;

  /** Whether we are on a mobile device */
  isMobile: boolean;

  /** Change AutoSize when toggling the size */
  autoSize: boolean;


  /**
  * What mode the sidenav is on.
  * @default 'side'
  */
  mode: 'side' | 'over' | 'push' = 'side';


  get expanded() { return this._expanded; }
  set expanded(value: boolean) {
    if(this.device == Devices.mobile) {
      setTimeout(() => this.mode = value ? 'over' : 'side', 0);
      this.showMenu = value ? true : false;
    }
    this.autoSize = true;
    this._expanded = value;

    this.updateLayout();
  }

  @ViewChild('drawerContainer', { static: false })
  drawerContainer: MatDrawerContainer;

  @Input() device: Devices;
  @Input() links: MenuItem[];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.device && this.device) {
      this.isMobile = this.device === Devices.mobile;

      // Hide the sidenav on mobile
      this.showMenu = !this.isMobile;
      this.expanded = !this.isMobile;
      this.opened = !this.isMobile;
    }

    if(changes.links) {
      this.updateLayout();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {  this.drawerContainer.updateContentMargins();  }, 1);
  }

  private updateLayout() {
    setTimeout(() => this.drawerContainer.updateContentMargins(), .5);
    setTimeout(() => this.autoSize = false, 1);
  }
}
