import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Step } from './step.model';

@Component({
  selector: 'triggered-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class StepperComponent implements OnInit {

  @Input() steps: Step[];
  @Input() activeIndex: number;
  @Input() isVertical: boolean = true;
  @Input() hideHeader: boolean;
  @Output() readonly indexChanged = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  onIndexChanged(index: number) {
    this.indexChanged.emit(index)
  }
}
