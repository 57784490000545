<triggered-page>

  <div fxLayout="row">
    <div fxFlex="0 1 1000px" fxLayout="column" fxLayoutGap="1em">
      <div>
        <h1 class="mat-h1 margin-0">Download Triggered</h1>
        <h2 class="mat-h3 margin-0">Make reaching out simple</h2>
      </div>

      <div>
        <p class="mat-body-1 margin-0">
          Triggered wants to make it simple to reach out for support when you need it the most.
          <br>
          Select a device or extension to get started with Triggered.
        </p>
      </div>

      <!-- Devices Sections-->
      <div fxLayout="row" fxLayout.lt-sm="column" class="mat-card">

        <!-- Device Chooser -->
        <div fxLayout="column" fxLayout.lt-sm="row wrap"  fxLayoutAlign.lt-sm="center">
          <ng-container *ngFor="let link of platforms">
            <a fxLayout="column" fxLayoutAlign="start center" class="padding-1" [routerLink]="[link.route]">
              <mat-icon class="" [svgIcon]="link.icon"></mat-icon>
              <span>{{link.display}}</span>
            </a>
          </ng-container>
        </div>

        <mat-divider fxHide.lt-sm [vertical]="true"></mat-divider>
        <mat-divider fxHide.gt-xs></mat-divider>

        <!---->
        <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start" class="padding-2">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</triggered-page>
