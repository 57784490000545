import { Component } from '@angular/core';
import { MenuItem, MenuService, NavigationHistoryService } from '@triggered/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'triggered-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'web-corporate';
  opened = false;
  readonly links$: Observable<MenuItem[]>;

  constructor(readonly menuService: MenuService, _historyService: NavigationHistoryService) {
    this.links$ = menuService.menus$['app'];
  }

}
