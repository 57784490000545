import { IGift, IGiftDesignation, IInvite, IPlan, IUserInvoice } from '.';
import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Record } from '../../data/record';
import { Reference } from '../../data/reference';

/**
 * The details of the gift given by a user. Holds information that is relevant
 * to the user.
 */
export interface IUserGift extends Record<IUserGift> {
  /** The plan that has been donated */
  plan: Reference<IPlan, {id: string, name: string}>;

  /** The id of the invite this gift was assigned to */
  invite?: Reference<IInvite, {id: string, code: string}>;

  /** The id of the invoice this gift was purchased with */
  invoice?: Reference<IUserInvoice, {id: string}>;

  /** Who this gift is intended for */
  designation: IGiftDesignation;

  /** The date this   */
  designateBy: DateTimestamp | FirebaseTimestamp;

  /**
   * Whether this gift was redeemed or not.
   * Do not expose any other details related to the redemption
   */
  redeemed: boolean;

  /** The main gift this is associated with */
  gift: Reference<IGift, {id: string}>;

  /** The duration that this plan is good for after redemption */
  duration: {
    years?: number;
    months?: number;
  };
}

export interface UserGift extends IUserGift {}
export class UserGift extends Record<UserGift> {
  createdAt: DateTimestamp;
  designateBy: DateTimestamp;

  // Order of operations
  // 1.) super(model) is called first and sets base properties
  // 2.) Property initializers run and reset properties to initiated values
  // 3.) Patch value runs and sets the properties
  constructor(model?: Partial<UserGift>) {
    super(model);
    this.patchValue(model);
  }

  patchValue(model?: Partial<UserGift>) {
    if (!model) { return; }
    super.patchValue(model);
    this.designateBy = new DateTimestamp(model.designateBy);
  }
}
