import { Inject, Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AppConfig } from '../config/app.config';
import { ErrorReporter } from '../services/global-error-handler.service';
import { APP_CONFIG } from '../tokens';
import { EventLogger, EventTypes } from './event-logger';

@Injectable()
export class WebEventLogger implements EventLogger {
  private static _isInitialized: boolean;

  constructor(@Inject(APP_CONFIG) readonly appConfig: AppConfig, readonly analytics: AngularFireAnalytics) {
    try {
      this.initialize();
    } catch(error) {
      ErrorReporter.report(error, { message: 'WebEventLogger: Could not initialize web event logger' });
    }
  }

  setUserId(userId: string) {
    try {
      this.analytics.setUserId(userId);
    } catch(error) {
      ErrorReporter.report(error, { message: `WebEventLogger: Could not set the userId to ${userId}`});
    }
  }

  logEvent(event: EventTypes, data?: any) {
    try {
      if(!this.analytics) {
        throw new Error('Cannot log event. EventLoggingService has not been initialized');
      }
      this.analytics.logEvent(event, data)
        .then(() => console.debug(`WebEventLogger: Event logged ${event}`, data))
        .catch(error => {
          ErrorReporter.report(error, { message: `WebEventLogger: Could not log event ${event}`, data});
        });
    } catch(error) {
      ErrorReporter.report(error, { message: `WebEventLogger: Could not log event ${event}`, data});
      return; // DO not throw error, just return. Logging Events is not a critical function
    }
  }

  private initialize() {
    if(WebEventLogger._isInitialized) { return; }

    // Enable Logging
    this.analytics.setAnalyticsCollectionEnabled(true);

    // Set User Properties
    this.analytics.setUserProperties({
      app_viewer: this.appConfig.isInstalled ? 'app' : 'web',
      app_platform: this.appConfig.isAndroid ? 'android' : this.appConfig.isIOS ? 'iOS' : 'web',
      app_device_size: this.appConfig.deviceSize,
      app_logger: 'WebEventLogger'
    });
    WebEventLogger._isInitialized = true;
  }
}
