import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class CustomDrawerScrollService {
  private readonly document: Document;

  constructor(private router: Router, private viewportScroller: ViewportScroller, @Inject(DOCUMENT) document) {
    this.document = document;
  }

  initialize() {
    this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(e => {
      const position = e.position || [];
      this.scrollTo(position[0], position[1], e.anchor);
    });
  }

  private scrollTo(x: number, y: number, anchor: string) {
    const sideNavs = this.document.querySelectorAll('.mat-drawer-content');
    const sideNav = sideNavs.length ? sideNavs.item(sideNavs.length - 1) : null;
    if(!(sideNav && sideNav.scroll)) { 
      console.log('DrawerScrollService: No sidenav scroll!');
      return; 
    }

    setTimeout(() => {
      if(x && y) {
        console.log(`DrawerScrollService: Scrolling to x y coordinates: (${x}, ${y})`);
        sideNav.scroll({ top: x, left: y, behavior: 'smooth'});
      } else if (anchor) {
        // TODO: This is not scrolling on the sidenav container?
        console.log('DrawerScrollService: Scrolling to anchor');
        this.viewportScroller.scrollToAnchor(anchor);
      } else {
        console.log('DrawerScrollService: Scrolling to top');
        sideNav.scroll({ top: 0, left: 0, behavior: 'smooth'});
      }
    }, 0);
  }
}
