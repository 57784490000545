<triggered-page id="how-it-works-page" layout="none" class="bg-card">
  <div class="page-content" fxLayout="column" fxLayoutAlign="start center" class="bg-card">

    <section *ngIf="Steps.GetTheApp as step" id="download-section" class="margin-bottom-1"  fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1200px"
            fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="space-between center"
            fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">

        <div fxFlex="0 1 500px" fxLayout="column" fxLayoutGap="1em" ngClass.gt-xs="margin-y-3" ngClass.lt-sm="margin-y-1"
            fxFlex.lt-sm="1 1 auto">
          <div>
            <h1 class="mat-h1 margin-0">{{step.header}}</h1>
          </div>
          <div>
            <p class="mat-body-1 margin-0">
              {{step.description}}
            </p>
          </div>

          <div fxLayout="row" fxLayoutGap="1em" >
            <button fxFlex="0 1 250px" mat-raised-button color="primary" aria-label="Download" [routerLink]="['/', routes.corporate.contact.pricing.full]">Download Now</button>
          </div>
        </div>

        <div fxFlex="175px" fxLayout="row wrap" fxLayoutGap=".5em"
            fxFlex.lt-sm="1 1 auto" fxLayout.lt-sm="center">

          <button mat-icon-button class="mat-hint" ngClass.lt-sm="lg" ngClass.gt-xs="xl" [routerLink]="[routes.downloads.android.full]" aria-label="Android">
            <mat-icon svgIcon="android-os"></mat-icon>
          </button>


          <button mat-icon-button class="mat-hint" ngClass.lt-sm="lg" ngClass.gt-xs="xl" [routerLink]="[routes.downloads.ios.full]" aria-label="iOS">
            <mat-icon svgIcon="apple-logo"></mat-icon>
          </button>
          <button mat-icon-button class="mat-hint" ngClass.lt-sm="lg" ngClass.gt-xs="xl" [routerLink]="[routes.downloads.chrome.full]" aria-label="Chrome">
            <mat-icon svgIcon="chrome"></mat-icon>
          </button>

          <button mat-icon-button class="mat-hint" ngClass.lt-sm="lg" ngClass.gt-xs="xl" [routerLink]="[routes.downloads.windows.full]" aria-label="Windows">
            <mat-icon svgIcon="windows-client"></mat-icon>
          </button>
        </div>
      </div>
    </section>

    <section *ngIf="Steps.BuildYourTeam as step" id="team-section" class="bg-accent padding-top-2" fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1200px"
            fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="space-between center"
            fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">

          <!-- World -->
          <div class="image-container"
              fxFlex="0 1 500px" fxFlexAlign="end"
              fxFlex.lt-sm="1 1 auto" fxFlexOrder.lt-sm="2" ngClass.lt-sm="offset-right">
            <img width="400" height="313" src="assets/images/mentor.png" alt="Man mentoring another man">
          </div>

        <div class="margin-y-3"
          fxFlex="0 1 500px" fxLayout="column" fxLayoutGap="1em"
          fxFlex.lt-sm="1 1 auto"  fxFlexOrder.lt-sm="1">
          <!-- <div fxFlex="120px" class="image-container">
            <img height="120" width="120" [src]="step.icon" alt="Team icon">
          </div> -->

          <div>
            <h1 class="mat-h1 margin-0">{{step.header}}</h1>
          </div>
          <div>
            <p class="mat-body-1 margin-0">
              {{step.description}}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="Steps.ClickTheButton as step" id="click-section"  fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1200px"
            fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="space-between center"
            fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">

        <div fxFlex="0 1 500px" fxLayout="column" fxLayoutGap="1em" class="margin-y-3"
            fxFlex.lt-sm="1 1 auto">
          <!-- <div fxFlex="120px" class="image-container">
            <img height="120" width="120" [src]="step.icon" alt="Icon of hand pushing a button">
          </div> -->

          <div>
            <h1 class="mat-h1 margin-0">{{step.header}}</h1>
          </div>
          <div>
            <p class="mat-body-1 margin-0">
              {{step.description}}
            </p>
          </div>
        </div>

        <div class="image-container"
            fxFlex="0 1 500px" fxFlexAlign="end"
            fxFlex.lt-sm="1 1 auto" fxFlexOrder.lt-sm="2" fxFlexAlign.lt-sm="center">
          <img height="500" width="330" src="assets/images/triggered-app.jpg" alt="Hand holding a phone with the Triggered app">
        </div>
      </div>
    </section>

    <section *ngIf="Steps.ReceiveSupport as step" id="support-section" class="bg-accent" fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1200px"
            fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="space-between center"
            fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">

                    <!-- World -->
          <div class="image-container"
              fxFlex="0 1 500px" fxFlexAlign="end"
              fxFlex.lt-sm="1 1 auto" fxFlexOrder.lt-sm="2" fxFlexAlign.lt-sm="center">
            <img height="300" width="300" src="assets/images/support.png"  alt="Two women talking">
          </div>

        <div class="margin-y-3"
          fxFlex="0 1 500px" fxLayout="column" fxLayoutGap="1em"
          fxFlex.lt-sm="1 1 auto"  fxFlexOrder.lt-sm="1">

          <div>
            <h1 class="mat-h1 margin-0">{{step.header}}</h1>
          </div>
          <div>
            <p class="mat-body-1 margin-0">
              {{step.description}}
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</triggered-page>
