import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
const angularModules = [
  CommonModule
];
const sharedAngularModules = [
  FlexLayoutModule, MatProgressSpinnerModule, MatDividerModule
]

// Card Components
import { CardComponent } from './card.component';
const externalComponents = [ CardComponent ];

@NgModule({
  declarations: [...externalComponents],
  exports: [
    ...externalComponents,
    ...sharedAngularModules
  ],
  imports: [
    ...angularModules,
    ...sharedAngularModules
  ]
})
export class TriggeredCardModule { }
