import { IGroup, IInvite, IUser, Reference } from '@triggered/common';
import { ISubscription } from '.';
import { StatusHistory } from '../../core/models/status';
import { Record } from '../../data/record';
import { ContactInfo } from '../../models';

export type AdminStatus = 'invited' | 'accepted' | 'removed';

/**
 * Representation of the SubscriptionUser object
 */
export interface ISubscriptionAdmin extends Record<ISubscriptionAdmin>, ContactInfo {

  /** The unique ID of the user */
  id: string;

  /** The search fields to locate this user */
  search: string[];

  /** The groups this user is administrator of */
  groups?: Reference<IGroup, {
    id: string,
    name: string,
    subscription?: Reference<ISubscription, {id: string}>
  }>[];

  /** Relevant user information */
  user?: Reference<IUser, { id: string }>;

  /** The invite sent to the user */
  invite?: Reference<IInvite, {code: string}>;

  /** The status of the user */
  status: AdminStatus;

  /** The status history */
  statusHistory: StatusHistory<AdminStatus>;
}

export interface SubscriptionAdmin extends ISubscriptionAdmin { }
export class SubscriptionAdmin extends Record<ISubscriptionAdmin> {

}

