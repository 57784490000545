/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { DateTimestamp, FirebaseTimestamp } from './date-timestamp';
import { DeepPartial, Model } from './model';

export type Updates<T> = Omit<T, keyof IRecord>;

export interface Timestamps {
  updatedAt: DateTimestamp | FirebaseTimestamp;
  updatedBy: string;
  createdAt: DateTimestamp | FirebaseTimestamp;
  createdBy: string;
}

export interface IRecord extends Timestamps {
  id: string;
}

/**
 * A record is any newable object that is a document in the database.
 */
export interface Record<TModel> extends IRecord {}
export class Record<TModel extends IRecord> extends Model<TModel> {


  patchValue(model?: Partial<TModel> | DeepPartial<TModel>) {
    if(!model) { return; }
    super.patchValue(model);
    this.createdAt = new DateTimestamp(model.createdAt);
    this.updatedAt = new DateTimestamp(model.updatedAt);
  }

}

