<triggered-page id="home-page" class="partial-width" layout="none">
  <!-- Hero Banner -->
  <ng-template #pageHeader>
    <section id="header-section" fxLayout="row" fxLayoutAlign="center" class="bg-texture bg-accent-500">
      <div fxFlex="0 1 1200px" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="3em" fxLayoutGap.lt-md="1em" fxLayoutAlign="space-between center" >

        <div fxFlex="none" fxLayout="row" fxLayoutAlign.lt-md="center" fxLayoutAlign="center">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em" class="padding-y-1">
            <div>
              <h1 id="home-title" class="mat-display-2 margin-0 text-uppercase text-center">Overcome Addiction</h1>
            </div>
            <div>
              <h2 class="mat-display-1 margin-0 text-uppercase text-center">With Just One Click</h2>
            </div>
            <div>
              <h3 class="mat-body-1 margin-0 text-center">Break the power of temptation with the Triggered™ App</h3>
            </div>


            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100">
              <a fxFlex="0 1 175px" mat-flat-button color="primary" class="border-primary" [routerLink]="[routes.corporate.contact.pricing.full]">Sign Up</a>
              <a fxFlex="0 1 175px" mat-stroked-button color="primary" class="border-primary" [routerLink]="[routes.corporate.give.full]">Give a Gift</a>
            </div>
          </div>
        </div>
        <div fxFlex="0 1 200px"  fxLayout="row" fxLayoutAlign="center">
          <div class="image-container text-center">
            <img height="200" width="200" src="assets/images/triggered-button-200x200.png" class="mat-elevation-z10 circle" alt="Triggered button"/>
          </div>
        </div>
      </div>
    </section>
  </ng-template>

  <!-- Trapped in Addiction? -->

  <section id="stuck-section" class="mat-typography"
    fxLayout="row" fxLayoutAlign="center">

    <div fxFlex="0 1 1200px"
      fxLayout="row" fxLayoutGap="2em"
      fxLayout.lt-sm="column" >

      <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start" fxLayoutAlign.lt-sm="center"  fxLayoutGap="-3em">
        <!-- Images -->
        <div id="depressed-man" class="image-container" fxHide.lt-md>
          <img height="300" width="300" src="assets/images/man-depressed.jpg" alt="Man depressed looking out a rainy window">
        </div>

        <div id="depressed-woman" class="image-container" ngClass.gt-sm="offset-image">
          <img height="300" width="300" src="assets/images/woman-depressed.jpg" alt="Woman depressed sitting with her head in her hands">
        </div>
      </div>

      <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center" fxLayoutAlign.lt-md="start">
        <div fxLayout="column" fxLayoutGap="1em" >
          <h2 class="mat-title section-title">
            Trapped in Addiction? <br>
            You are not alone.
          </h2>
          <div>
            <p class="mat-body-1">
              When life gets hard, temptations await...
            </p>
            <ul>
              <li>Pornography</li>
              <li>Alcohol</li>
              <li>Drugs</li>
              <li>Sex</li>
              <li>Gambling</li>
              <li>Suicide</li>
              <li>Self-harm</li>
              <li>Overeating</li>
              <li>and more...</li>
            </ul>
            <p class="mat-body-1">
              Overcome temptation with the Triggered App. <br>
              Get the support and accountability you need.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="accountability-section" class="angled-1 bg-gradient ltr" fxLayout="row" fxLayoutAlign="center">
    <div fxFlex="0 1 1200px"
    fxLayout="row" fxLayoutGap="2em"
    fxLayout.lt-sm="column" >

      <!-- Feeling Triggered ?-->
      <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start" fxLayoutAlign.lt-md="start" fxFlexOrder.lt-md="1">
        <div fxLayout="column" fxLayoutGap="1em">
          <h3 class="mat-title">
            Feeling Tempted? <br/>
            Support is there.
          </h3>

          <div class="mat-body-1">
            <p>
              When you are feeling down, someone is there. <br>
              Don't fight it alone.
            </p>

            <ul>
              <li>Stress</li>
              <li>Anxiety</li>
              <li>Fear</li>
              <li>Shame</li>
              <li>Anger</li>
              <li>Frustration</li>
              <li>Loneliness</li>
              <li>and more...</li>
            </ul>

            <p>
              Instant accountability and support. <br>
              Download the Triggered™ App now.
            </p>
          </div>

          <div>
            <a mat-stroked-button color="primary" class="border-primary large" [routerLink]="[routes.corporate.howItWorks.full]">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div style="height: 2em; width: 2em;" class="image-container">
                  <img height="28" width="28" src="assets/images/icons/icon-100x100.png" alt="Small Triggered button"/>
                </div>
                <span>
                  How it Works
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- Images -->
      <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="end" fxLayoutAlign.lt-sm="center"  fxLayoutGap="-3em" fxFlexOrder.lt-md="0">

        <div id="accountability-image-1" fxHide.lt-md class="image-container">
          <img height="300" width="300" src="assets/images/woman-laptop.jpg" alt="Woman sitting at the laptop sitting with the sun on her">
        </div>
        <div id="accountability-image-2" class="image-container" ngClass.gt-sm="offset-image">
          <img height="300" width="300" src="assets/images/man-phone.jpg" alt="Man looking at his phone while sitting with a laptop in front of him">
        </div>
      </div>
    </div>
  </section>

  <section id="support-section" fxLayout="row" fxLayoutAlign="center">
    <div fxFlex="0 1 1200px" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em">

      <h3 class="mat-title text-center">
        Expecting a call <br *solidDevice="Devices.tablet">will give you the strength to fight. <br>
        We are in this together.
      </h3>

      <div>
        <p class="mat-body-1 text-center margin-0">
          The Triggered™ App is a one-click button designed to send out instant text alerts to your group via text, email and push notifications. <br>
          We can help ensure you get the accountability you need to find victory.
        </p>
      </div>

      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="-3em">
        <div id="group-image-1" fxHide.lt-md class="image-container">
          <img src="assets/images/group-men.jpg" height="333" width="500" alt="Two young men sitting on a rock overlooking the ocean talking">
        </div>
        <div id="group-image-2" class="image-container" ngClass.gt-sm="offset-image">
          <img src="assets/images/group-women.jpg" height="333" width="500" alt="A group of women talking and laughing with ane another">
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="center" class="w-100">
        <ng-container *ngFor="let step of steps">
          <div fxFlex="0 1 300px" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px" class="padding-1">
            <div class="image-container text-center">
              <img height="120" width="120" [src]="step.icon" [alt]="step.header + ' icon'">
            </div>

            <div class="text-center">
              <h4 class="margin-0 mat-title">{{step.header}}</h4>
            </div>
            <span class="text-center mat-body-2" [innerHTML]="step.summary">
            </span>
          </div>
        </ng-container>
      </div>

      <div>
        <a mat-stroked-button color="primary" class="border-primary large" [routerLink]="[routes.corporate.contact.pricing.full]">Get the Triggered™ App</a>
      </div>
    </div>

  </section>

</triggered-page>
