export class FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}
export type AppPlatform = 'Web' | 'Android' | 'iOS';

export class AppConfig {
  companyId: string;
  appVersion: string;
  reportErrors: boolean;
  firebase: FirebaseConfig;

  production: boolean;

  useEmulator?: boolean;

  /**
   * Whether or not this is installed as an application (true)
   * or is just running in the browser (false)
   */
  isInstalled: boolean;

  isAndroid?: boolean;
  isIOS?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
  deviceSize?: string;
  platform?: AppPlatform;

  endpoints: {
    baseAppUrl: string;
    baseCorporateUrl: string;
    baseApiUrl: string;
  };

  stripe?: {
    publicKey: string;
  };

  products: {
    oneYearGiftSubscription: {
      productId: string;
      priceId: string;
    }
  }
}
