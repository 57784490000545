// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatStepperModule } from '@angular/material/stepper';
const angularModules = [ CommonModule, RouterModule, FlexLayoutModule, MatStepperModule ];

// Stepper components
import { StepperComponent } from './stepper.component';
const exportComponents = [ StepperComponent ];

@NgModule({
  declarations: [...exportComponents],
  exports: [...exportComponents],
  imports: [
    ...angularModules
  ]
})
export class TriggeredStepperModule { }
