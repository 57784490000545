import { Directive, OnInit, OnChanges, OnDestroy, Input, TemplateRef, ViewContainerRef, SimpleChanges, EmbeddedViewRef } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[triggeredAuth]'
})
export class AuthDirective implements OnInit, OnChanges, OnDestroy {
  private _currentTemplate: TemplateRef<any>
  private _currentRef: EmbeddedViewRef<any>
  private _isAdmin: boolean;
  private _isGlobalAdmin: boolean;
  private _isLoggedIn: boolean;
  private _subscription: Subscription;

  @Input('triggeredAuth')
  role: '' |'user' | 'companyAdmin' | 'globalAdmin' = 'companyAdmin';

  @Input()
  solidAuthElse: TemplateRef<any>;


  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthenticationService) {

  }

  ngOnInit() {
    this._subscription = combineLatest([this.authService.isLoggedIn$, this.authService.isAdmin$, this.authService.isGlobalAdmin$])
      .subscribe(([isLoggedIn, isAdmin, isGlobalAdmin]) => {
        this._isLoggedIn = isLoggedIn;
        this._isAdmin = isAdmin;
        this._isGlobalAdmin = isGlobalAdmin;
        this.onChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges();
  }

  ngOnDestroy() {
    if(this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private onChanges() {
    switch(this.role) {
      case 'user': return this.setVisibility(this._isLoggedIn); // Show only if loggedIn
      case 'companyAdmin': return this.setVisibility(this._isAdmin); // Show only if admin
      case 'globalAdmin': return this.setVisibility(this._isGlobalAdmin); // Show only if globalAdmin
      default: return this.setVisibility(this._isAdmin); // By default, show if admin
    }
  }

  private setVisibility(canView: boolean) {
    if(canView) {
      return this.show(this.templateRef);
    } else if(this.solidAuthElse) {
      return this.show(this.solidAuthElse);
    } else {
      return this.clear();
    }
  }

  private show(template: TemplateRef<any>) {
    // If it is already diplayed, dont show it
    if(this._currentTemplate === template){ return; }

    // Otherwise, clear the container and update the view
    this.clear();
    this._currentRef = this.viewContainer.createEmbeddedView(template);
    this._currentTemplate = template;
  }

  private clear() {
    if(!this._currentRef){ return; }
    this.viewContainer.clear();
    this._currentRef = null;
  }
}
