import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
const angularModules = [ CommonModule, FlexLayoutModule ];

// Legal Components
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
const externalComponents =  [ TermsAndConditionsComponent, PrivacyPolicyComponent ];


@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [
    ...angularModules
  ]
})
export class LegalComponentsModule { }
