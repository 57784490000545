import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
const angularModules = [
  CommonModule, FlexLayoutModule, RouterModule, MatListModule, MatProgressSpinnerModule,
  MatMenuModule, MatIconModule, MatButtonModule, MatBadgeModule, MatDividerModule, MatSlideToggleModule
];


import { NotificationMenuComponent } from './notification-menu.component';
const externalComponents = [ NotificationMenuComponent ];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [
    ...angularModules
  ]
})
export class TriggeredNotificationMenuModule { }
