import { Inject, Injectable, Optional } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@triggered/authentication';
import { AppConfig, APP_CONFIG, ErrorReporter } from '@triggered/core';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  // Injecting CLIENT before auth. Hack to make sure it initializes properly
  constructor(/*private client: FirestoreClient<User>, */private auth: AuthenticationService, @Optional() @Inject(APP_CONFIG) readonly appConfig: AppConfig) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let retryCount = 0;
    return this.auth.idToken$.pipe(
      switchMap(token => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            CompanyId: this.appConfig?.companyId,
            AppVersion: this.appConfig?.appVersion
          }
        });
        return next.handle(request);
      }),
      retryWhen(errors => errors.pipe(
        switchMap(async error => {
          retryCount++;

          if(error instanceof HttpErrorResponse) {
            // Attempt the cheapest first
            const shouldRetry = await this.retryExpiredToken(request, error, retryCount);
            if(shouldRetry === true) { return true; }
          }

          throw error;
        }))
      )
    );
  }

  private async retryExpiredToken(request: HttpRequest<any>, error: HttpErrorResponse, retryCount: number) {
    const shouldRetry = error?.status === 401 && request?.headers?.has('Authorization');
    if(!shouldRetry) { return false; }

    if(retryCount > 1) {
      ErrorReporter.report(new Error(`Tried to refresh the IdToken ${retryCount} times`), error);
      return false;
    }

    try {
      return await this.auth.refreshToken();
    } catch(newError) {
      ErrorReporter.report(new Error('An error occured calling refreshToken()'), newError);
      throw error; // Throw the original error
    }

  }
}
