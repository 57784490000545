<h1 mat-dialog-title class="margin-0">{{data?.title}}</h1>

<div mat-dialog-content>
  <p class="mat-body-1" [innerHTML]="data?.message"></p>
</div>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px" >
  <ng-container *ngFor="let option of data?.otherOptions">
    <button mat-button (click)="onSelect(option)">{{option}}</button>
  </ng-container>

  <ng-container *ngIf="data?.primaryOption as option">
    <button mat-raised-button color="primary" (click)="onSelect(option)" cdkFocusInitial>{{option}}</button>
  </ng-container>
</div>

