
import { IRecord, Record } from '../../data/record';
import { IRelatedResource, RelatedResource } from './related-resource';


export interface IRelatedResources extends IRecord {
  /** The resources that are related to this article */
  resources: IRelatedResource[];

  /** The resourceIds that are related to this article. Used for filter operations */
  resourceIds: string[];
}

export interface RelatedResources extends IRelatedResources { }
export class RelatedResources extends Record<RelatedResources> {
  resources: RelatedResource[];

  patchValue(model: RelatedResources) {
    if(model) {
      super.patchValue(model);
      this.resources = (model.resources || []).map(resource => new RelatedResource(resource));
    }
  }
}
