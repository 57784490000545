<div fxFlex="0 1 500px" fxLayout="column" fxLayoutGap="2em">
  <div class="">
    <h3 class="mat-h2 margin-0">Mac App</h3>
    <h4 class="mat-h3 margin-0">Coming fall 2021</h4>
  </div>

  <div class="mat-body-1">
    <p>
      The desktop version of the Triggered App will be available on Mac this fall! We want to provide
      you with the most effective tools to help you get the support you need, when you need it the most!
    </p>

    <p class="margin-bottom-0">
      Subscribe below to receive product updates so that you can be the first to overcome with Triggered.
    </p>
  </div>

  <div class="w-100">
    <triggered-sendgrid-form fxFlex="0 1 500px" [newsletterIds]="['8e4f851a-9b84-465e-90b1-752038381e8b']" (submitted)="onSubmitted($event)" [displayName]="false"></triggered-sendgrid-form>
  </div>
</div>
