import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ErrorReporter } from '@triggered/core';
import { DialogService, Masks, PromptData, PromptDialog } from '@triggered/ui';
import { ContactRequest, ContactRequestForm } from '../../core/models/contact-request.model';
import { ContactRequestService } from '../../services/contact-request.service';

@Component({
  selector: 'triggered-contact-request-form',
  templateUrl: './contact-request.form.html',
  styleUrls: ['./contact-request.form.scss']
})
export class ContactRequestFormComponent implements OnChanges {
  readonly phoneMask = Masks.phoneNumber;


  isSubmitting: boolean = false;
  readonly form: ContactRequestForm = new ContactRequestForm();

  @Input() model: ContactRequest;
  @Input() hideContactInfo: boolean;
  @Input() header: string = 'Contact Us';
  @Input() subheader: string = `We'd love to connect with you`;
  @Output() readonly cancelled = new EventEmitter();
  @Output() readonly submitted = new EventEmitter<ContactRequest>();




  constructor(private readonly dialogService: DialogService, private service: ContactRequestService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.model && this.model) {
      this.form.patchValue(this.model);
    }
  }
  async onSubmit() {
    if(this.form.invalid) {
      return this.form.markAllAsDirty();
    }

    this.isSubmitting = true;
    const response = await this.service.sendContactRequest(this.form).catch(async error => {
      ErrorReporter.report(error, { message: `ContactRequestForm: An error occured submitting the contact request form`});
      await PromptDialog.open(this.dialogService, new PromptData('Error', 'An error occured submitting your request. Please try again.', 'Ok'));
    });
    this.isSubmitting = false;

    if(response) {
      await PromptDialog.open(this.dialogService, new PromptData('Thank You', 'Your message has been sent. We will be respond as soon as possible.', 'Ok'))
      this.submitted.emit(new ContactRequest(this.form.value));
    }
  }

  onCancel() {
    this.cancelled.emit();
  }

}
