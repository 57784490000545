import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, } from 'rxjs/operators';
import { MetaTags } from '../models';
import { MetaTagService } from '../services';
import { NavigationService } from '../services/navigation.service';

@Directive({
  selector: '[solidSEO]',
  providers: [
    NavigationService
  ]
})
export class SEODirective implements OnDestroy {
  private _subscription: Subscription;

  constructor(
              private readonly navigationService: NavigationService,
              private readonly metaTagService: MetaTagService) {
    // TODO: Dynamic MetaTags (i.e. - Blogs)
    this._subscription = this.navigationService.routeData$.pipe(
      map(data => {
        if(data.metaTags && data.metaTags instanceof MetaTags) {
          this.metaTagService.updateTags(data.metaTags);
        }
      })
    ).subscribe()
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }
}
