<mat-form-field [appearance]="appearance" floatLabel="always" [class.ng-valid]="errors === ''" [class.margin-bottom-1]="hint != null">

  <!-- Prefix -->
  <div matPrefix>
    <template *ngIf="prefixTemplate; else defaultPrefix" [ngTemplateOutlet]="prefixTemplate" ></template>
    <ng-template #defaultPrefix>
      <span *ngIf="prefix">{{prefix}}</span>
    </ng-template>
  </div>

  <!-- <mat-icon matPrefix>phone</mat-icon> -->
  <mat-label>{{label}}</mat-label>
  <input matInput
    [showMaskTyped]="showMask"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [readonly]="readonly"
    [mask]="mask?.mask || textMask"
    [dropSpecialCharacters]="dropSpecialCharacters"
    [patterns]="mask?.pattern || patterns"
    [name]="autoComplete ? name : 'do-not-auto'"
    [autocomplete]="autoComplete ? name || 'on' : 'do-not-auto'"
    (blur)="onBlur()" [errorStateMatcher]="matcher">

  <mat-hint>{{hint}}</mat-hint>
  <mat-error [innerHTML]="errors"></mat-error>

  <!-- Suffix -->
  <div matSuffix>
    <template *ngIf="suffixTemplate; else defaultSuffix" [ngTemplateOutlet]="suffixTemplate" ></template>
    <ng-template #defaultSuffix>
      <span *ngIf="suffix">{{suffix}}</span>
    </ng-template>
  </div>
</mat-form-field>
