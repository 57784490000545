import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormField } from '../form-field';

@Component({
  selector: 'triggered-texteditor',
  templateUrl: './texteditor.component.html',
  styleUrls: ['./texteditor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TexteditorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TexteditorComponent),
      multi: true,
    }
  ]
})
export class TexteditorComponent extends FormField {
  @Input() hint: string;
  @Input() label: string;
  @Input() prefix: string;
  @Input() readonly: boolean;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnInit(): void {
  }

}
