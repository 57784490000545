<div fxFlexFill class="triggered-page" [ngClass]="layout" [class.bg-gradient]="layout !== 'none'" solidDeviceSize>
  <div *ngIf="headerTemplate" class="triggered-page-header">
    <template [ngTemplateOutlet]="headerTemplate" ></template>
  </div>

  <div class="triggered-page-content">
    <ng-content></ng-content>
  </div>
</div>


