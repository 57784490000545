import { Component, forwardRef, Input, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormField } from '../form-field';
import { Output } from '@angular/core';

@Component({
  selector: 'triggered-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    }
  ]
})
export class DatepickerComponent  extends FormField {

  @Input() hint: string;

  @Input() maxLength: string;

  @Input() label: string;

  @Input() prefix: string;

  @Input() suffix: string;

  @Input() placeholder = 'Choose a date';

  @Input() readonly: boolean;

  @Output() readonly blur = new EventEmitter<void>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }
}
