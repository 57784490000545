
import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';
import { IResourceImageModel, ResourceImageModel } from './resource';


export interface IRelatedResource {
  id: string;
  readonly title: string;
  readonly path: string;
  readonly type: string;
  dateWritten: DateTimestamp | FirebaseTimestamp;
  mainImage: IResourceImageModel;
}

export interface RelatedResource extends IRelatedResource { }
export class RelatedResource extends Model<RelatedResource> {
  dateWritten: DateTimestamp;

  getDefaults(): Partial<RelatedResource> {
    return { dateWritten: new Date() };
  }

  patchValue(model: RelatedResource) {
    if(model) {
      super.patchValue(model);
      this.dateWritten = new DateTimestamp(model.dateWritten);
      this.mainImage = new ResourceImageModel(model?.mainImage);
    }
  }
}
