import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DialogOptions, DialogService } from '../../dialog/services/dialog.service';

export class PdfViewerData {
  constructor(readonly url: string) {}
}

@Component({
  selector: 'triggered-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {
  url: SafeUrl;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PdfViewerData,
              sanitizer: DomSanitizer,
              private readonly dialogRef: MatDialogRef<PdfViewerComponent, PdfViewerData>) {
    if(data?.url) {
      this.url = sanitizer.bypassSecurityTrustResourceUrl(data?.url);
    }
  }



  static async open(service: DialogService, data: PdfViewerData, options?: Partial<DialogOptions>): Promise<void> {
    const dialogOptions = new DialogOptions('90vh', '90vh', false);
    Object.assign(dialogOptions, options);
    return await service.openDialog<PdfViewerComponent, PdfViewerData>(PdfViewerComponent, data, dialogOptions);
  }

}
