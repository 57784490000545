import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { cfaSignIn, cfaSignOut } from 'capacitor-firebase-auth';
import { Observable, of, Subject, merge } from 'rxjs';
import { switchMap, shareReplay, map, filter, take, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import firebase from 'firebase/app';
import { User, UserForm } from '../models/user.model';
import { AppConfig, APP_CONFIG, ErrorReporter, EventLoggingService } from '@triggered/core';
import { DateTimestamp } from '@triggered/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public readonly idToken$: Observable<string>;
  public readonly user$: Observable<User>;

  public readonly isLoggedIn$: Observable<boolean>;
  public readonly isAdmin$: Observable<boolean>;
  public readonly isCompanyAdmin$: Observable<boolean>;
  public readonly isGlobalAdmin$: Observable<boolean>;

  private readonly refreshedToken$ = new Subject<string>();

  constructor(readonly fireAuth: AngularFireAuth, private userService: UserService, @Inject(APP_CONFIG) private readonly appConfig: AppConfig) {
    const userToken$ = this.fireAuth.authState.pipe(switchMap(user => user ? user.getIdToken(true) : of(null)));
    this.idToken$ = merge(userToken$, this.refreshedToken$).pipe(shareReplay(1));

    this.user$ = this.fireAuth.authState.pipe(
      switchMap((user: any) => user ? this.userService.getUser$(user.uid) : of(null)),
      switchMap((user: User) => this.afterUserInitialized(user)),
      tap(user => EventLoggingService.setUserId(user?.uid)),
      shareReplay(1)
    );

    this.isLoggedIn$ = this.user$.pipe(map(user => user != null), shareReplay(1));

    // TODO: Not distinguishing factor anymore between global and non global
    this.isAdmin$ = this.user$.pipe(map(user => user && user.isAdmin === true), shareReplay(1));
    this.isGlobalAdmin$ = this.isAdmin$;
    this.isCompanyAdmin$ = this.isAdmin$;
  }

  async refreshToken() {
    const user = await this.fireAuth.authState.pipe(take(1)).toPromise();
    if(user) {
      // Fetch the new token and emit event to refresh the token
      const newToken = await user.getIdToken(true);
      this.refreshedToken$.next(newToken);

      // Wait until the token has been propagated by the observable before returning
      await this.idToken$.pipe(filter(token => token === newToken), take(1)).toPromise();
      return true;
    }
    return false;
  }

  async user() {
    return this.user$.pipe(take(1)).toPromise();
  }

  async logout(): Promise<void> {
    if(this.appConfig.isInstalled) {
      await cfaSignOut().toPromise();
    } else {
      await this.fireAuth.signOut();
    }
    // Wait until the user is logged out and the event has been emitted
    return this.user$.pipe(filter(user => user == null), take(1), map(() => {})).toPromise();
  }

  async signInWithApple() {
    let userInfo: firebase.User;
    if(!this.appConfig.isInstalled) {
      // Not installed. Using web browser.
      const provider = new firebase.auth.OAuthProvider('apple.com');
      const credential = await this.fireAuth.signInWithPopup(provider)
        .catch(error => {
          ErrorReporter.report(error, { message: 'AuthenticationService: Error logging in with Google popup' });
          throw error;
        });

      userInfo = credential.user;
    } else if (this.appConfig.isIOS || this.appConfig.isAndroid) {
      console.log(`AuthenticationService: Signing in with CFA for ${this.appConfig.platform}`);
      userInfo = await cfaSignIn('apple.com').toPromise()
        .catch(error => {
          ErrorReporter.report(error, { message: 'AuthenticationService: Error logging in with CFA' });
          console.warn(`AuthService: Error: ${JSON.stringify(error)}`)
          throw error;
        });
    } else {
      throw new Error(`AuthenticationService: Login with apple not supported on ${this.appConfig.platform} devices.`)
    }
    const user = this.convertToUser(userInfo);
    return await this.userService.afterLogin(user);

  }

  async signInWithGoogle() {
    let userInfo: firebase.User;
    if(this.appConfig.isInstalled) {
      console.log('Signing in with CFA');
      userInfo = await cfaSignIn('google.com').toPromise()
        .catch(error => {
          ErrorReporter.report(error, { message: 'AuthenticationService: Error logging in with CFA' });
          throw error;
        });
    } else {
      console.log('Signing in with firebase auth');
      const provider = new firebase.auth.GoogleAuthProvider();
      const credential = await this.fireAuth.signInWithPopup(provider)
      .catch(error => {
        ErrorReporter.report(error, { message: 'AuthenticationService: Error logging in with Google popup' });
        throw error;
      });
      userInfo = credential.user;
    }
    const user = this.convertToUser(userInfo);
    return await this.userService.afterLogin(user);
  }

  async forgotPassword(email: string, _returnUrl?: string) {
    await this.fireAuth.sendPasswordResetEmail(email)
      .catch(error => ErrorReporter.report(error, { message: `AuthenticationService: Error sending password reset`, email}));
  }

  async verifyResetCode(code: string) {
    await this.fireAuth.verifyPasswordResetCode(code)
  }

  async resetPassword(code: string, email: string) {
    this.fireAuth.confirmPasswordReset(code, email)
  }

  async signUpWithPassword(email: string, password: string) {
    const credential = await this.fireAuth.createUserWithEmailAndPassword(email, password);
    if(credential?.user) {
      const user = this.convertToUser(credential.user);
      return await this.userService.afterLogin(user);
    }
    console.warn('User was not created', email);
    return null;
  }

  async signInWithPassword(email: string, password: string) {
    const credential = await this.fireAuth.signInWithEmailAndPassword(email, password);
    const user = this.convertToUser(credential.user);
    return await this.userService.afterLogin(user);
  }

  private async afterUserInitialized(user?: User) {
    if(!user) { return user; }

    const timezone = DateTimestamp?.local()?.zoneName;
    if(timezone && user.timezone == null) {
      const form = new UserForm(user);
      form.getControl('timezone').setValue(timezone);
      form.getControl('timezone').markAsDirty();
      await this.userService.updateUser(form)
        .catch(error => ErrorReporter.report(error, { message: 'UserService: An error occurred updating users timezone' }));
    }
    return user;
  }

  private convertToUser(firebaseUser: firebase.UserInfo) {
    const nameParts = firebaseUser?.displayName ? firebaseUser.displayName.split(' ') : [];
    const user = new User({
      id: firebaseUser.uid,
      uid: firebaseUser.uid,
      firstName: nameParts[0],
      lastName: nameParts[1],
      phoneNumber: firebaseUser.phoneNumber,
      email: firebaseUser.email?.indexOf('@privaterelay.appleid.com') >= 0 ? undefined : firebaseUser.email,
    });
    return user;
  }
}
