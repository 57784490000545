import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { IRecord, Record } from '../../data/record';

export type NotificationType = 'email' | 'sms' | 'push' | 'in-app';

export interface DeliveryResponse {
  /** The type of notification this was */
  type: NotificationType;

  /** Who the notification was sent to */
  to: any;

  /** The place where the email was sent from */
  from: any;

  /** The status of this delivery */
  status: 'success' | 'failure';

  /** The statusCode of this delivery */
  statusCode: number | string;

  /** Additional properties */
  [key: string]: any;
}


export interface IDelivery extends IRecord {
  /** The type of notification this is */
  type: string;

  /** The date the notification was sent */
  sentOn: DateTimestamp | FirebaseTimestamp;

  responses: DeliveryResponse[];

  /** Additional properties */
  [key: string]: any;
}

export interface Delivery extends IDelivery { }
export class Delivery extends Record<Delivery> {
  sentOn: DateTimestamp;

  patchValue(delivery?: Partial<Delivery>) {
    super.patchValue(delivery);
    if(!delivery) { return; }
    this.sentOn = new DateTimestamp(delivery.sentOn);
  }
}
