
import { DateTimestamp } from '../../data/date-timestamp';
import { Record } from '../../data/record';

export const groupsCollectionPath = (subscriptionId: string) => `subscriptions/${subscriptionId}/groups`;
export const groupsDocumentPath = (subscriptionId: string, groupId: string) => `subscriptions/${subscriptionId}/groups/${groupId}`;

/**
 * Representation of the Subscription object
 */
export interface IGroup extends Record<IGroup> {
  name: string;
  status: 'active' | 'removed';
  openInvite: boolean;
  isDefault: boolean;
  userCount: number;
  invitedUserCount: number;
  activeUserCount: number;
  invite?: {
    code: string,
    ref: any;
  }
}

export interface Group extends IGroup { }

export class Group extends Record<IGroup> {
  startDate?: DateTimestamp;
  endDate?: DateTimestamp;
  status: 'active' | 'removed' = 'active';
  openInvite = true;

  constructor(model?: Partial<Group>) {
    super(model);
    this.patchValue(model);
  }

  patchValue(model?: Partial<Group>) {
    if (!model) { return; }
    super.patchValue(model);
    this.status = this.status ?? 'active';
    this.openInvite = this.openInvite ?? true;
    this.startDate =  model.startDate ? new DateTimestamp(model.startDate) : undefined;
    this.endDate = model.endDate ? new DateTimestamp(model.endDate) : undefined;
    this.createdAt = new DateTimestamp(model.createdAt);
    this.updatedAt = new DateTimestamp(model.updatedAt);
  }

}


// export default Subscription;
