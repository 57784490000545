import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { EventLogger, EventTypes } from '../logging/event-logger';
import { APP_CONFIG } from '../tokens';

@Injectable({
  providedIn: 'root'
})
export class EventLoggingService {
  private static logger: EventLogger;
  private static appConfig: AppConfig;

  constructor(logger: EventLogger, @Inject(APP_CONFIG) readonly appConfig: AppConfig) {
    EventLoggingService.logger = logger;
    EventLoggingService.appConfig = appConfig;
    EventLoggingService.logEvent('initialized', { message: 'The event logger service has been initialized.'})
  }

  static logEvent(event: EventTypes | string, data?: any) {
    try {
      if(!EventLoggingService.logger) {
        throw new Error('Cannot log event. Logger has not been initialized');
      }
      const logData = Object.assign(
        {},
        {
          app_viewer: this.appConfig.isInstalled ? 'app' : 'web',
          app_platform: this.appConfig.isAndroid ? 'android' : this.appConfig.isIOS ? 'iOS' : 'web',
          app_device_size: this.appConfig.deviceSize,
        },
        data
      );
      EventLoggingService.logger.logEvent(event, logData);
    } catch(e) {
      console.error('WebEventLogger: logEvent error: ' + e?.message)
      return;
    }
  }

  // TODO: Change
  static setUserId(userId?: string) {
    try {
      EventLoggingService.logger.setUserId(userId);
    } catch(e) {
      console.error('WebEventLogger: setUserId error: ' + e?.message)
    }
  }
}
