import { Observable } from 'rxjs';
import { IRecord } from './record';
import { Document } from './document';
import { DocumentClient } from './document-client';
import { take } from 'rxjs/operators';

/**
 * A documents points to a record in the database.
 */
export abstract class Collection<TRecord extends IRecord, TDocumentType = Document<TRecord>> {

  abstract get isLoading$(): Observable<boolean>
  abstract get documents$(): Observable<TDocumentType[]>;

  get collectionPath(): string { return this._collectionPath; };
  protected get status() { return this.database ? 'attached' : 'unattached'; };

  constructor(private _collectionPath: string, protected readonly database?: DocumentClient) {
  }

  async data(): Promise<TDocumentType[]> {
    return this.documents$.pipe(take(1)).toPromise();
  }

  abstract create(record: Partial<TRecord>): Promise<TDocumentType>;
}

