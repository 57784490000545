import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[triggeredAnimation]',
  animations: [
    trigger('expand', [
      state('0', style({ height: '0', opacity: '0' })),
      state('1', style({ height: '*', opacity: '1' })),
      transition('0 => 1', animate('400ms ease-in')),
      transition('1 => 0', animate('400ms ease-out'))
    ]),
    trigger('rotate180', [
      state('0', style({ transform: 'rotate(0)' })),
      state('1', style({ transform: 'rotate(180deg)' })),
      transition('1 => 0', animate('400ms ease-out')),
      transition('0 => 1', animate('400ms ease-in'))
    ])
  ],
  template: `<ng-content></ng-content>`,
})
export class ExpandAnimationComponent {
  @HostBinding('@expand') @Input() expand: boolean;
  @HostBinding('@rotate180') @Input() rotate: boolean

  constructor() {
  }
}
