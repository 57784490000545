import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, Data, Router
} from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    return this.checkAuth(state.url, next.data);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    return this.checkAuth(state.url, next.data);
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return this.checkAuth(route.path, route.data);
  }

  private async checkAuth(returnUrl: string, routeData: Data): Promise<boolean> {
    let isAuth$ = this.authService.user$.pipe(map(user => user != null));

    if(routeData?.requiresAdmin === true) {
      // Must be explicitly requires admin
      isAuth$ = this.authService.isAdmin$;
    }

    if(routeData?.requiresGlobalAdmin === true) {
      // If requires globalAdmin, this takes precedence
      isAuth$ = this.authService.isGlobalAdmin$;
    }

    const isAuth = await isAuth$.pipe(take(1)).toPromise();
    if (isAuth) {
      return true;
    }

    this.router.navigate(['/account'], { queryParams: { returnUrl }});
  }
}
