import { FormControl, ControlValueAccessor, Validator, FormGroupDirective, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Input, Output, Injectable, Directive } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty or touched. */
@Injectable()
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    return !!(control && control.invalid && (control.dirty || (form && form.submitted)));
  }
}

@Directive()
export abstract class FormField implements ControlValueAccessor, Validator {
  public errors = '';
  public hasErrors: boolean;
  protected subscription: any;

  public _value: any = null;

  @Input() name: string;
  @Input() autoComplete: boolean = true;
  @Input() formControl: FormControl;
  @Input() appearance: 'outline' | 'standard' = 'outline';
  @Input() color: 'primary' | 'accent' | 'default' | 'white' | null;

  @Input()
  public get value() { return this._value; }

  public set value(value) {
    if (this._value !== value) {
      this._value = value;
      this.executeOnChanged();
      this.executeOnTouched();
    }
  }

  @Input()
  public disabled: boolean;

  @Output()
  readonly change: EventEmitter<any> = new EventEmitter<any>();

  // TODO: DI inject
  matcher = new CustomErrorStateMatcher();

  readonly errorsChanged: EventEmitter<string> = new EventEmitter<string>();

  public onChange = (_: any) => {};
  public onTouch = (_: any) => {};
  public onValidatorChange = (_: any) => {
    console.log('validator changed', _);
  }

  constructor(private sanitizer: DomSanitizer) {
  }
  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public registerOnValidatorChange(fn: () => void) {
    this.onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if(this.formControl == null) { return; }

    this.disabled = isDisabled;
    if(this.formControl.disabled === isDisabled) { return; }
    if(isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  protected executeOnTouched() {
    this.onTouch(this.value);
    this.setErrors(this.formControl);
  }

  protected executeOnChanged() {
    this.onChange(this.value);
    this.change.emit(this.value);
  }

  public validate(formControl: FormControl) {
    this.initializeForm(formControl);
    return null;
  }

  protected initializeForm(formControl: FormControl) {
    if (!this.subscription) {
      this.formControl = formControl;
      this.subscription = formControl.valueChanges.subscribe(() => {
        this.setErrors(formControl);
        this.value = formControl.value;
      });
      formControl.statusChanges.subscribe(() => this.setErrors(formControl) );
      this.setErrors(formControl);
    }
  }

  protected setErrors(formControl: FormControl) {
    this.errors = this.getDisplayErrors(formControl);
    this.hasErrors = this.errors !== '';
  }

  protected getDisplayErrors(formControl: FormControl) {
    if (!formControl) { return ''; }

    if ((formControl.dirty) && formControl.hasError('required')) {
      return this.sanitizer.bypassSecurityTrustHtml('This field is <strong>required</strong>');
    }
    if ((formControl.dirty) && formControl.hasError('email')) {
     return this.sanitizer.bypassSecurityTrustHtml('This field must be a valid <strong>email</strong>');
    }
    if (formControl.hasError('pattern')) {
      return this.sanitizer.bypassSecurityTrustHtml('This field is not <strong>valid</strong>');
    }
    if (formControl.hasError('domain')) {
      return this.sanitizer.bypassSecurityTrustHtml('This field is not a valid <strong>domain</strong>');
    }
    if (formControl.hasError('whitespace')) {
      return this.sanitizer.bypassSecurityTrustHtml('This field cannot contain any <strong>spaces</strong>');
    }
    if (formControl.hasError('min')) {
      return this.sanitizer.bypassSecurityTrustHtml('The value is <strong>too small</strong>');
    }
    if (formControl.hasError('max')) {
      return this.sanitizer.bypassSecurityTrustHtml('The value is <strong>too large</strong>');
    }
    if (formControl.hasError('minlength')) {
      return this.sanitizer.bypassSecurityTrustHtml('This field does not reach <strong>minimum length</strong>');
    }
    if (formControl.hasError('maxlength')) {
      return this.sanitizer.bypassSecurityTrustHtml('This field exceeds <strong>maximum length</strong>');
    }
    if (formControl.hasError('url')) {
      return this.sanitizer.bypassSecurityTrustHtml('This field must be a <strong>valid url</strong>');
    }
    if (formControl.hasError('modelState')) {
      return formControl.errors.modelState;
    }
    if (formControl.hasError('duplicate')) {
      return this.sanitizer.bypassSecurityTrustHtml('This name <strong>already exists</strong>');
    }
    if (formControl.hasError('notSame')) {
      return this.sanitizer.bypassSecurityTrustHtml('Passwords do not <strong>match</strong>');
    }
    if (formControl.hasError('regex')) {
      return formControl.errors.regex;
    }
    if (formControl.hasError('mask')) {
      return this.sanitizer.bypassSecurityTrustHtml('Input does not match required <strong>format<strong>');
    }
    if (formControl.hasError('custom')) {
      return this.sanitizer.bypassSecurityTrustHtml(formControl.errors.custom);
    }

    return '';
  }
}
