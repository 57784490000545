<div fxLayout="row" fxLayoutAlign="space-between" class="footer-container" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">
  <!---->
  <div fxLayout="column" class="link-section" fxFlexOrder.lt-sm="1">

    <a mat-button [routerLink]="['contact-us']" class="contact-button padding-0">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" >
        <mat-icon>chat</mat-icon>
        <span class="mat-title margin-0">Contact Us</span>
      </div>
    </a>

    <a mat-button [href]="'mailto:info@triggered.app'" class="padding-0">

      <div fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="15px">
        <mat-icon>send_outlined</mat-icon>
        <span class="margin-0">info@triggered.app</span>
      </div>
    </a>

    <div class="social-media-links">
      <ng-container *ngFor="let link of socialMediaLinks">
        <!-- Many of the button svgs retrieved from here: https://murhafsousli.github.io/ngx-sharebuttons/#/themes-->
        <a mat-icon-button *ngIf="link?.url" [href]="link.url" target="_blank" rel="noopener" [attr.aria-label]="link.name">
          <mat-icon [svgIcon]="link.icon" class="grey"></mat-icon>
        </a>

      </ng-container>
    </div>
  </div>


   <div *ngIf="displayLinks" fxLayout="row wrap" fxLayoutAlign="end" fxLayoutAlign.lt-sm="start" fxFlexOrder.lt-sm="0">
    <div class="link-section" fxFlex="0 0 175px" fxLayout="column">
      <div>
        <span class="mat-h4 text-uppercase margin-0"><b>About Us</b></span>
      </div>
      <a [routerLink]="[routes.about.ourTeam.full]">
        <span class="mat-caption">Our Team</span>
      </a>
      <a [routerLink]="['pricing']">
        <span class="mat-caption">Download</span>
      </a>
      <a [routerLink]="['how-it-works']">
        <span class="mat-caption">How it Works</span>
      </a>

    </div>

    <div class="link-section"  fxFlex="0 0 175px" fxLayout="column">
      <div>
        <span class="mat-h4 text-uppercase margin-0"><b>Customer Service</b></span>
      </div>
      <a [routerLink]="[routes.about.faqs.full]">
        <span class="mat-caption">Questions and Answers</span>
      </a>
      <a [routerLink]="[routes.corporate.contact.requestInfo.full]">
        <span class="mat-caption">Request Info</span>
      </a>

    </div>

    <div class="link-section"  fxFlex="0 0 175px" fxLayout="column">
      <div>
        <span class="mat-h4 text-uppercase margin-0"><b>Legal</b></span>
      </div>
      <a [routerLink]="['legal']">
        <span class="mat-caption">Privacy Policy</span>
      </a>
      <a [routerLink]="['legal', 'terms-and-conditions']">
        <span class="mat-caption">Terms and Conditions</span>
      </a>
    </div>
  </div>


</div>
