import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ErrorReporter } from '@triggered/core';
import { DialogService, PromptData, PromptDialog } from '@triggered/ui';
import { AuthenticationService, PasswordReset, PasswordResetForm } from '../../core';

@Component({
  selector: 'triggered-change-password',
  templateUrl: './change-password.card.html',
  styleUrls: ['./change-password.card.scss']
})
export class ChangePasswordCard implements OnChanges {
  isSubmitting: boolean;

  readonly form = new PasswordResetForm();

  @Input() code: string;
  @Output() readonly reset = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();



  constructor(private readonly dialog: DialogService, private readonly authService: AuthenticationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.code && this.code) {
      this.form.getControl('code').setValue(this.code);
    }
  }

  async onResetPassword() {
    if(this.form.invalid) {
      return this.form.markAllAsDirty();
    }
    this.isSubmitting = true;
    const value:  PasswordReset = this.form.value;
    // TODO: Handle error
    await this.authService.resetPassword(value.code, value.password)
      .then(async () => {
        await PromptDialog.open(this.dialog, new PromptData('Reset', 'Your password was reset successfully!', 'Ok'))
        this.reset.emit();
      })
      .catch(async error => {
        ErrorReporter.report(error, { message: 'ChangePasswordComponent: An error occured while changing the password' });
        await PromptDialog.open(this.dialog, new PromptData('Error', 'An error occured while resetting your password', 'Ok'));
      })
      .finally(() => this.isSubmitting = false)

  }

  onCancel() {
    this.cancelled.emit();
  }

}
