export interface ContactInfo {
  /** The id of the corresponding user  */
  id?: string;

  /** The contact's firstName */
  firstName?: string;

  /** The contact's lastName */
  lastName?: string;

  /** The contact's emailAddress */
  emailAddress?: string;

  /** The contact's phoneNumber */
  phoneNumber?: string;

  /** The contact's fcm tokens used to send Push Notifications */
  pushTokens?: string[];
}

export class ContactInfo {
  static buildSearch(contact: ContactInfo) {
    const search = [
      contact?.firstName,
      contact?.lastName,
      (contact?.emailAddress || (contact as any)?.email),
      contact?.phoneNumber,
      contact?.phoneNumber?.replace(/[^\w\s]/gi, '')
    ].map(field => (field || '').toString())
      .map(value => value.toLocaleLowerCase())
      .filter(value => value != null);
    return search;
  }
}
