import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { APP_CONFIG } from '../tokens';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { Platform as IonicPlatform } from '@ionic/angular';
import { EventLogger, EventTypes } from './event-logger';
import { ErrorReporter } from '../services';

// Must import the package once to make sure the web support initializes
// import '@capacitor-community/firebase-analytics';
@Injectable()
export class AppEventLogger implements EventLogger {
  private static _isInitialized: boolean;

  constructor(@Inject(APP_CONFIG) readonly appConfig: AppConfig, private readonly platform: IonicPlatform) {
      try {
        this.initialize()
      } catch(error) {
        ErrorReporter.report(error, { message: 'AppEventLogger: Could not initialize app event logger' });
      }
  }

  setUserId(userId: string) {
    try {
      FirebaseAnalytics.setUserId({ userId: userId || ' ' });
    } catch (error){
      ErrorReporter.report(error, {message: `AppEventLogger: Could not set the userId to ${userId || ' '}`});
    }
  }

  logEvent(event: EventTypes | string, data?: any) {
    const params = JSON.parse(JSON.stringify(data));
    try {
      FirebaseAnalytics.logEvent({
        name: event,
        params
      });
      console.debug(`AppEventLogger: Event ${event} logged!`, data);
    } catch(error) {
      ErrorReporter.report(error, { message: `AppEventLogger: Could not log event ${event}`, data });
      return; // DO not throw error, just return. Logging Events is not a critical function
    }
  }

  private initialize() {
    if(AppEventLogger._isInitialized) { return; }

    if(!this.appConfig.isInstalled) {
      // Only call this method if being viewed from the browser.
      FirebaseAnalytics.initializeFirebase(this.appConfig.firebase);
    }

    // Enable Logging
    FirebaseAnalytics.setCollectionEnabled({  enabled: true });

    // Set User Properties
    FirebaseAnalytics.setUserProperty({name: "app_viewer", value: this.appConfig.isInstalled ? 'app' : 'web' });
    FirebaseAnalytics.setUserProperty({name: "app_platform", value: this.appConfig.isAndroid ? 'android' : this.appConfig.isIOS ? 'iOS' : 'web' });
    FirebaseAnalytics.setUserProperty({name: "app_device_size", value: this.appConfig.deviceSize });
    FirebaseAnalytics.setUserProperty({name: "app_logger", value: 'AppEventLogger' });
    AppEventLogger._isInitialized = true;
  }


}
