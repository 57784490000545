
<button *ngIf="!(text || buttonTemplate)" mat-icon-button #trigger="matMenuTrigger" class="button-trigger" [color]="color" [disabled]="disabled" [matMenuTriggerFor]="groupMenu" [attr.aria-label]="name + ' Menu'" (click)="$event.stopPropagation()">
  <mat-icon>{{icon}}</mat-icon>
</button>


<button *ngIf="text || buttonTemplate" mat-button #trigger="matMenuTrigger" class="button-trigger" [class.mat-stroked-button]="type === 'stroked'" [class.mat-raised-button]="type === 'raised'" [color]="color" [disabled]="disabled" [matMenuTriggerFor]="groupMenu" [attr.aria-label]="name + ' Menu'" (click)="$event.stopPropagation()">
  <template *ngIf="buttonTemplate; else defaultButton" [ngTemplateOutlet]="buttonTemplate" ></template>
</button>

<ng-template #defaultButton>
  <div fxLayout="row" [fxLayoutAlign]="(icon ? 'start' : 'center') + ' center'" fxLayoutGap="10px">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>
    <span *ngIf="text">{{text}}</span>
  </div>
</ng-template>

<mat-menu #groupMenu="matMenu" class="height-auto width-auto">
  <ng-container *ngFor="let button of menuButtons">
    <ng-template [ngTemplateOutlet]="button.template"></ng-template>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngFor="let item of menuItems">
    <a *ngIf="!item.link; else link" mat-menu-item (click)="item.click?.emit()" [disabled]="item.disabled">
      <triggered-menu-item-content [icon]="item.icon" [iconStyle]="item.iconStyle" [header]="item.header" [subheader]="item.subheader"></triggered-menu-item-content>
    </a>

    <ng-template #link>
      <ng-container [ngSwitch]="item.target">
        <a *ngSwitchCase="'_blank'" mat-menu-item [href]="item.link" [disabled]="item.disabled" target="_blank">
          <triggered-menu-item-content [icon]="item.icon" [iconStyle]="item.iconStyle" [header]="item.header" [subheader]="item.subheader"></triggered-menu-item-content>
        </a>
        <a *ngSwitchDefault mat-menu-item [routerLink]="item.link" [queryParams]="item.linkParams " [disabled]="item.disabled">
          <triggered-menu-item-content [icon]="item.icon" [iconStyle]="item.iconStyle" [header]="item.header" [subheader]="item.subheader"></triggered-menu-item-content>
        </a>
      </ng-container>

    </ng-template>
    <mat-divider></mat-divider>
  </ng-container>
</mat-menu>


