<div class="expander-container">
  <div class="expander mat-body-2" (click)="expanded = !expanded" [class.clickable]="content?.children?.length">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-icon *ngIf="icon" class="material-icons-outlined" [matTooltip]="tooltip">
        {{ icon }}
      </mat-icon>

      <div fxFlex="1 1 auto" fxLayout="column">
        <span class="mat-body-2">{{header}}</span>
        <span *ngIf="subheader" class="mat-caption">{{subheader}}</span>
      </div>

      <div *ngIf="content?.children?.length">
        <div triggeredAnimation [rotate]="expanded" fxLayout="column" fxLayoutAlign="start center" class="no-select">
          <mat-icon>expand_more</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div triggeredAnimation [expand]="expanded" class="expander-description">
    <div #content>
      <ng-content></ng-content>
      <template *ngIf="descriptionTemplate; else defaultDescription" [ngTemplateOutlet]="descriptionTemplate" ></template>
      <ng-template #defaultDescription>
        <span *ngIf="description" class="mat-hint mat-caption">
          {{description}}
        </span>
      </ng-template>
    </div>

  </div>
</div>
