<mat-form-field [appearance]="appearance" floatLabel="always" [class.ng-valid]="errors === ''" [class.margin-bottom-1]="hint != null" >

  <div matPrefix>
    <template *ngIf="prefixTemplate; else defaultPrefix" [ngTemplateOutlet]="prefixTemplate" ></template>
    <ng-template #defaultPrefix>
      <span *ngIf="prefix">{{prefix}}</span>
    </ng-template>
  </div>

  <mat-label>{{label}}</mat-label>
  <mat-chip-list #chipList aria-label="Chips Selection" [errorStateMatcher]="matcher">
    <mat-chip
      *ngFor="let option of selectedValues"
      [selectable]="false"
      [removable]="true"
      (removed)="onItemRemoved(option)">
      <ng-container *ngIf="tagTemplate == null; else tagTmp">
        {{displayPath ? option[displayPath] : option}}
      </ng-container>

      <ng-template #tagTmp>
        <template [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{item: option}"></template>
      </ng-template>

      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      [disabled]="!canAdd()"
      #chipInput
      required
      (input)="onInput($event)"
      [matChipInputSeparatorKeyCodes]="seperatorKeyCodes"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (matChipInputTokenEnd)="onAdd($event)">
  </mat-chip-list>

  <mat-hint>{{hint}}</mat-hint>
  <mat-error [innerHTML]="errors"></mat-error>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)" (change)="onChanged($event)">
    <ng-container *ngIf="options?.length">
      <mat-option *ngFor="let option of options" [value]="valuePath ? option[valuePath] : option">
        <ng-container *ngIf="optionTemplate == null; else optTemplate">
          {{displayPath ? option[displayPath] : option}}
        </ng-container>
        <ng-template #optTemplate>
          <template [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{item: option}"></template>
        </ng-template>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
