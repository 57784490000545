

import { DateTimestamp } from '../../data/date-timestamp';
import { Record } from '../../data/record';

/**
 * Representation of the Subscription object
 */
export interface IInviteConsent extends Record<IInviteConsent> {
  /** The unique invite code */
  code: string;

  /** The type of invite this is for */
  consent?: boolean;

}

export interface InviteConsent extends IInviteConsent { }
export class InviteConsent extends Record<IInviteConsent> {

  patchValue(model: IInviteConsent) {
    if(!model) { return; }
    super.patchValue(model);
    this.createdAt = new DateTimestamp(model.createdAt);
    this.updatedAt = new DateTimestamp(model.updatedAt);
  }

}

// export default Subscription;
