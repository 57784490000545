import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
const angularModules = [ CommonModule, FlexLayoutModule ]

import { PdfViewerComponent } from './pdf-viewer.component';
const components = [ PdfViewerComponent];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...angularModules
  ]
})
export class TriggeredPdfViewerModule { }
