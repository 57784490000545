import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
const angularModules = [
  CommonModule, MatIconModule, MatButtonModule, MatMenuModule
]

// Third Party Modules
import { ShareModule } from '@ngx-share/core';
const thirdPartyModules = [ ShareModule ];

// Share Components
import { ShareButtonsComponent } from './share-buttons.component';
const sharedComponents = [ ShareButtonsComponent ];


@NgModule({
  declarations: [...sharedComponents],
  exports: [...sharedComponents],
  imports: [
    ...angularModules,
    ...thirdPartyModules
  ]
})
export class TriggeredShareButtonsModule { }
