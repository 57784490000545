import { DiscountPricingFeature, GroupBillingFeature, GroupMembersFeature, GroupReportingFeature, GroupsSettingsFeature, PaymentFrequency, Plan, Pricing } from '@triggered/common';

let devPlans: Plan[];
let prodPlans: Plan[];
const devProducts = {

  groupPlan: {
    id: 'prod_KHohBZfSvbVeF1',

    smallGroupId: 'price_1JpHGtCLwIdzRt749yRUrLbV',
    smallOrganizationId: 'price_1JpHGtCLwIdzRt749yRUrLbV',
    mediumOrganizationId: 'price_1JpHGtCLwIdzRt749yRUrLbV',
    largeOrganizationId: 'price_1JpHGtCLwIdzRt749yRUrLbV'
  }
};

// TODO: Update to production products
const prodProducts = {
  groupPlan: {
    id: 'prod_KHohBZfSvbVeF1',

    smallGroupId: 'price_1K6GN5CLwIdzRt74r2XcBZf4',
    smallOrganizationId: 'price_1K6GN5CLwIdzRt74Ir6JlQ9U',
    mediumOrganizationId: 'price_1K6GN5CLwIdzRt74JQ7Jy0iX',
    largeOrganizationId: 'price_1K6GN4CLwIdzRt74kbSW3ZBH'
  }
};



function buildPlans(isProd?: boolean) {
  const products = isProd ? prodProducts : devProducts;

  const smallGroupPlan = new Plan({
    id:'v1-group-small',
    name: 'Small Group',
    description: 'Great for up to 10 users.',
    isActive: true,
    type: 'Group',
    features: [
      new GroupMembersFeature({
        title: 'Triggered for Each Group Member',
        description: 'Each user in your group receives access to the Triggered™ App and all features provided in the individual plan.',
        type: GroupMembersFeature.type,
        settings: {
          freeAdminAccount: false,
        }
      }),
      new DiscountPricingFeature({
        title: 'Discount Pricing',
        description: 'Receive a 10% off discount per user on this plan when you have a minimum of 3 users.',
        type: DiscountPricingFeature.type
      }),
      new GroupsSettingsFeature({
        title: 'Group Management',
        description: 'Manage the users in your group with group administration.',
        type: GroupsSettingsFeature.type,
        settings: {
          maxGroups: 1,
          maxAdministrators: 1
        }
      }),
      new GroupBillingFeature({
        title: 'Simple Billing',
        description: 'Billing made simple with one transaction. Prorated rates based on number of users.',
        type: GroupBillingFeature.type,
        settings: {
          individualBilling: false
        }
      })
    ],
    pricing: [
      new Pricing({
        paymentProcessor: 'stripe',
        pricingModel: 'volume',
        frequencies: [
          new PaymentFrequency({
            frequency: 'Monthly',
            price: 0,
            unitPrice: 4.99,
            productId: products.groupPlan.smallGroupId,
            volumnPricing: [
              { quantity: 3, unitPriceOverride: 4.49 }
            ]
          }),
        ],
        productId: products.groupPlan.id
      })
    ]
  });

  const smallOrganizationPlan = new Plan({
    id:'v1-organization-small',
    name: 'Small Organization',
    description: 'Great for up to 30 users.',
    isActive: true,
    type: 'Group',
    features: [
      new DiscountPricingFeature({
        title: 'Discount Pricing',
        description: 'Receive a 30% off discount per user on this plan. Base subscription rate is applied for administrative reporting, support and additional features.',
        type: DiscountPricingFeature.type
      }),
      new GroupMembersFeature({
        title: 'Triggered for Each Group Member',
        description: 'Each user in your group receives access to the Triggered™ App and all features provided in the individual plan.',
        type: GroupMembersFeature.type,
        settings: {
          freeAdminAccount: false,
        }
      }),
      // new PlanFeature({
      //   title: 'Free Admin Account',
      //   description: 'Use or test the Triggered app from your own free account.'
      // }),
      new GroupsSettingsFeature({
        title: 'Manage Up to 3 Groups',
        description: 'Create up to 3 separate groups and manage the users in each of the groups. Assign up to 3 group administrators to help manage your groups.',
        type: GroupsSettingsFeature.type,
        settings: {
          maxGroups: 3,
          maxAdministrators: 3
        }
      }),
      new GroupReportingFeature({
        title: 'Group Reporting',
        description: 'Receive insight on the success of your groups. Monitor the collective usage of each group.',
        type: GroupReportingFeature.type,
        settings: {
          collectiveReports: true,
          individualReports: false
        }
      }),
      new GroupBillingFeature({
        title: 'Simple Billing',
        description: 'Billing made simple with one transaction. Prorated rates based on number of users.',
        type: GroupBillingFeature.type,
        settings: {
          individualBilling: false
        }
      })
    ],
    pricing: [
      new Pricing({
        paymentProcessor: 'stripe',
        pricingModel: 'graduated',
        frequencies: [
          new PaymentFrequency({
            frequency: 'Monthly',
            price: 10,
            unitPrice: 3.49,
            productId: products.groupPlan.smallOrganizationId,
          }),
        ],
        productId: products.groupPlan.id
      })
    ]
  });

  const mediumOrgnizationPlan = new Plan({
    id:'v1-organization-medium',
    name: 'Medium Organization',
    description: 'Great for 30-50 users.',
    isActive: true,
    type: 'Group',
    features: [
      new DiscountPricingFeature({
        title: 'Discount Pricing',
        description: 'Receive a 40% off discount per user on this plan. Base subscription rate is applied for administrative reporting, support and additional features.',
        type: DiscountPricingFeature.type
      }),
      new GroupMembersFeature({
        title: 'Triggered for Each Group Member',
        description: 'Each user in your group receives access to the Triggered™ App and all features provided in the individual plan.',
        type: GroupMembersFeature.type,
        settings: {
          freeAdminAccount: false,
        }
      }),
      new GroupsSettingsFeature({
        title: 'Manage up to 5 Groups',
        description: 'Create up to 5 separate groups and manage the users in each of the groups. Assign up to 3 group administrators to help manage your groups.',
        type: GroupsSettingsFeature.type,
        settings: {
          maxGroups: 5,
          maxAdministrators: 5
        }
      }),
      new GroupReportingFeature({
        title: 'Group Reporting',
        description: 'Receive insight on the success of your groups. Monitor the collective usage of each group.',
        type: GroupReportingFeature.type,
        settings: {
          collectiveReports: true,
          individualReports: false
        }
      }),
      new GroupBillingFeature({
        title: 'Simple Billing',
        description: 'Billing made simple with one transaction. Prorated rates based on number of users.',
        type: GroupBillingFeature.type,
        settings: {
          individualBilling: false
        }
      })
    ],
    pricing: [
      new Pricing({
        paymentProcessor: 'stripe',
        pricingModel: 'graduated',
        frequencies: [
          new PaymentFrequency({
            frequency: 'Monthly',
            price: 25,
            unitPrice: 2.99,
            productId: products.groupPlan.mediumOrganizationId,
          }),
        ],
        productId: products.groupPlan.id
      })
    ]
  });

  const largeOrganizationPlan = new Plan({
    id:'v1-organization-large',
    name: 'Large Organization',
    description: 'Great for 50+ users.',
    isActive: true,
    type: 'Group',
    features: [
      new DiscountPricingFeature({
        title: 'Discount Pricing',
        description: 'Receive a 50% off discount per user on this plan. Base subscription rate is applied for administrative reporting, support and additional features.',
        type: DiscountPricingFeature.type
      }),
      new GroupMembersFeature({
        title: 'Triggered for Each Group Member',
        description: 'Each user in your group receives access to the Triggered™ App and all features provided in the individual plan.',
        type: GroupMembersFeature.type,
        settings: {
          freeAdminAccount: false,
        }
      }),
      new GroupsSettingsFeature({
        title: 'Manage Unlimited Groups',
        description: 'Create unlimited groups and manage the users in each of the groups. Assign up to 5 group administrators to help manage your groups.',
        type: GroupsSettingsFeature.type,
        settings: {
          maxGroups: null,
          maxAdministrators: 5
        }
      }),
      new GroupReportingFeature({
        title: 'Group Reporting',
        description: 'Receive insight on the success of your groups. Monitor the collective usage of each group.',
        type: GroupReportingFeature.type,
        settings: {
          collectiveReports: true,
          individualReports: false
        }
      }),
      new GroupBillingFeature({
        title: 'Simple Billing',
        description: 'Billing made simple with one transaction. Prorated rates based on number of users.',
        type: GroupBillingFeature.type,
        settings: {
          individualBilling: false
        }
      })
    ],
    pricing: [
      new Pricing({
        paymentProcessor: 'stripe',
        pricingModel: 'graduated',
        frequencies: [
          new PaymentFrequency({
            frequency: 'Monthly',
            price: 50,
            unitPrice: 2.49,
            productId: products.groupPlan.largeOrganizationId,
          }),
        ],
        productId: products.groupPlan.id
      })
    ]
  });
  return [smallGroupPlan, smallOrganizationPlan, mediumOrgnizationPlan, largeOrganizationPlan];
};


export const GroupPlans = (isProd: boolean) => {
  if(isProd) {
    prodPlans = prodPlans || buildPlans(true);
    return prodPlans;
  }
  devPlans = devPlans || buildPlans(false);
  return devPlans;
}
