import { Directive, OnInit, OnDestroy, Input, TemplateRef, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
  selector: '[triggeredFeature]'
})
export class FeatureDirective implements OnInit, OnDestroy {
  private _currentTemplate: TemplateRef<any>
  private _currentRef: EmbeddedViewRef<any>
  private _subscription: Subscription;

  @Input('triggeredFeature') flag: string;
  @Input('triggeredFeatureDefault') default: boolean;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private flagService: FeatureFlagService) {
  }

  ngOnInit() {
    this._subscription = this.flagService.isEnabled$(this.flag, this.default)
      .subscribe((isEnabled) => this.onChanges(isEnabled));
  }


  ngOnDestroy() {
    if(this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private onChanges(isEnabled: boolean) {
    this.setVisibility(isEnabled)
  }

  private setVisibility(canView: boolean) {
    if(canView) {
      return this.show(this.templateRef);
    } else {
      return this.clear();
    }
  }

  private show(template: TemplateRef<any>) {
    // If it is already diplayed, dont show it
    if(this._currentTemplate === template){ return; }

    // Otherwise, clear the container and update the view
    this.clear();
    this._currentRef = this.viewContainer.createEmbeddedView(template);
    this._currentTemplate = template;
  }

  private clear() {
    if(!this._currentRef){ return; }
    this.viewContainer.clear();
    this._currentRef = null;
    this._currentTemplate = null;
  }
}
