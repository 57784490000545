<form *ngIf="form$ | async as form" [formGroup]="form" (ngSubmit)="onSave(form)">
  <triggered-form-card [title]="hideHeader ? '' : 'Edit Profile'" [subtitle]="hideHeader ? '' : 'Keep your info up to date'">
    <ng-template #content>
      <triggered-textbox name="given-name" label="First Name" placeholder="First Name" [formControl]="form.getControl('firstName')"></triggered-textbox>
      <triggered-textbox name="family-name" label="Last Name" placeholder="Last Name" [formControl]="form.getControl('lastName')"></triggered-textbox>
      <triggered-textbox name="email" type="email" label="Email" placeholder="Email" [formControl]="form.getControl('email')"></triggered-textbox>
      <triggered-textbox-mask name="tel-national" label="Phone" placeholder="Phone" [formControl]="form.getControl('phoneNumber')" [textMask]="phoneMask.mask" [patterns]="phoneMask.pattern"></triggered-textbox-mask>
    </ng-template>


    <ng-template #actions>
      <triggered-form-buttons (cancelled)="onCancel(form)" [cancelText]="cancelText" [isSaving]="form.isSaving" [saveText]="saveText" [saveDisabled]="form.isSaving"></triggered-form-buttons>
      <button mat-button color="primary" (click)="onLogout()" class="small w-100" type="button">{{logoutText}}</button>

    </ng-template>
  </triggered-form-card>
</form>

