import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, Data } from '@angular/router';
import { ErrorReporter, NavigationHistoryService } from '@triggered/core';
import { FeatureFlagService } from '../services/feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private featureService: FeatureFlagService, private navigationService: NavigationHistoryService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    return this.checkAuth(state.url, next.data);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    return this.checkAuth(state.url, next.data);
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return this.checkAuth(route.path, route.data);
  }

  private async checkAuth(route: string, routeData: Data): Promise<boolean> {

    const defaultFlag = routeData?.defaultFlag ?? false;

    // Get the feature flag from the routeData
    const featureFlag = routeData?.featureFlag;
    if (featureFlag) {
      // Check if the flag is enabled
      const isEnabled = await this.featureService.isEnabled(featureFlag, defaultFlag);
      if (isEnabled){
        return true;
      }
    }

    // Flag is not enabled. Report error and stay on page
    const errorMessage = `FeatureGuard: Feature flag ${featureFlag} not ${ featureFlag ? 'enabled' : 'provided'}. Cannot navigate to ${route}`;
    ErrorReporter.report(new Error(errorMessage), { route, featureFlag });
    return await this.navigationService.navigate(this.navigationService.currentUrl);
  }
}
