import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { HeaderDirective } from './header.directive';
const exportDirectives = [ HeaderDirective ];

@NgModule({
  declarations: [...exportDirectives],
  exports: [...exportDirectives],
  imports: [
    CommonModule
  ]
})
export class LayoutDirectivesModule {

}
