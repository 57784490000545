import { Component, ContentChild, ContentChildren, Directive, EventEmitter, forwardRef, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Directive({
  selector: '[menuItem]',
  providers: []
})
export class MenuItemDirective {
  @Input() icon?: string;
  @Input() iconStyle?: 'outlined';
  @Input() header: string;
  @Input() subheader: string;

  @Input() disabled?: boolean;
  @Input() target?: string;


  /** Where the menu item should navigate */
  @Input() link?: any[] | string | null | undefined;
  @Input() linkParams?: any;
  @Output() readonly click = new EventEmitter();
}

@Directive({
  selector: '[menuButton]',
  providers: []
})
export class MenuButtonDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'triggered-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @ViewChild('trigger') trigger: MatMenuTrigger;
  @ContentChild('buttonContent', { static: true}) buttonTemplate: TemplateRef<any>;
  @ContentChildren(MenuItemDirective, {descendants: true}) menuItems : QueryList<MenuItemDirective>;
  @ContentChildren(MenuButtonDirective, {descendants: true}) menuButtons: QueryList<MenuButtonDirective>;


  @Input() icon: string = 'more_vert';
  @Input() type?: 'stroked' | 'raised';
  @Input() name?: string;
  @Input() text?: string;
  @Input() color?: string;
  @Input() disabled?: boolean;

  get opened() { return this.trigger?.menuOpen === true; }
  constructor() { }

  ngOnInit(): void {

  }

  toggle() {
    if(this.disabled) { return; }

    if(this.trigger.menuOpen) {
      this.trigger.closeMenu();
    } else {
      this.trigger.openMenu();
    }
  }

}
