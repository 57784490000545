import { Form } from '@triggered/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';

interface IPasswords {
  password: string;
  passwordConfirmation?: string;
}
export class PasswordReset implements IPasswords {
  code: string;
  password: string;
  passwordConfirmation?: string;
}

export class Login implements IPasswords {
  email: string;
  password: string;
  passwordConfirmation?: string;
}

function checkPasswords(group: Form<IPasswords>) { // here we have the 'passwords' group
  return (_control: AbstractControl): {[key: string]: any} | null => {
    const password = group.getControl('password').value;
    const confirmPassword = group.getControl('passwordConfirmation').value;

    return password === confirmPassword ? null : { notSame: true };
  };
}


export class LoginForm extends Form<Login> {
  constructor(value?: any, options?: { requirePasswordConfirmation?: boolean }) {
    super({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
      passwordConfirmation: new FormControl(null)
    });

    if(options?.requirePasswordConfirmation) {
      this.getControl('password').setValidators([Validators.required, Validators.minLength(8)]);
      this.getControl('passwordConfirmation').setValidators([checkPasswords(this)]);
    }

    if (value) {
      this.patchValue(value);
    }
  }
}


export class PasswordResetForm extends Form<PasswordReset> {
  constructor(value?: Partial<PasswordReset>) {
    const init = value || new PasswordReset();
    super({
      code: new FormControl(init?.code, [Validators.required]),
      password: new FormControl(init?.password, [Validators.required]),
      passwordConfirmation: new FormControl(init?.passwordConfirmation)
    });

    this.getControl('passwordConfirmation').setValidators([checkPasswords(this)]);
    this.getControl('password').valueChanges.subscribe(() => this.getControl('passwordConfirmation').updateValueAndValidity());
  }

}
