import { convertToDate, Form } from '@triggered/core';
import { FormControl, Validators } from '@angular/forms';
import { Record } from '@triggered/common';

export class ContactRequest extends Record<ContactRequest> {
  id: string;
  userId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  date: Date = new Date();
  selectedAbout: boolean;
  selectedPartnerships: boolean;
  selectedGroupRates: boolean;
  selectedIndividualRates: boolean;
  selectedMoreResources: boolean;

  patchValue(model: ContactRequest) {
    if(!model) { return; }
    super.patchValue(model);
    this.date = convertToDate(model.date);
  }

  getDefaults(): Partial<ContactRequest> {
    return { date: new Date() }
  }
}

export class ContactRequestForm extends Form<ContactRequest> {

  public originalValue: ContactRequest;

  constructor(value?: ContactRequest) {
    const init = value || new ContactRequest();
    super({
      id: new FormControl(null, []),
      // TODO: USe Address form
      userId: new FormControl(init.userId, []),
      firstName: new FormControl(init.firstName, [Validators.required]),
      lastName: new FormControl(init.lastName, [Validators.required]),
      email: new FormControl(init.email, [Validators.required, Validators.email]),
      phone: new FormControl(init.phone, [Validators.required]),
      message: new FormControl(init.message, [Validators.required]),
      date: new FormControl(init.date, [Validators.required]),
      selectedAbout: new FormControl(null, []),
      selectedPartnerships: new FormControl(null, []),
      selectedGroupRates: new FormControl(null, []),
      selectedIndividualRates: new FormControl(null, []),
      selectedMoreResources: new FormControl(null, []),
    });

    this.originalValue = init;
  }
}
