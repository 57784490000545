import { ISubscription } from './subscription';
import { IRecord, Record } from '../../data/record';
import { Reference } from '../../data/reference';
import { ContactInfo } from '../../models/contact-info';
import { IInvite } from './invite';
import { StatusHistory } from '../../core/models/status';

export type GroupMemberStatus = 'invited' | 'accepted' | 'removed'

export interface IGroupMember extends ContactInfo, IRecord {
  /** The unique, random ID used to manage members */
  id: string;

  /** The user's ID */
  userId?: string;

  /** The search field that can be used for key seaches */
  search?: string[];

  /** The status of the user */
  status: GroupMemberStatus;

  /** The status history */
  statusHistory: StatusHistory<GroupMemberStatus>;

  invite?: Reference<IInvite, {code: string}>;

  /** Reference to this group member's subscription */
  subscription?: Reference<ISubscription, {id: string}>;
}

export interface GroupMember extends IGroupMember { }
export class GroupMember extends Record<IGroupMember> { }

