import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TriggeredRoutes } from '@triggered/core';
import { AndroidView } from './views/download/android/android.view';
import { ChromeExtensionView } from './views/download/chrome-extension/chrome-extension.view';
import { DownloadView } from './views/download/download.view';
import { IosView } from './views/download/ios/ios.view';
import { MacView } from './views/download/mac/mac.view';
import { WindowsView } from './views/download/windows/windows.view';
import { HomeView } from './views/home/home.view';
import { HowItWorksView } from './views/how-it-works/how-it-works.view';
import { OurMissionView } from './views/our-mission/our-mission.view';

const routes: Routes = [
  {
    path: TriggeredRoutes.corporate.home,
    component: HomeView,
    data: { metaTags: HomeView.metaTags }
  },
  {
    path: TriggeredRoutes.corporate.ourMission.relative,
    component: OurMissionView,
    data: { metaTags: OurMissionView.metaTags }
  },
  {
    path: TriggeredRoutes.downloads.root,
    component: DownloadView,
    data: { metaTags: DownloadView.metaTags },
    children: [
      { path: TriggeredRoutes.downloads.android.relative, component: AndroidView},
      { path: TriggeredRoutes.downloads.windows.relative, component: WindowsView},
      { path: TriggeredRoutes.downloads.mac.relative, component: MacView},
      { path: TriggeredRoutes.downloads.ios.relative, component: IosView},
      { path: TriggeredRoutes.downloads.chrome.relative, component: ChromeExtensionView},
      // { path: TriggeredRoutes.downloads.windows.relative, component: WindowsView}
    ]
  },
  {
    path: TriggeredRoutes.corporate.howItWorks.relative,
    component: HowItWorksView,
    data: { metaTags: HowItWorksView.metaTags }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
