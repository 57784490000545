import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';

export interface IMigration {
  runAt?: DateTimestamp | FirebaseTimestamp;
  description: string;
  allowMultiple: boolean;
}

export interface Migration extends IMigration {};
export class Migration extends Model<IMigration> {
  runAt?: DateTimestamp;

  patchValue(model?: Partial<IMigration>): void {
    super.patchValue(model);
    if(!model) { return; }

    this.runAt = model.runAt ? new DateTimestamp(model.runAt) : undefined;
  }
}
export interface IMigrationSettings extends IRecord {
  id: string;
  migrations: { [id: string]: IMigration }
}

export interface Migrations extends IMigrationSettings { }
export class Migrations extends Record<Migrations> {
  id: 'migrations';
  migrations: { [id: string]: Migration }

  patchValue(model: Partial<Migration>) {
    if(!model) { return; }
    super.patchValue(model);

    // Map migrations to model
    this.migrations = this.migrations ?? {};
    Object.keys(this.migrations).forEach(key => this.migrations[key] = new Migration(this.migrations[key]))
  }

}
