
  <nav mat-tab-nav-bar fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
    <ng-container *ngFor="let tab of tabs; let isLast = last">
      <ng-container *ngIf="tab.label">
        <a mat-tab-link class="text-capitalize" [routerLink]="[tab.fullPath]" [routerLinkActive]="'disabled'" [routerLinkActiveOptions]="{exact: true}">
          {{tab.label}}
        </a>
        <mat-icon *ngIf="!isLast">chevron_right</mat-icon>
      </ng-container>
    </ng-container>
  </nav>
