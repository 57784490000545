import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
const angularModules = [ CommonModule, MatIconModule, MatMenuModule]


import { PopoverComponent } from './popover.component';
const components = [ PopoverComponent];


@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    ...angularModules
  ]
})
export class TriggeredPopoverModule { }
