import { Form } from '@triggered/core';
import { FormControl } from '@angular/forms';
import { UserNotification } from '@triggered/common';

export class UserNotificationForm extends Form<UserNotification> {
  constructor(value?: UserNotification) {
    const init = value || new UserNotification();
    super({
      id: new FormControl(init.id),
      isRead: new FormControl(init.isRead)
    });
    this.originalValue = init;
  }
}
