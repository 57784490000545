/* eslint-disable @typescript-eslint/no-empty-interface */
import { Model } from '../../data/model';

export interface IPlanFeature<TSettings = any> {
  /** The feature title */
  title: string;

  /** The feature description */
  description: string;

  /** Whether the feature is active or coming soon*/
  isActive: boolean;

  /** The type of feature this is */
  type?: string;

  /** The settings associated to this feature */
  settings?: TSettings;
}

export interface PlanFeature<TSettings = any> extends IPlanFeature<TSettings> {}
export class PlanFeature<TSettings = any> extends Model<PlanFeature<TSettings>> {
  isActive = true;

  constructor(model?: Partial<IPlanFeature<TSettings>>) {
    super(model);
    this.patchValue(model);
  }
}


export class GroupMembersFeature extends PlanFeature<{ maxUsers?: number; freeAdminAccount: boolean; }> {
  static type = 'group-members';
  type = GroupMembersFeature.type;
}

export class GroupsSettingsFeature extends PlanFeature<{maxGroups: number | null, maxAdministrators: number}> {
  static type = 'group-settings';
  type = GroupsSettingsFeature.type;
}

export class GroupBillingFeature extends PlanFeature<{ individualBilling?: boolean; }> {
  static type = 'group-billing';
  type = GroupBillingFeature.type;
}

export class GroupReportingFeature extends PlanFeature<{ collectiveReports: boolean; individualReports: boolean; }> {
  static type = 'group-reporting';
  type = GroupReportingFeature.type;
}

export class DiscountPricingFeature extends PlanFeature {
  static type = 'discount-pricing';
  type = DiscountPricingFeature.type;
}
