import { Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map, tap, } from 'rxjs/operators';
import { Devices, DevicesSizeService } from '../services/devices-size.service';

@Directive({
  selector: '[solidDeviceSize]'
})
export class DeviceSizeDirective implements OnDestroy {
  private _subscription: Subscription;
  private _previousDevice: Devices;
  constructor(private renderer: Renderer2,
              private elementRef: ElementRef,
              private readonly service: DevicesSizeService) {
    this._subscription = this.service.device$.pipe(
      filter(device => device != this._previousDevice),
      tap(device => this.renderer.addClass(this.elementRef.nativeElement, device)),
      tap(device => {
        if(this._previousDevice) {
          this.renderer.removeClass(this.elementRef.nativeElement, this._previousDevice);
        }
        this._previousDevice = device;
      })
    ).subscribe()
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }
}
