// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
const angularModules = [ CommonModule, ReactiveFormsModule ];

// Triggered Modules
import { TriggeredCardModule } from '../../layout/card/card.module';
const triggeredModules = [ TriggeredCardModule ];

// Form Card Components
import { FormCardComponent } from './form-card.component';
const externalComponents = [ FormCardComponent ];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [
    ...angularModules,
    ...triggeredModules
  ]
})
export class TriggeredFormCardModule { }
