

import { Reference } from '../../data/reference';
import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Record } from '../../data/record';
import { IPlan, PlanType } from './plan';
import { PaymentProcessor } from './product';

export const subscriptionCollectionPath = () => `subscriptions`;
export const subscriptionDocumentPath = (subscriptionId: string) => `subscriptions/${subscriptionId}`;

/**
 * Representation of the Subscription object
 */
export interface ISubscription extends Record<ISubscription> {

  /** The unique ID of the subscription */
  id: string;

  /**
   * The ID of the associated plan
   * @deprecated in favor of plan.id
   */
  planId: string;

  /**
   * The name of the selected plan.
   * @deprecated in favor of plan.name
   */
  planName: string;

  /**
   * The type of the plan
   * @deprecated in favor of plan.type
   */
  planType: PlanType;

  /** The reference to the selected plan */
  plan: Reference<IPlan, { id: string, name: string, type: PlanType }>;

  /** The user who owns this subscription */
  ownerId: string;

  /** The main parent subscription */
  parent?: Reference<ISubscription, { id: string }>;

  /** The group this member belongs to. NOTE: Using any here to prevent circular dependencies */
  group?: Reference<any, { id: string, name: string }>;

  startDate?: DateTimestamp | FirebaseTimestamp;

  endDate?: DateTimestamp | FirebaseTimestamp;

  /** Whether the subscription is a gift or not */
  isGift?: boolean;

  /** Whether the plan was cancelled */
  cancelled?: boolean;

  /** The subscriptionId in stripe */
  stripeSubscriptionId?: string;

  /** The transactionID corresponding to apple or google play */
  transactionId?: string;

  /** The processor who the payment was subitted through */
  processor?: PaymentProcessor;
}

export interface Subscription extends ISubscription { }
export class Subscription extends Record<ISubscription> {
  // Assert that these date fields are DateTimestamps
  startDate?: DateTimestamp;
  endDate?: DateTimestamp;

  static readonly collectionPath = () => `subscriptions`;
  static readonly documentPath = (subscriptionId: string) => `subscriptions/${subscriptionId}`;

  set isActive(_value: boolean) { /** DO Nothing - Catches errors when attempting an Object.assign */ }
  get isActive() { return  this.isStarted && !this.isExpired;  }

  get isStarted() { return !this.isExpired && this.startDate != null && this.startDate.getTime() <= Date.now(); }
  get isStarting() { return !this.isExpired && this.startDate != null && this.startDate.getTime() >= Date.now(); }

  get isExpired() { return this.endDate && this.endDate.getTime() <= Date.now(); }
  get isExpiring() { return this.endDate != null && this.endDate.getTime() >= Date.now(); }

  get isGroupMember() {
    return this.planType === 'Group-Member' && this.parent?.id != null;
  }

  get isIndividual() {
    return this.planType === 'Individual';
  }

  isGroupAdmin(userId: string) {
    return this.planType === 'Group' && this.ownerId === userId;
  }

  patchValue(model: Subscription) {
    if(!model) { return; }
    super.patchValue(model);

    if(this.planType == null) {
      // NOTE: This can be removed once subscriptions have been fixed
      this.planType = this.parent?.id ? 'Group-Member' : 'Individual';
      console.error(`Subscription ${this.id} has no plan type. Setting to ${this.planType}.`);
    }

    this.startDate =  model.startDate ? new DateTimestamp(model.startDate) : undefined;
    this.endDate = model.endDate ? new DateTimestamp(model.endDate) : undefined;
    this.createdAt = new DateTimestamp(model.createdAt);
    this.updatedAt = new DateTimestamp(model.updatedAt);
  }

}

// export default Subscription;
