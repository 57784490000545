import { Component, OnInit } from '@angular/core';
import { ErrorReporter } from '@triggered/core';
import { DialogService, Masks, PromptData, PromptDialog } from '@triggered/ui';
import { ContactRequestForm } from '../../core/models/contact-request.model';
import { ContactRequestService } from '../../services/contact-request.service';

@Component({
  selector: 'triggered-request-info-form',
  templateUrl: './request-info.form.html',
  styleUrls: ['./request-info.form.scss']
})
export class RequestInfoForm implements OnInit {
  readonly phoneMask = Masks.phoneNumber;

  isSubmitting: boolean;
  readonly form = new ContactRequestForm();

  constructor(private readonly dialogService: DialogService, private contactService: ContactRequestService) { }

  ngOnInit(): void {
  }

  async onSubmit() {
    if(this.form.invalid) {
      return this.form.markAllAsDirty();
    }

    const response = await this.contactService.sendContactRequest(this.form).catch(async error => {
      ErrorReporter.report(error, { message: 'RequestInfoForm: Error occured sending contact request form' });
      await PromptDialog.open(this.dialogService, new PromptData('Error', 'An error occured submitting your request. Please try again.', 'Ok'));
    });

    if(response) {
      await PromptDialog.open(this.dialogService, new PromptData('Thank You', 'Your request has been sent. We will be respond as soon as possible.', 'Ok'))
      this.form.reset();
    }

  }
}
