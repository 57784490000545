import { Directive, OnChanges, OnDestroy, Input, TemplateRef, ViewContainerRef, SimpleChanges, EmbeddedViewRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../services/subscription.service';

@Directive({
  selector: '[triggeredSubscribed]'
})
export class SubscribedDirective implements OnChanges, OnDestroy {
  private _currentTemplate: TemplateRef<any>
  private _currentRef: EmbeddedViewRef<any>
  private _subscription: Subscription;

  @Input('triggeredSubscribed') userId?: string;

  @Input() triggeredSubscribedElse: TemplateRef<any>;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private subscriptionService: SubscriptionService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges();
  }

  ngOnDestroy() {
    if(this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private onChanges() {
    this._subscription?.unsubscribe();
    const isRegistered = this.subscriptionService.userIsRegistered$(this.userId)
    this._subscription = isRegistered.subscribe(isActive => this.setVisibility(isActive))

  }

  private setVisibility(canView: boolean) {
    if(canView) {
      return this.show(this.templateRef);
    } else if(this.triggeredSubscribedElse) {
      return this.show(this.triggeredSubscribedElse);
    } else {
      return this.clear();
    }
  }

  private show(template: TemplateRef<any>) {
    // If it is already diplayed, dont show it
    if(this._currentTemplate === template){ return; }

    // Otherwise, clear the container and update the view
    this.clear();
    this._currentRef = this.viewContainer.createEmbeddedView(template);
    this._currentTemplate = template;
  }

  private clear() {
    if(!this._currentRef){ return; }
    this.viewContainer.clear();
    this._currentRef = null;
  }
}
