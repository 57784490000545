import { PaymentFrequency, Plan, PlanFeature, Pricing } from '@triggered/common';

const devProducts = {
  battlePlan: {
    id: 'prod_I0ebUcJo5GL4U3',

    monthly: {
      id: 'price_1IaMQZCLwIdzRt74vmRlSqjV',
      stripePrice: 4.99,
      applePrice: 6.99,
      overrides: {
        android: {
          productId: 'dev_individual_v1_monthly',
          priceId: 'dev-individual-v1-monthly',
          price: 5.99
        }
      }
    },
    yearly: {
      id: 'price_1IaMQZCLwIdzRt74nt7u9xJN',
      stripePrice: 49.99,
      applePrice: 69.99,
      overrides: {
        android: {
          productId: 'dev_individual_v1_yearly',
          priceId: 'dev-individual-v1-yearly',
          price: 59.99
        }
      }
    }
  }
};

const prodProducts = {
  battlePlan: {
    id: 'prod_JSZprhp3d67EXR',

    monthly: {
      id: 'price_1IpefaCLwIdzRt74VeASkxO9',
      stripePrice: 4.99,
      applePrice: 6.99,
      overrides: {
        android: {
          productId: 'prod_individual_v1_monthly',
          priceId: 'price-individual-v1-monthly',
          price: 5.99
        }
      }
    },
    yearly: {
      id: 'price_1IpefaCLwIdzRt74bEZ2No79',
      stripePrice: 49.99,
      applePrice: 69.99,
      androidPrice: 59.99,
      overrides: {
        android: {
          productId: 'prod_individual_v1_yearly',
          priceId: 'price-individual-v1-yearly',
          price: 59.99
        }
      }
    }
  }
};


let devPlan: Plan;
let prodPlan: Plan;

export const IndividualPlan = (isProd: boolean) => {
  if(isProd) {
    prodPlan = prodPlan || buildPlan(true);
    return prodPlan;
  }
  devPlan = devPlan || buildPlan(false);
  return devPlan;
}

function buildPlan(isProd?: boolean) {
  const products = isProd ? prodProducts : devProducts;
  return new Plan({
    id: 'individual-plan-v1', // This cannot change!
    isActive: true,
    name: 'Individual Plan',
    type: 'Individual',
    features: [
      new PlanFeature({
        title: 'Instant Alerts',
        description: 'Triggered alerts are sent out immediately to the support team you build.',
        isActive: true
      }),
      new PlanFeature({
        title: 'Up to 5 Support Partners',
        description: 'Your support partners can download the app and receive your alerts free of charge.',
        isActive: true
      }),
      new PlanFeature({
        title: 'Text, Email and Push Notifications',
        description: 'Notifications are sent out immediately to your partner via text, email and push notifications.',
        isActive: true,
      }),
      new PlanFeature({
        title: 'Access on any Supported Devices',
        description: `Subscribe for Triggered and receive access from any supported devices you wish to use it on.`,
        isActive: true
      }),
      new PlanFeature({
        title: 'Trigger Trends and Reports',
        description: 'Coming Soon! Triggered trends reveal when you most often press the Triggered Button and can help you predict when you may need it again. Helping you be proactive in the fight!.',
        isActive: false
      }),
      new PlanFeature({
        title: 'Voice Commands',
        description: `Voice commands for Google Assistant™ and Amazon Alexa™ are coming soon. Simply say "Hey Google, I'm Triggered" to notify your team.`,
        isActive: false
      })
    ],
    pricing: [
      new Pricing({
        paymentProcessor: 'stripe',
        frequencies: [
          new PaymentFrequency({
            frequency: 'Monthly',
            price: products.battlePlan.monthly.stripePrice,
            productId: products.battlePlan.monthly.id
          }),
          new PaymentFrequency({
            frequency: 'Yearly',
            price: products.battlePlan.yearly.stripePrice,
            productId: products.battlePlan.yearly.id
          })
        ],
        productId: products.battlePlan.id
      }),
      new Pricing({
        paymentProcessor: 'apple-iap',
        frequencies: [
          new PaymentFrequency({
            frequency: 'Monthly',
            price: products.battlePlan.monthly.applePrice,
            productId: products.battlePlan.monthly.id,
            priceBreakdown: [
              {
                title: 'Triggered Subscription',
                price: 4.99
              },
              {
                title: 'In-App Processing Fees',
                price: 2.00,
                description: 'Monthly charge for purchasing outside of the website.'
              }
            ]
          }),
          new PaymentFrequency({
            frequency: 'Yearly',
            price: products.battlePlan.yearly.applePrice,
            productId: products.battlePlan.yearly.id,
            priceBreakdown: [
              {
                title: 'Triggered Subscription',
                price: 49.99
              },
              {
                title: 'In-App Processing Fees',
                price: 20.00,
                description: 'Yearly charge for purchasing outside of the website.'
              }
            ]
          })
        ],
        productId: products.battlePlan.id
      }),
      new Pricing({
        paymentProcessor: 'android-iap',
        frequencies: [
          new PaymentFrequency({
            frequency: 'Monthly',
            price: products.battlePlan.monthly.overrides.android.price,
            productId: products.battlePlan.monthly.overrides.android.productId,
            priceBreakdown: [
              {
                title: 'Triggered Subscription',
                price: 4.99
              },
              {
                title: 'In-App Processing Fees',
                price: 1.00,
                description: 'Monthly charge for purchasing outside of the website.'
              }
            ]
          }),
          new PaymentFrequency({
            frequency: 'Yearly',
            price: products.battlePlan.yearly.overrides.android.price,
            productId: products.battlePlan.yearly.overrides.android.productId,
            priceBreakdown: [
              {
                title: 'Triggered Subscription',
                price: 49.99
              },
              {
                title: 'In-App Processing Fees',
                price: 10.00,
                description: 'Yearly charge for purchasing outside of the website.'
              }
            ]
          })
        ],
        productId: products.battlePlan.id
      }),
    ]
  });
}

