import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { PromptDialog } from './dialogs/prompt/prompt.dialog';
import { MatButtonModule } from '@angular/material/button';
const angularModules = [ CommonModule, FlexLayoutModule, MatButtonModule, MatDialogModule ]
const angularExportModules = [ MatDialogModule ];

import { TriggeredDialogServiceModule } from './services/dialog.module';
const internalModules = [TriggeredDialogServiceModule];

@NgModule({
  declarations: [PromptDialog],
  imports: [
    ...angularModules,
    ...internalModules
  ],
  exports: [
    ...angularExportModules
  ]
})
export class TriggeredDialogModule { }
