import { BehaviorSubject, Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { Document, DocumentClient, DocumentOptions, IRecord } from '@triggered/common';
import { DocumentChangeAction, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { FirebaseHelper } from './firebase.helper';

export class FirestoreDocument<TRecord extends IRecord> extends Document<TRecord> {

  private readonly _isLoading$ = new BehaviorSubject<boolean>(true);

  readonly data$: Observable<TRecord | null>;
  readonly isLoading$ = this._isLoading$.asObservable();

  constructor(collectionPath: string,
              id: string,
              data$: Observable<TRecord | null> | TRecord | null,
              database?: DocumentClient) {
    super(id, collectionPath, database);
    this.data$ = (data$ instanceof Observable ? data$ : of(data$)).pipe(
      tap(() => this._isLoading$.next(false)),
      shareReplay(1));
  }

  async updateValue(value: Partial<TRecord>) {
    const data = await super.updateValue(value);
    this._id = data.id;
    return data;
  }

  delete() {
    return this.database.deleteDocument(`${this.collectionPath}/${this.id}`);
  }
}

export class FirestoreQueryDocument<TRecord extends IRecord> extends FirestoreDocument<TRecord>{
  readonly record: TRecord;
  readonly snapshot: QueryDocumentSnapshot<TRecord>;

  constructor(change: DocumentChangeAction<TRecord>, options: DocumentOptions<TRecord>, database?: DocumentClient) {
    const {collectionPath, documentId} = FirebaseHelper.getPathParts(change.payload.doc.ref.path);
    const record = FirebaseHelper.docToModel(change.payload.doc, options);

    super(collectionPath, documentId, record, database);

    this.record = record;
    this.snapshot = change.payload.doc;

  }

}
