import { FormControl, Validators } from '@angular/forms';
import { Form, Model } from '@triggered/core';

export type UserSettingsId = 'stripe'
| 'app-settings'
| 'devices'
| 'alert-settings'
| 'fcmSettings';


export abstract class UserSettingsModel {
  abstract id: UserSettingsId
}

export abstract class UserSettingsForm<TModel extends UserSettingsModel> extends Form<TModel> {
  isSaving: boolean;
}

export class AppSettings extends Model<AppSettings> implements UserSettingsModel {
  readonly id: UserSettingsId = 'app-settings';
  readonly autoTrigger: boolean;
  readonly pushToken: string;

  readonly darkMode: boolean;

  constructor(model?: Partial<AppSettings>) {
    super(model);
  }

  getDefaults(): Partial<AppSettings> {
    return { id: 'app-settings', autoTrigger: true, darkMode: false };
  }
}

export class AppSettingsForm extends UserSettingsForm<AppSettings> {

  constructor(model?: Partial<AppSettings>) {
    model = model || new AppSettings();
    super({
      id: new FormControl(model?.id, [Validators.required]),
      autoTrigger: new FormControl(model?.autoTrigger == null ? true : model?.autoTrigger),
      pushToken: new FormControl(model?.pushToken),
      darkMode: new FormControl(model?.darkMode || false)
    })
  }
}
