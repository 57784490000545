import { Component, OnInit } from '@angular/core';
import { MetaTags, TriggeredRoutes } from '@triggered/core';
import { Steps } from '../../../constants/steps';

@Component({
  selector: 'triggered-how-it-works',
  templateUrl: './how-it-works.view.html',
  styleUrls: ['./how-it-works.view.scss']
})
export class HowItWorksView implements OnInit {
  static readonly metaTags: MetaTags = new MetaTags({
    title: 'How Triggered Works - Learn How You Can Overcome Addictions',
    description: 'Overcome addiction with the Triggered App. ' +
      'Simply create an account, setup accountability and download the app. ' +
      'Press the Triggered button when you need help and ' +
      'rest assured that someone will be reaching out.',
    ogImage: 'https://firebasestorage.googleapis.com/v0/b/solid-apps-triggered.appspot.com/o/solid-apps-triggered%2Fimages%2FSEO%2FTriggered%20-%20Overcome%20Addictions.jpg?alt=media&token=bc3c7bdb-4d7f-4e4e-a530-0c15cb54ddfe'
  });

  readonly routes = TriggeredRoutes;
  readonly Steps = Steps;

  constructor() {
   }

  ngOnInit(): void {
  }

}
