
import { IGroup, ISubscriptionAdmin } from '.';
import { DeepPartial, Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';
import { Reference } from '../../data/reference';

export const groupSettingsDocumentId = 'group-settings';
export const groupSettingsDocumentPath = (subscriptionId: string) => `subscriptions/${subscriptionId}/settings/group-settings`;
export const groupSettingsCollectionPath = (subscriptionId: string) => `subscriptions/${subscriptionId}/settings`;

export interface IGroupInfo {
  id: string;
  activeUserCount: number,
  invitedUserCount: number
}

export interface GroupInfo extends IGroupInfo { }
export class GroupInfo extends Model<IGroupInfo> {
  userCount: number = 0;
  activeUserCount: number = 0;

  constructor(model?: Partial<IGroupInfo>) {
    super(model);
    this.patchValue(model);
  }

}

/**
 * Representation of the Subscription object
 */
export interface IGroupSettings extends IRecord {
  id: string | 'group-settings';

  /** The maximum users allowed in this group. Unlimited if undefined. */
  maxUsers?: number;

  /** The maximum number of groups. Unlimited if undefined. */
  maxGroups?: number;

  /** The maximum number of group administrators. Needs at least 1 */
  maxAdministrators: number;

  /** The type of reports provided */
  reports: {
    /** Whether individual reports are included */
    individual: boolean;

    /** Whether group reports are included */
    group: boolean;
  }

  /**
   * The information related to the groups in this subscription
   * @deprecated Use groups instead
   */
  groupInfo: IGroupInfo[];

  groups: {[id: string]: Reference<IGroup, { activeUserCount: number, invitedUserCount: number }>};


  /** The information related to the groups in this subscription */
  administrators: {[id: string]: Reference<ISubscriptionAdmin>};
}

export interface GroupSettings extends IGroupSettings { }
export class GroupSettings extends Record<GroupSettings> {
  id = 'group-settings';

  administratorCount: number;

  constructor(value?: DeepPartial<GroupSettings>) {
    super(value);
    this.patchValue(value);
  }

  patchValue(model?: DeepPartial<GroupSettings>) {
    this.administratorCount = Object.keys(model?.administrators || {}).length || 0;
    if(!model) { return; }
    super.patchValue(model);
    this.groups = this.groups || {};

    // NOTE 1/11/2022: REMOVE THIS AFTER A FEW RELEASES
    if(Object.keys(this.groups).length) {
      // We switched over to use groups instead of group info
      this.groupInfo = Object.keys(this.groups).map(key => ({id: key, ...this.groups[key]}));
    }

  }
}
