import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum Devices {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile'
}

@Injectable({
  providedIn: 'root'
})
export class DevicesSizeService {
  readonly isMobile$: Observable<boolean>;
  readonly isTablet$: Observable<boolean>;
  readonly isDesktop$: Observable<boolean>;
  readonly device$: Observable<Devices>;

  constructor(public readonly media: MediaObserver) {
    this.isMobile$ = this.checkAlias$(['xs']);
    this.isTablet$ = this.checkAlias$(['sm', 'md']);
    this.isDesktop$ = this.checkAlias$(['lg', 'xl']);

    this.device$ = combineLatest([this.isMobile$, this.isTablet$, this.isDesktop$]).pipe(
      map(([isMobile, isTablet, _isDesktop]) => isMobile ? Devices.mobile : (isTablet ? Devices.tablet : Devices.desktop))
    )
  }

  private checkAlias$(alias: string | string[]) {
    const aliases = alias instanceof Array ? alias : [alias];
    return this.media.asObservable().pipe(
      map(changes => changes.some(change => aliases.includes(change.mqAlias)))
    );
  }
}
