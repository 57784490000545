import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export type NetworkStatus = 'wifi' | 'cellular' | 'none' | 'unknown';

@Injectable({providedIn: 'root'})
export class NetworkProvider {
  readonly currentStatus: NetworkStatus = 'unknown';
  readonly status$: Observable<NetworkStatus> = of('unknown');
}
