<mat-form-field [appearance]="appearance" floatLabel="always" [class.ng-valid]="errors === ''" [class.margin-bottom-1]="hint != null" [class.mat-form-field-readonly]="readonly">

  <mat-label>{{label}}</mat-label>

  <triggered-editor [formControl]="formControl" [errorState]="hasErrors"></triggered-editor>

  <mat-hint>{{hint}}</mat-hint>

  <mat-error [innerHTML]="errors"></mat-error>

</mat-form-field>

<!-- {{ formControl?.value  | json}} -->
