export class Mask {
  constructor(public mask: string, public pattern: { [character: string]: { pattern: RegExp, optional?: boolean} }) {
  }
}

export class Masks {
  public static phoneNumber: Mask = new Mask('(200)-000-0000',  {
    // 1: { pattern: new RegExp('\\d{1}'), optional: true}, // Can be a 2 digit extension
    2: { pattern: new RegExp('\[2-9\]')}, // Must be 2-9 or A-Z
    0: { pattern: new RegExp('\[0-9\]')} // Can be 0-9 or A-Z
  });
  public static wholeNumber: Mask = new Mask('0*',  {0: { pattern: new RegExp('\[0-9\]')}});
  public static positiveWholeNumber: Mask = new Mask('10*',  {
    1: { pattern: new RegExp('\[1-9\]')},
    0: { pattern: new RegExp('\[0-9\]'), optional: true},
  });

}
