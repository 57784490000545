import { IInvite, IPlan, ISubscription } from '.';
import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { IRecord, Record } from '../../data/record';
import { Reference } from '../../data/reference';

export interface ISimpleContact {
  name: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface IGiftMessage {
  name?: string;
  isAnonymous: boolean;
  message?: string;
}

export interface ICampaign extends IRecord {
  name: string;
}
export type IGiftDesignationType = null | 'Random' | 'Individual' | 'Campaign';
export interface IGiftDesignation {
  type?: IGiftDesignationType;

  campaign?: Reference<ICampaign, {id: string, name: string}>;
  contact?: ISimpleContact;
  message?: IGiftMessage
}

export interface IGift extends Record<IGift> {

  /** Who this gift is intended for */
  designation: IGiftDesignation;

  /** The plan that has been donated */
  plan: Reference<IPlan, {id: string, name: string}>;

  /** The id of the plan that has been donated */
  subscription?: Reference<ISubscription, {id: string}>;

  /**
   * The id of the invite this gift pertains to
   */
  invite?: Reference<IInvite, {id: string, code: string}>;

  /**
   * When the invite is openly available to the public.
   * Up until this time, the user may assign the invite to
   * a specific person
   */
  availableAt: DateTimestamp | FirebaseTimestamp;

  /** If this invite has been redeemed */
  redeemed: boolean;

  /** When this invite was redeemed */
  redeemedAt?: DateTimestamp | FirebaseTimestamp;

  /** The duration that this plan is good for after redemption */
  duration: {
    years?: number;
    months?: number;
  };
}

export interface GiftSubscription extends IGift {}
export class GiftSubscription extends Record<GiftSubscription> {
  redeemedAt?: DateTimestamp;

  // Order of operations
  // 1.) super(model) is called first and sets base properties
  // 2.) Property initializers run and reset properties to initiated values
  // 3.) Patch value runs and sets the properties
  constructor(model?: Partial<GiftSubscription>) {
    super(model);
    this.patchValue(model);
  }

  patchValue(model?: Partial<GiftSubscription>) {
    if (!model) { return; }
    super.patchValue(model);
    this.redeemedAt = model.redeemedAt ? new DateTimestamp(model.redeemedAt) : undefined;
  }
}
