import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { MetaTags } from '../models/meta-tags.model';

@Injectable()
export class MetaTagService {

  constructor(private readonly meta: Meta, private readonly titleService: Title) {
  }

  updateTags(tags: MetaTags) {
    if(!tags) {
      // TODO: Should we handle if tags are not specified? i.e. - revert to default?
      console.warn('No tags were provided for this route.');
      return;
    }

    this.titleService.setTitle(tags.title);
    this.addOrUpdateTags([
      // Default Meta Tags
      { name: 'description', content: tags.description },

      // Open Graph protocol uses property:'og: ' as the meta
      { property: 'og:type', content: 'article' },
      { property: 'og:title', content: tags.ogTitle || tags.title },
      { property: 'og:description', content: tags.ogDescription || tags.description },
      { property: 'og:image', content: tags.ogImage },

      // Twitter protocol: https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup
      { name: 'twitter:title', content: tags.twitterTitle || tags.title },
      { name: 'twitter:description', content: tags.twitterDescription || tags.description },
      { name: 'twitter:image:src', content: tags.twitterImageSrc || tags.ogImage }

      // TODO:
      // name="author"
      // property="og:image:height"
      // property="og:image:width"
      //
    ]);
  }

  private addOrUpdateTags(tags: MetaDefinition[]) {
    tags.forEach(tag => {
      let existing: HTMLMetaElement;
      if(tag.property) {
        existing = this.meta.getTag(`property="${tag.property}"`);
      } else if(tag.name) {
        existing = this.meta.getTag(`name="${tag.name}"`);
      }

      if(existing) {
        this.meta.updateTag(tag);
      } else {
        this.meta.addTag(tag);
      }
    });
  }

}
