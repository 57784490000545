import { Form, SOLIDFormArray } from '@triggered/core';
import { FormControl } from '@angular/forms';
import { UserDevice, UserDevices } from '@triggered/common';

export class UserDeviceForm extends Form<UserDevice> {
  constructor(value?: UserDevice) {
    const init = value || new UserDevice();
    super({
      pushToken: new FormControl(init?.pushToken),
      dateLastLogin: new FormControl(init?.dateLastLogin),
      appVersion: new FormControl(init?.appVersion),
      appId: new FormControl(init?.appId),
      appBuild: new FormControl(init?.appBuild),
      platform: new FormControl(init?.platform),
      model: new FormControl(init?.model),
      osVersion: new FormControl(init?.osVersion),
      operatingSystem: new FormControl(init?.operatingSystem),
    })
  }

}

export class UserDevicesForm extends Form<UserDevices> {
  isSaving: boolean;
  constructor(value?: UserDevices) {
    const init = value || new UserDevices();
    super({
      id: new FormControl(init.id),
      devices: new SOLIDFormArray(init.devices, (user) => new UserDeviceForm(user))
    });
    this.originalValue = init;
  }
}
