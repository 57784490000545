import { Form, SOLIDFormArray } from '@triggered/core';
import { FormControl, Validators } from '@angular/forms';
import { FeatureFlag, FeatureFlags } from '@triggered/common';

export type Features = 'partner-force-download';

export class FeatureFlagForm extends Form<FeatureFlag> {

  constructor(value?: FeatureFlag) {
    const init = value || new FeatureFlag();
    super({
      id: new FormControl(init.id, [Validators.required]),
      display: new FormControl(init.display, [Validators.required]),
      isActive: new FormControl(init.isActive, [])
    });
    this.originalValue = init;
  }
}


export class FeatureSettingsForm extends Form<FeatureFlags> {

  public get flags(): SOLIDFormArray<FeatureFlag>  {
    return this.getFormArray<FeatureFlag>('flags');
  }


  constructor(value?: FeatureFlags) {
    const init = value || new FeatureFlags();
    super({
      id: new FormControl(init.id, [Validators.required]),
      flags: new SOLIDFormArray(init.flags, (flag) => new FeatureFlagForm(flag))
    });
    this.originalValue = init;
  }
}
