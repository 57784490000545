import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'triggered-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss']
})
export class FormCardComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() loading: boolean;

  @ContentChild('content') contentTemplate: TemplateRef<any>;
  @ContentChild('actions') actionsTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
