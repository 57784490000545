import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
const angularModules = [
  CommonModule, RouterModule, FlexLayoutModule, MatIconModule
]

// Components
import { NavigationChipsComponent } from './navigation-chips.component';
const externalComponents = [ NavigationChipsComponent ];



@NgModule({
  declarations: [
    ...externalComponents
  ],
  imports: [
    ...angularModules
  ],
  exports: [
    ...externalComponents
  ]
})
export class NavigationChipsModule { }
