import { Record } from '../../data/record';

export interface ITrial extends Record<ITrial> {
  days: number;
}

export interface Trial extends ITrial {}
export class Trial extends Record<Trial> {
  static readonly collectionPath = () => `modules/store/trials`;
  static readonly documentPath = (trialId: string) => `modules/store/trials/${trialId}`;

  constructor(model?: Partial<Trial>) {
    // Order of operations
    // 1.) super(model) is called first and sets base properties
    super(model);

    // 2.) Property initializers run and reset properties to initiated values
    // 3.) Patch value runs and sets the properties
    this.patchValue(model);
  }
}
