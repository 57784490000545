// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/auth';
const angularModules = [ CommonModule, AngularFireAuthModule ];

// Internal Modules
const internalModules = [ ];

// Shared Directives
import { AuthDirective } from './directives/auth.directive';
import { FeatureDirective } from './directives/feature.directive';
import { SubscribedDirective } from './directives/subscribed.directive';
const sharedDirectives = [AuthDirective, FeatureDirective, SubscribedDirective];

@NgModule({
  declarations: [...sharedDirectives],
  exports: [...sharedDirectives],
  imports: [
    ...angularModules,
    ...internalModules
  ]
})
export class AuthenticationCoreModule { }
