// Angular Modules
import { APP_INITIALIZER, InjectionToken, isDevMode, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FIREBASE_OPTIONS } from '@angular/fire';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/auth';
import { AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR, SETTINGS as FIRESTORE_SETTINGS  } from '@angular/fire/firestore';
import { USE_EMULATOR as DATABASE_EMULATOR } from '@angular/fire/database';
import { NEW_ORIGIN_BEHAVIOR, USE_EMULATOR as FUNCTIONS_EMULATOR, ORIGIN as FUNCTIONS_ORIGIN } from '@angular/fire/functions';
const exportModules = [ FlexLayoutModule ];
const angularModules = [
  CommonModule,
  AngularFireAuthModule,
  AngularFireAnalyticsModule,
  AngularFirestoreModule.enablePersistence({synchronizeTabs: true})
];

// TODO: Refine these and eliminate the ones that are not necessary
// Internal Directives: Available for all apps
import { AppConfig, APP_CONFIG, BASE_API_ROUTE, COMPANY_ID } from '@triggered/core';

// Register the Triggered Icons
import { TriggeredIconsModule } from '@triggered/ui';
const triggeredModules = [ TriggeredIconsModule.forRoot() ];


// Configuration
import { ConfigService } from './services/config.service';
const TEMP_APP_CONFIG = new InjectionToken<Partial<AppConfig>>('TEMP_CONFIG_TOKEN');

function loadConfig(config: ConfigService, appConfig: Partial<AppConfig> ) {

  return () => config.configureApp(appConfig);
}

function appConfigFactory(config: ConfigService, appConfig: Partial<AppConfig> ) {
  const configuration = config.configureApp(appConfig);
  return configuration;
}

/**
 * The SharedModule is a common import by all projects. This should not be imported
 * by any other module, other than the app.module
 */
 @NgModule({
  declarations: [],
  exports: [ TriggeredIconsModule, ...exportModules],
  imports: [
    ...angularModules,
    ...triggeredModules,
    ...exportModules
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
  ]

})
export class SharedModule {
  static forRoot(appConfig: Partial<AppConfig>): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // Register this first: Only used temporarily to register the app_initializer
        { provide: TEMP_APP_CONFIG, useValue: appConfig },

        // Configures the app
        { provide: APP_INITIALIZER, useFactory: loadConfig, deps: [ConfigService, TEMP_APP_CONFIG],  multi: true },

        // Configure Tokens based on app config
        { provide: APP_CONFIG, useFactory: appConfigFactory, deps: [ConfigService, TEMP_APP_CONFIG] },
        { provide: COMPANY_ID, useFactory: (appConfig: AppConfig) => appConfig.companyId, deps: [APP_CONFIG] },
        { provide: BASE_API_ROUTE, useFactory: (appConfig: AppConfig) => appConfig.endpoints?.baseApiUrl, deps: [APP_CONFIG]  },

        // Register these tokens from the appConfig directly. They get used before APP_INITIALIZER runs
        { provide: FIREBASE_OPTIONS, useFactory: () => appConfig.firebase },
        { provide: AUTH_EMULATOR, useFactory: () => appConfig.useEmulator && !appConfig.production ? ['localhost', 9099] : undefined },
        { provide: FIRESTORE_EMULATOR, useFactory: () => appConfig.useEmulator && !appConfig.production ? ['localhost', 8080] : undefined },
        { provide: DATABASE_EMULATOR, useFactory: () => appConfig.useEmulator && !appConfig.production ?  ['localhost', 9000] : undefined },
        { provide: FUNCTIONS_EMULATOR, useFactory: () => appConfig.useEmulator && !appConfig.production ? ['localhost', 5001] : undefined },
        { provide: NEW_ORIGIN_BEHAVIOR, useValue: true },
        { provide: FUNCTIONS_ORIGIN, useFactory: () => isDevMode() ? undefined : location.origin },

        // { provide: FIRESTORE_SETTINGS, useFactory: (appConfig.useEmulator && (typeof window !== 'undefined') && (window as any)?.Cypress) ? { experimentalForceLongPolling: true, merge: true } : FIRESTORE_SETTINGS },
        {
          provide: FIRESTORE_SETTINGS,
          useFactory: () => {
            try {
              if (appConfig.useEmulator && (window as any)?.Cypress) {
                return { experimentalForceLongPolling: true, merge: true };
              }
            }catch(error) { }

            return FIRESTORE_SETTINGS
          }
        }

      ]
    };
  }
}
