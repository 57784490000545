import { Form } from '@triggered/core';

export type SettingsId = 'features';

export abstract class SettingsModel {
  abstract id: SettingsId
}

export abstract class SettingsForm<TModel extends SettingsModel> extends Form<TModel> {
  isSaving: boolean;
}
