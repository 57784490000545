<triggered-page>
  <div fxLayout="row" fxLayoutAlign="start">
    <div fxFlex="0 1 500px" fxFlexOffset.gt-md="15%" fxLayout="column" fxLayoutAlign="start" fxLayoutAlign.lt-md="center"  fxLayoutGap="1em">

      <div fxLayout="column">
        <h1 class="mat-title margin-0">Welcome to Triggered</h1>
        <h2 class="mat-subheading-2 margin-0">You are an Overcomer!</h2>
      </div>

      <mat-divider></mat-divider>

      <triggered-login [requireProfile]="false" (afterLogin)="onAfterLogin($event)"></triggered-login>
    </div>
  </div>
</triggered-page>
