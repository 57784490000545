import { Directive, EmbeddedViewRef, Inject, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppConfig, AppPlatform } from '../config';
import { APP_CONFIG } from '../tokens';

@Directive({
  selector: '[triggeredPlatform]'
})
export class PlatformDirective implements OnChanges {
  private _currentTemplate: TemplateRef<any>
  private _currentRef: EmbeddedViewRef<any>

  @Input('triggeredPlatform') platform: AppPlatform | AppPlatform[];
  @Input() triggeredPlatformIsInstalled: boolean;

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  ngOnChanges() {
    const platforms = this.platform instanceof Array ? this.platform : [this.platform];
    if(platforms.includes(this.appConfig.platform)) {
      if(this.triggeredPlatformIsInstalled == null || this.appConfig.isInstalled === this.triggeredPlatformIsInstalled) {
        return this.show(this.templateRef);
      }
    }
    return this.clear();
  }


  private show(template: TemplateRef<any>) {
    // If it is already diplayed, dont show it
    if(this._currentTemplate === template){ return; }

    // Otherwise, clear the container and update the view
    this.clear();
    this._currentRef = this.viewContainer.createEmbeddedView(template);
    this._currentTemplate = template;
  }

  private clear() {
    if(!this._currentRef){ return; }
    this.viewContainer.clear();
    this._currentRef = null;
  }
}
