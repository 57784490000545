import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ParamMap } from '@angular/router';

export class QueryParamHelper {
  static params$<TParams = any>(activatedRoute: ActivatedRoute) {
    return activatedRoute.paramMap.pipe(
      map(params => QueryParamHelper.convertToObject(params, false) as TParams)
    );
  }

  static queryParams$<TParams = any>(activatedRoute: ActivatedRoute) {
    return activatedRoute.queryParamMap.pipe(
      map(params => QueryParamHelper.convertToObject(params, false) as TParams)
    );
  }

  static convertToObject<TParams = any>(params: ParamMap, includeNull: boolean) {
    const model = params.keys.reduce((obj, key) => {
      const value = params.get(key);
      if(value || includeNull) { obj[key] = value; }
      return obj;
    }, {});
    return model as TParams;
  }

  static convertToParams(model: any, includeNull: boolean) {
    const params = Object.keys(model).reduce((obj, key) => {
      const value = model[key];
      if(value || includeNull) { obj[key] = value; }
      return obj;
    }, {});
    return params;
  }
}


/**
 * https://nils-mehlhorn.de/posts/angular-navigate-back-previous-page
 */
@Injectable({providedIn: 'root'})
export class NavigationHistoryService {
  private readonly history: string[] = []

  get currentUrl(): string {
    return this._routeChanged.value;
  };

  private readonly _routeChanged = new BehaviorSubject<string>(null);
  readonly routeChanged = this._routeChanged.asObservable();

  private readonly path$: Observable<string>;

  constructor(readonly router: Router) {
    const urlChanged$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects),
      filter(url => this.history[this.history.length - 1] !== url)
    );

    urlChanged$.subscribe((currentUrl) => {
      console.log('HistoryService: Adding History', currentUrl);
      this._routeChanged.next(currentUrl);
      this.history.push(currentUrl)
    });
  }

  async back(fallback?: string) {
    this.history.pop(); // Pop the current element
    const previousUrl = this.history.pop(); // Pop the element to go back to
    if (previousUrl) {
      console.log(`HistoryService: Navigating back to ${previousUrl}`);
      await this.router.navigateByUrl(previousUrl, {replaceUrl: true});
    } else {
      // Navigate to root if there is no more history
      console.log('HistoryService: Navigating to root or fallback');
      await this.router.navigateByUrl(fallback || '/', {replaceUrl: true});
    }
  }

  async navigate(fullPath: string) {
    return await this.router.navigateByUrl(`/${fullPath}`);
  }
}
