import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const angularModules = [ CommonModule ];
const angularSharedModules = [ MatChipsModule ];

// Chips Components
import { MiniChipsComponent } from './mini-chips/mini-chips.component';
import { MatChipsModule } from '@angular/material/chips';
const externalComponents = [ MiniChipsComponent ];

@NgModule({
  declarations: [...externalComponents],
  exports: [ ...externalComponents, ...angularSharedModules ],
  imports: [
    ...angularModules,
    ...angularSharedModules
  ]
})
export class TriggeredMiniChipsModule { }
