import { Directive, OnInit, OnChanges, OnDestroy, Input, TemplateRef, ViewContainerRef, SimpleChanges, EmbeddedViewRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Devices, DevicesSizeService } from '../services/devices-size.service';
@Directive({
  selector: '[solidDevice]'
})
export class DeviceDirective implements OnInit, OnChanges, OnDestroy {
  private _currentTemplate: TemplateRef<any>
  private _currentRef: EmbeddedViewRef<any>
  private _currentDevice: Devices;
  private _subscription: Subscription;

  @Input('solidDevice')
  device: Devices | Devices[] | string | string[];

  @Input()
  solidDeviceElse: TemplateRef<any>;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private sizeService: DevicesSizeService) {

  }

  ngOnInit() {
    this._subscription = this.sizeService.device$
      .subscribe(device => {
        this._currentDevice = device;
        this.onChanges();
      });
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.onChanges();
  }

  ngOnDestroy() {
    if(this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private onChanges() {
    const device = this.device instanceof Array ? this.device : [this.device];
    this.setVisibility(device?.includes(this._currentDevice));
  }

  private setVisibility(canView: boolean) {
    if(canView) {
      return this.show(this.templateRef);
    } else if(this.solidDeviceElse) {
      return this.show(this.solidDeviceElse);
    } else {
      return this.clear();
    }
  }

  private show(template: TemplateRef<any>) {
    // If it is already diplayed, dont show it
    if(this._currentTemplate === template){ return; }

    // Otherwise, clear the container and update the view
    this.clear();
    this._currentRef = this.viewContainer.createEmbeddedView(template);
    this._currentTemplate = template;
  }

  private clear() {
    if(!this._currentRef){ return; }
    this.viewContainer.clear();
    this._currentRef = null;
  }
}
