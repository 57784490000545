export function convertToDate(firebaseDate: any): Date {
  if (firebaseDate == null) {
    return null;
  }

  if (firebaseDate instanceof Date) {
    return firebaseDate;
  }

  const milliseconds = firebaseDate.seconds * 1000 + firebaseDate.nanoseconds / 1e6;
  return new Date(milliseconds);
}
