
<!-- Horizontal Stepper for desktop/tablet -->
<ng-container *ngIf="!isVertical; else vertical">
  <mat-horizontal-stepper fxFlex [linear]="true" [selectedIndex]="activeIndex" (selectionChange)="onIndexChanged($event.selectedIndex)" [class.hide-header]="hideHeader"  labelPosition="bottom">
    <mat-step *ngFor="let step of steps; let i = index" [label]="step.tabHeader" [completed]="step.isComplete$ | async" [routerLink]="['/' + step.route]" [state]="step.icon">
      <div *ngIf="i === activeIndex" [class.padding-top-1]="!hideHeader">
        <router-outlet></router-outlet>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</ng-container>

<!-- Vertical Stepper for mobile -->
<ng-template #vertical>
  <mat-vertical-stepper fxFlex [selectedIndex]="activeIndex" [linear]="true" (selectionChange)="onIndexChanged($event.selectedIndex)">
    <mat-step *ngFor="let step of steps; let i = index" [label]="step.tabHeader" [completed]="step.isComplete$ | async" [routerLink]="['/' + step.route]" [state]="step.icon">
      <div *ngIf="i === activeIndex" [class.padding-top-1]="!hideHeader">
        <router-outlet></router-outlet>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</ng-template>
