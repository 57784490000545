import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
const angularModules = [
  CommonModule, RouterModule, FlexLayoutModule,
  MatIconModule, MatButtonModule, MatDividerModule,
  MatToolbarModule,
];
const angularExportModules = [
  FlexLayoutModule, MatIconModule, MatButtonModule, MatDividerModule
];

// Triggered Modules
import { TriggeredCoreModule } from '../../core.module';
const triggeredModules = [ TriggeredCoreModule ];

// Triggered Components
import { FooterComponent } from './footer/footer.component';
import { PageComponent } from './page/page.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
const triggeredComponents = [ FooterComponent, PageComponent, ToolbarComponent ];

@NgModule({
  declarations: [...triggeredComponents ],
  imports: [
    ...angularModules,
    ...triggeredModules
  ],
  exports: [
    ...triggeredComponents,
    ...angularExportModules
  ]
})
export class TriggeredLayoutModule { }
