import { ValidatorFn, AbstractControl } from '@angular/forms';

/**
 * Returns validation errors if the formControl consists of only spaces
 */
export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const hasWhitespace = (control.value || '').indexOf(' ') >= 0;
    return hasWhitespace ? { 'whitespace': true } : null
  };
}
