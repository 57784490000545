import { Injectable } from '@angular/core';
import { FeatureFlag, FeatureFlags } from '@triggered/common';
import { FirestoreDatabase } from '@triggered/firestore';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Features } from '../models/feature-settings.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private readonly flags$: Observable<FeatureFlag[]>;

  constructor(database: FirestoreDatabase) {
    const document = database.getDocument('settings', 'features', FeatureFlags);
    this.flags$ = document.data$.pipe(
      map(settings => settings?.flags || []),
      shareReplay(1)
    );

  }

  isEnabled$(flagId: Features | string, defaultValue: boolean = false) {
    return this.flags$.pipe(
      map(flags => flags.find(flag => flag.id === flagId)),
      map(flag => flag ? flag.isActive : (defaultValue || false))
    )
  }

  async isEnabled(flagId: Features | string, defaultValue: boolean = false) {
    return await this.isEnabled$(flagId, defaultValue).pipe(take(1)).toPromise();
  }
}
