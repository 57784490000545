import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TriggeredRoutes } from '@triggered/core';
import { LegalView } from './legal/legal.view';
import { PrivacyPolicyView } from './legal/privacy-policy/privacy-policy.view';
import { TermsAndConditionsView } from './legal/terms-and-conditions/terms-and-conditions.view';

const routes: Routes = [
  {
    path: '',
    component: LegalView,
    children: [
      { path: '', redirectTo: TriggeredRoutes.legal.privacyPolicy.relative, pathMatch: 'full' },
      { path: TriggeredRoutes.legal.privacyPolicy.relative, component: PrivacyPolicyView },
      { path: TriggeredRoutes.legal.termsAndConditions.relative, component: TermsAndConditionsView },
    ]
   }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LegalRoutingModule { }
