

import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { IRecord, Record } from '../../data/record';

export const inviteCollectionPath = () => `modules/store/invites`;
export const inviteDocumentPath = (inviteId: string) => `${inviteCollectionPath()}/${inviteId}`;

export type InviteType = 'partner-request'
  | 'admin-invite'
  | 'group-invite'
  | 'referral'
  | 'gift';

export interface IInviteAcception {
  consent: boolean;
  acceptedBy: string;
  acceptedAt: DateTimestamp | FirebaseTimestamp;
}

/**
 * Representation of the Subscription object
 */
export interface IInvite<TData = any> extends IRecord {

  /** The unique ID of the subscription */
  id: string;

  /** The unique invite code */
  code: string;

  /** The type of invite this is for */
  type?: InviteType;

  /** Those who have accepted the invite */
  responses: IInviteAcception[];

  /** When this invite expires */
  expiresAt: DateTimestamp | FirebaseTimestamp;

  /** Additional data used while accepting this invite. Public to user accepting the invite. */
  data?: TData;
}

export interface Invite extends IInvite { }
export class Invite<TData = any> extends Record<Invite> {
  expiresAt: DateTimestamp;
  data?: TData;

  set isActive(_value: boolean) { /** DO Nothing - Catches errors when attempting an Object.assign */ }
  get isActive() {
    return this.expiresAt == null || this.expiresAt.getTime() >= Date.now();
  }

  patchValue(model: Invite) {
    if(!model) { return; }
    super.patchValue(model);
    this.expiresAt = new DateTimestamp(model.expiresAt);
    this.createdAt = new DateTimestamp(model.createdAt);
    this.updatedAt = new DateTimestamp(model.updatedAt);
  }

}

// export default Subscription;
