// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const angularModules = [ CommonModule ];

// Beta Modules
import { ContactCoreModule } from '../core/core.module';
const contactModules = [ ContactCoreModule ];

@NgModule({
  declarations: [],
  imports: [
    ...angularModules,
    ...contactModules
  ]
})
export class ContactServicesModule { }
