// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule, MatIconModule, FlexLayoutModule,
  MatInputModule, MatFormFieldModule, MatPasswordStrengthModule.forRoot()
];
const exportedAngularModules = [
  FormsModule, ReactiveFormsModule
]

// Textbox Components
import { CustomErrorStateMatcher } from '../form-field';
import { PasswordComponent } from './password.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
const exportComponents = [ PasswordComponent, PasswordStrengthComponent ];

@NgModule({
  declarations: [...exportComponents ],
  imports: [
    ...angularModules
  ],
  exports: [
    ...exportedAngularModules,
    ...exportComponents
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher }
  ]
})
export class TriggeredPasswordModule { }
