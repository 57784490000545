import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';

export interface IDiscount {
  /** The code entered in by the user */
  code: string;

  /** The id of the coupon that is being applied */
  couponId?: string;

  /** The id of the promo that is being applied */
  promoId?: string;

  /** The id of the applied trial */
  trialId?: string;

  discount: {
    /** The percentage off the total */
    percentOff?: number | null,

    /** The amount off of the total */
    amountOff?: number | null,

    /** The length of the trial in days */
    trialPeriod?: number;

    /** The date the trial ends */
    trialEndDate?: DateTimestamp | FirebaseTimestamp;
  }

  /** The Products this coupon applies to */
  productIds?: string[] | undefined;
}


export interface Discount extends IDiscount { }
export class Discount extends Model<Discount> {

  constructor(model?: Partial<Discount>) {
    // Order of operations
    // 1.) super(model) is called first and sets base properties
    // 2.) Property initializers run and reset properties to initiated values
    // 3.) Patch value runs and sets the properties (Cannot be done in the base class)
    super(model);
    this.patchValue(model);
  }
}
