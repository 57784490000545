import { FormControl } from '@angular/forms';

export class NumberControl extends FormControl {

  setValue(value: any, options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
  }): void {
    value =  value ? Number(value) : null;
    return super.setValue(value, options);
  }
}
