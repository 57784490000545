import { Component, OnInit } from '@angular/core';
import { MetaTags, TriggeredRoutes } from '@triggered/core';

@Component({
  selector: 'triggered-download',
  templateUrl: './download.view.html',
  styleUrls: ['./download.view.scss']
})
export class DownloadView implements OnInit {
  static readonly metaTags: MetaTags = new MetaTags({
    title: 'Download the Triggered App - Overcome Addictions Today',
    description: 'Download the Triggered App wherever you can use it the most. ' +
      'Available on your Android, iOS, Windows, Mac and more! ' +
      'Find other ways to make accountability easy to reach out.',
    ogImage: 'https://firebasestorage.googleapis.com/v0/b/solid-apps-triggered.appspot.com/o/solid-apps-triggered%2Fimages%2FSEO%2FTriggered%20-%20Overcome%20Addictions.jpg?alt=media&token=bc3c7bdb-4d7f-4e4e-a530-0c15cb54ddfe'
  });


  readonly platforms = [
    { display: 'Windows', icon: 'windows-client', route: TriggeredRoutes.downloads.windows.full},
    { display: 'Android', icon: 'android-os', route: TriggeredRoutes.downloads.android.full },
    { display: 'iOS', icon: 'ios-logo', route: TriggeredRoutes.downloads.ios.full },
    // { display: 'Mac', icon: 'laptop_mac', route: TriggeredRoutes.downloads.mac.full },
    { display: 'Chrome', icon: 'chrome', route: TriggeredRoutes.downloads.chrome.full },

  ]
  constructor() { }

  ngOnInit(): void {
  }

}
