import { Component, Input } from '@angular/core';

export class MiniChip {
  isDisabled?: boolean;
  constructor(readonly text: string, readonly color?: 'primary' | 'default') {}
}

@Component({
  selector: 'triggered-mini-chips',
  templateUrl: './mini-chips.component.html',
  styleUrls: ['./mini-chips.component.scss']
})
export class MiniChipsComponent {

  @Input() chips: MiniChip[];

  constructor() { }



}
