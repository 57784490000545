import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
const angularModules = [ CommonModule, MatSnackBarModule, MatButtonModule ]
const angularExportModules = [ MatSnackBarModule ];

import { ToasterServicesModule } from './services/services.module';
import { ToasterService } from './services/toaster.service';
const internalModules = [ ToasterServicesModule ];
@NgModule({
  declarations: [],
  imports: [
    ...angularModules,
    ...internalModules
  ],
  exports: [
    ...angularExportModules
  ]
})
export class TriggeredToasterModule {
  constructor(_toasterService: ToasterService) {
  }
 }
