<div fxFlex="0 1 500px" fxLayout="column" fxLayoutGap="2em">
  <div class="">
    <h3 class="mat-h2 margin-0">iOS App</h3>
    <h4 class="mat-h3 margin-0">Coming Summer 2021</h4>
  </div>

  <div class="mat-body-1">
    <p>
      The Triggered App will be made available to the App Store in the summer of 2021
      making Triggered available to all of your iOS devices.
    </p>

    <p class="margin-bottom-0">Subscribe below to receive product updates so that you can be the first to overcome with Triggered.</p>
  </div>

  <div class="w-100">
    <triggered-sendgrid-form fxFlex="0 1 500px" (submitted)="onSubmitted($event)" [displayName]="false"></triggered-sendgrid-form>
  </div>
</div>
