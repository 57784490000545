<form *ngIf="form" [formGroup]="form" fxLayout="column" (ngSubmit)="onSubmit()">
  <triggered-form-card [title]="header" [subtitle]="subheader">
    <ng-template #content>

      <!-- First and Last Name -->
      <ng-container  *ngIf="!hideContactInfo">
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="1em" class="w-100">
          <triggered-textbox fxFlex="1 1 auto" name="given-name" label="First Name" placeholder="First Name" formControlName="firstName"></triggered-textbox>
          <triggered-textbox fxFlex="1 1 auto" name="family-name" label="Last Name" placeholder="Last Name" formControlName="lastName"></triggered-textbox>
        </div>

        <!-- Email and Phone -->
        <triggered-textbox name="email" type="email" label="Email" placeholder="Email" formControlName="email"></triggered-textbox>
        <triggered-textbox-mask name="tel-national" label="Phone" placeholder="Phone" formControlName="phone" [textMask]="phoneMask.mask" [patterns]="phoneMask.pattern"></triggered-textbox-mask>
      </ng-container>

      <!-- Message -->
      <triggered-textarea label="Message" formControlName="message" [minRows]="3" placeholder="Enter your message"></triggered-textarea>
    </ng-template>


    <ng-template #actions>
      <!-- Form Buttons -->
      <triggered-form-buttons [isSaving]="isSubmitting" saveText="Submit" cancelText="Cancel" savingText="Validating..." (cancelled)="onCancel()"></triggered-form-buttons>

    </ng-template>
  </triggered-form-card>
</form>

