import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';
import { GroupMembersFeature, IPlanFeature, PlanFeature } from './plan-feature';
import { PaymentProcessor } from './product';


export type Frequency = 'Monthly' | 'Yearly';

export interface IPaymentFrequency {
  /** The recurring billing frequency */
  frequency: Frequency;

  /** The recurring price for this frequency */
  price: number;

  /** The price charged per unit */
  unitPrice?: number;

  /** The ProductID associated with this payment processor */
  productId: string;

  /** The breakdown of the price associated with this variation */
  priceBreakdown?: { title: string, description?: string, price?: number }[];

  /** The breakpoints for different quantities */
  volumnPricing?: { quantity: number, unitPriceOverride?: number, priceOverride?: number}[];
}

export interface PaymentFrequency extends IPaymentFrequency { }
export class PaymentFrequency extends Model<IPaymentFrequency> {

}

/** The different pricing based upon the corresponding payment processor */
export interface IPricing {
  /** The payment processor who is managing the subcriptions */
  paymentProcessor: PaymentProcessor;

  /** The type of pricing this subscription uses */
  pricingModel?: 'graduated' | 'volume';

  /** The subscription frequencies available to the user */
  frequencies: PaymentFrequency[];

  /** The reference to the parent product. Currently only applicable to stripe */
  productId?: string;
}

export interface Pricing extends IPricing { }
export class Pricing extends Model<Pricing> {
    getFrequency(frequency: Frequency) {
    return (this.frequencies || []).find(freq =>freq.frequency === frequency);
  }
}


export type PlanType = 'Individual' | 'Group' | 'Group-Member' | 'Group-Admin';

/**
 * Representation of a plan
 */
export interface IPlan extends IRecord {
  /** The ID of the given plan */
  id: string

  /** The display name of the plan */
  name: string;

  /** This plan's description */
  description?: string;

  /** The display name of the plan */
  type: PlanType;

  /** Whehter the plan is still active */
  isActive?: boolean;

  /** Features  */
  features: IPlanFeature[];

  /** The different pricing  */
  pricing: IPricing[];
}

export interface Plan extends IPlan {};
export class Plan extends Record<IPlan> {

  pricing: Pricing[];
  features: PlanFeature[];

  get hasFreeAdminAccount() {
    return this.findFeature<GroupMembersFeature>(GroupMembersFeature.type)?.settings?.freeAdminAccount === true;
  }

  patchValue(value: Partial<Plan>) {
    super.patchValue(value);
    this.pricing = (value?.pricing || []).map(freq => new Pricing(freq));
  }

  getDefaults(): Partial<Plan> {
    return {pricing: [], features: []};
  }

  getPricing(processor: PaymentProcessor) {
    return this.getPricings(processor)[0];
  }

  getPricings(processor: PaymentProcessor) {
    return (this.pricing || []).filter(price => price.paymentProcessor === processor);
  }

  findFeature<TFeature extends IPlanFeature>(featureType: string): TFeature {
    const feature = this.features?.find(feature => feature.type === featureType);
    return feature ? feature as any : feature;
  }


}
