<!-- Main Share button -->
<button mat-icon-button [matMenuTriggerFor]="shareMenu" aria-label="Share Menu">
  <mat-icon>share</mat-icon>
</button>

<!-- Share menu items -->
<mat-menu #shareMenu="matMenu">
  <button mat-menu-item shareButton="twitter" [url]="url || config?.url">
    <mat-icon svgIcon="twitter-1" class="clickable"></mat-icon>
    <span>Twitter</span>
  </button>
  <button mat-menu-item shareButton="pinterest" [url]="url || config?.url">
    <mat-icon svgIcon="pinterest-1" class="clickable"></mat-icon>
    <span>Pinterest</span>
  </button>
  <button mat-menu-item shareButton="facebook" [url]="url || config?.url">
    <mat-icon class="material-icons-outlined">facebook</mat-icon>
    <span>Facebook</span>
  </button>
  <button mat-menu-item shareButton="linkedin" [url]="url || config?.url">
    <mat-icon svgIcon="linked-in-1" class="clickable"></mat-icon>
    <span>LinkedIn</span>
  </button>
  <button mat-menu-item shareButton="email" [url]="url || config?.url">
    <mat-icon class="material-icons-outlined">email</mat-icon>
    <span>Email</span>
  </button>
  <button mat-menu-item shareButton="sms" [url]="url || config?.url">
    <mat-icon class="material-icons-outlined">chat</mat-icon>
    <span>Text Message</span>
  </button>
</mat-menu>
