import { Form } from '@triggered/core';

export type SubscriptionSettingsId = 'coupon' | 'pricing' | 'plan';

export const SubscriptionSettingsIds = Object.freeze({
  coupon: 'coupon' as SubscriptionSettingsId,
  pricing: 'pricing' as SubscriptionSettingsId,
  plan: 'plan' as SubscriptionSettingsId
});

export abstract class SubscriptionSettingsModel {
  abstract id: SubscriptionSettingsId | string;
}

export abstract class SubscriptionSettingsForm<TModel extends SubscriptionSettingsModel> extends Form<TModel> {
  isSaving: boolean;
}
