import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
const angularModules = [
  CommonModule, FlexLayoutModule, RouterModule, MatSidenavModule,
  MatIconModule, MatButtonModule
];

// Triggered Modules
import { TriggeredNavMenuModule } from '../nav-menu';
const triggeredModules = [ TriggeredNavMenuModule ];

// Sidenav Router Components
import { SidenavRouterComponent } from './sidenav-router.component';
const externalComponents = [ SidenavRouterComponent ];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [
    ...angularModules,
    ...triggeredModules
  ]
})
export class TriggeredSidenavRouterModule { }
