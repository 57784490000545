import { Component, OnInit } from '@angular/core';
import { DialogService, PromptData, PromptDialog } from '@triggered/ui';

@Component({
  selector: 'triggered-mac',
  templateUrl: './mac.view.html',
  styleUrls: ['./mac.view.scss']
})
export class MacView implements OnInit {



  constructor(private readonly dialogService: DialogService) { }

  ngOnInit(): void {
  }

  async onSubmitted(model: any){
    // TODO: Handle errors on all of these responses. Redirect to contact request if this is not working
    await PromptDialog.open(this.dialogService, new PromptData('Congratulations', 'You have taken the first step in overcomming with Triggered. \n  We will inform you of product updates and when Triggered is available', 'Ok'));
  }
}
