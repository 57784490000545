// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
const angularModules = [ CommonModule ];
const sharedAngularModules = [ FlexLayoutModule, RouterModule, MatMenuModule, MatButtonModule, MatIconModule, MatDividerModule ]

// Menu Components
import { MenuComponent, MenuItemDirective, MenuButtonDirective } from './menu.component';
import { MenuItemContentComponent } from './menu-item-content/menu-item-content.component';
const sharedMenuComponents = [ MenuComponent, MenuItemContentComponent ];


@NgModule({
  declarations: [
    MenuItemDirective,
    MenuButtonDirective,
    ...sharedMenuComponents,
  ],
  imports: [
    ...angularModules,
    ...sharedAngularModules
  ],
  exports: [
    ...sharedAngularModules,
    ...sharedMenuComponents,
    MenuItemDirective,
    MenuButtonDirective
  ]
})
export class TriggeredMenuModule { }
