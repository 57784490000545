import { Component } from '@angular/core';
import { Devices, MetaTags, TriggeredRoutes } from '@triggered/core';
import { Steps } from '../../../constants/steps';

@Component({
  selector: 'triggered-home',
  templateUrl: './home.view.html',
  styleUrls: ['./home.view.scss']
})
export class HomeView {
  static readonly metaTags: MetaTags = new MetaTags({
    title: 'Triggered App - Overcome Addiction with Accountability',
    description: 'Overcome addiction with one press of a button! ' +
      'Download the Triggered App to break free from pornography, ' +
      'alcohol, smoking, drugs, suicidal thoughts and more. ' +
      'There is power in accountability.',
    ogImage: 'https://firebasestorage.googleapis.com/v0/b/solid-apps-triggered.appspot.com/o/solid-apps-triggered%2Fimages%2FSEO%2FTriggered%20-%20Overcome%20Addictions.jpg?alt=media&token=bc3c7bdb-4d7f-4e4e-a530-0c15cb54ddfe'
  })

  readonly Devices = Devices;
  readonly routes = TriggeredRoutes;
  readonly steps = Steps.AllSteps;
  constructor() {
  }
}
