import { convertToDate } from './converters';

export abstract class Model<TType = Model<any>> {
  updatedAt?: Date;
  createdAt?: Date;

  static create<TModel extends Model>(model: (new (value?: TModel) => TModel), value: TModel): TModel {
    return new model(value);
  }

  constructor(originalValue?: Partial<TType>) {
    Object.assign(this, this.getDefaults());
    if (originalValue) {
      this.patchValue(originalValue);
    }
  }

  patchValue(model: Partial<TType>) {
    if (!model) { return; }
    Object.assign(this, model);
    this.updatedAt = convertToDate(this.updatedAt);
    this.createdAt = convertToDate(this.createdAt);
  }


  getDefaults(): Partial<any> { return {}; }

  clean(): any {
    return this.removeEmpty(this);
  }

  private removeEmpty(obj: any) {
    return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? this.removeEmpty(v) : v }),
      {}
    );
  }
}
