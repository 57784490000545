import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
const angularModules = [ CommonModule, RouterModule, FlexLayoutModule, MatTabsModule ];

const solidModules = [ ];

// Internal Modules
import { TriggeredLayoutModule } from '@triggered/core';
import { LegalRoutingModule } from './legal-routing.module';
import { LegalComponentsModule } from '../components/components.module';
const internalModules = [ LegalRoutingModule, TriggeredLayoutModule, LegalComponentsModule ]

// Internal Views
import { LegalView } from './legal/legal.view';
import { PrivacyPolicyView } from './legal/privacy-policy/privacy-policy.view';
import { TermsAndConditionsView } from './legal/terms-and-conditions/terms-and-conditions.view';
const internalViews = [ LegalView, PrivacyPolicyView, TermsAndConditionsView];

@NgModule({
  declarations: [...internalViews ],
  imports: [
    ...angularModules,
    ...solidModules,
    ...internalModules
  ]
})
export class LegalViewsModule { }
