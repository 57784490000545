// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
const angularModules = [
  CommonModule, RouterModule, ReactiveFormsModule, FlexLayoutModule, MatDividerModule,
  MatButtonModule, MatIconModule, MatStepperModule, MatProgressSpinnerModule
];

// SOLID Modules
import { PlatformDirective } from '@triggered/core';
const solidModules = [  ]

// Triggered Modules
import { AuthenticationCoreModule } from '../core/core.module';
import { TriggeredTextboxModule, TriggeredPasswordModule, TriggeredTextboxMaskModule, TriggeredFormCardModule, TriggeredCardModule, TriggeredFormButtonsModule } from '@triggered/ui';
import { LegalComponentsModule } from '@triggered/legal';
const triggeredModules = [
  TriggeredTextboxModule, TriggeredPasswordModule, TriggeredTextboxMaskModule, TriggeredFormButtonsModule,
  AuthenticationCoreModule, LegalComponentsModule, TriggeredFormCardModule, TriggeredCardModule
];

// Triggered Components
import { LoginComponent, LoginStepService } from './login/login.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { ChangePasswordCard } from './change-password/change-password.card';
const exportComponents = [ LoginComponent, ProfileFormComponent, ChangePasswordCard ];

@NgModule({
  declarations: [...exportComponents, PlatformDirective ],
  exports: [...exportComponents],
  imports: [
    ...angularModules,
    ...triggeredModules,
    ...solidModules
  ],
  providers: [
    LoginStepService // Make this instance per instance
  ]
})
export class AuthenticationComponentsModule { }
