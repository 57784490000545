import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'triggered-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @ContentChild('notification') notificationTemplate: TemplateRef<any>;

  @Input() notificationRowClass: string;
  constructor() { }

  ngOnInit(): void {
  }

}
