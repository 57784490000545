// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
const angularModules = [
  CommonModule, MatIconModule, MatTooltipModule, FlexLayoutModule
];

// Triggered Modules
import { TriggeredAnimationsModule } from '../../animations/animations.module';
const triggeredModules = [ TriggeredAnimationsModule ];

// Expander Components
import { ExpanderComponent } from './expander.component';
const sharedComponents = [ ExpanderComponent ];



@NgModule({
  declarations: [
    ...sharedComponents
  ],
  exports: [ ...sharedComponents, ...angularModules ],
  imports: [
    ...angularModules,
    ...triggeredModules
  ]
})
export class TriggeredExpanderModule { }
