import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';

export const FeaturePaths = {
  documentId: 'features',
  collectionPath: () => 'settings',
  documentPath: () => 'settings/features'
};

/**
 * Representation of the Subscription object
 */
export interface IFeatureFlag {
  id: string;
  display: string;
  isActive: boolean;
}

export interface FeatureFlag extends IFeatureFlag { }
export class FeatureFlag extends Model<FeatureFlag> {
  getDefaults(): Partial<FeatureFlag> {
    return { isActive: true}
  }
}

export interface IFeatureFlags extends IRecord {
  id: string;
  flags: IFeatureFlag[];
}


export interface FeatureFlags extends IFeatureFlags { }
export class FeatureFlags extends Record<FeatureFlags> {
  id: string = 'features';
  flags: FeatureFlag[];

  getDefaults(): Partial<FeatureFlags> {
    return { id: 'features', flags: [] };
  }

  patchValue(value: Partial<FeatureFlags>) {
    super.patchValue(value);
    this.flags = (this.flags || []).map(flag => new FeatureFlag(flag));
  }
}
