import { Injectable, Optional, Inject } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { TriggeredIconsServicesModule } from './services.module';

@Injectable({providedIn: TriggeredIconsServicesModule})
export class UniversalHostProvider {
  public readonly host: string;

  constructor(@Optional() @Inject(REQUEST) protected request: Request) {
    let hostname = '';
    if(this.request) {
      if(this.request?.hostname === 'localhost' && this.request?.headers?.referer) {
        const url = new URL(this.request.headers.referer);
        hostname = url.origin;
        console.log('here 1');
      } else if (this.request.headers) {
        const xHost = this.request.headers['x-forwarded-host'];
        if(xHost) {
          const xProtocol = this.request.headers['x-forwarded-proto'] || (this.request?.hostname === 'localhost' ? 'http' : 'https');
          hostname = `${xProtocol}://${xHost}`;
        }
      }
      hostname = hostname || this.request.hostname;
      hostname = hostname.endsWith('/') ? hostname : `${hostname}/`;
    }
    console.log('Hostname is', hostname);
    this.host = hostname;
  }
}
