import { Component, OnInit } from '@angular/core';
import { MetaTags } from '@triggered/core';

@Component({
  selector: 'triggered-our-mission',
  templateUrl: './our-mission.view.html',
  styleUrls: ['./our-mission.view.scss']
})
export class OurMissionView implements OnInit {
  static readonly metaTags: MetaTags = new MetaTags({
    title: 'Triggered App - Our Mission and Vision',
    description: `Our mission is to provide instant accountability and support to those struggling with addictions, unwanted behaviors and negative thought patterns.`,
    ogImage: 'https://firebasestorage.googleapis.com/v0/b/solid-apps-triggered.appspot.com/o/solid-apps-triggered%2Fimages%2FSEO%2FTriggered-Mission-Vision.jpg?alt=media&token=b14eb0de-52af-4fd6-a744-4e9d55e51aa1'
  });


  constructor() { }

  ngOnInit(): void {
  }

}
