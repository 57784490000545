import { FormControl, Validators } from '@angular/forms';
import { Record } from '@triggered/common';
import { Form } from '@triggered/core';

export class NewsletterSubscriptionModel extends Record<NewsletterSubscriptionModel> {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  newsletterIds: string[];

  // Optional Address Fields
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  country?: string;

  constructor(model?: Partial<NewsletterSubscriptionModel>){
    super(model);
  }
}

export class NewsletterSubscriptionForm extends Form<NewsletterSubscriptionModel> {
  constructor(model?: Partial<NewsletterSubscriptionModel>,) {
    super({
      id: new FormControl(model?.id),
      firstName: new FormControl(model?.firstName, []),
      lastName: new FormControl(model?.lastName, []),
      newsletterIds: new FormControl(model?.newsletterIds, []),
      emailAddress: new FormControl(model?.emailAddress, [Validators.required, Validators.email])
    })
  }
}
