export interface IPaymentRequest {
  /** The id associated with this payment method */
  id: string;

  /** The trial ID associated with this payment */
  trialId?: string;

  /** The promo associated with this payment */
  promo?: string;

  /** The coupon associated with this payment */
  coupon?: string;
}
