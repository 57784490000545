import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, forwardRef, EventEmitter, Output, Input, ContentChild, TemplateRef, SimpleChanges, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { FormField } from '../form-field';
import { DomSanitizer } from '@angular/platform-browser';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'triggered-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true,
    }
  ]
})
export class ChipsComponent extends FormField implements OnChanges {
  @ContentChild('option') optionTemplate: TemplateRef<any>;

  @ContentChild('tag') tagTemplate: TemplateRef<any>;

  @ContentChild('prefix') prefixTemplate: TemplateRef<any>;

  @Input() label: string;

  @Input() placeholder = '';

  @Input() hint: string;

  @Input() emptyPlaceholder = '';

  @Input() prefix: string;

  @Input() allowCustomInput = false;

  @Input() displayPath: string;

  @Input() valuePath: string;

  @Input() options: any[];

  @Input() inputFormControl: FormControl = new FormControl();

  @Output() readonly blur = new EventEmitter<void>();

  @Output() readonly textChange = new EventEmitter<string>();

  @Output() readonly selectedChanged = new EventEmitter<any[]>();

  @Input() selectedValues: any[] = [];

  @Input() max: number;

  seperatorKeyCodes: number[];

  @ViewChild('chipList') chipList;
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedValues) {
      this.selectedValues = this.selectedValues || [];
    }

    if(changes.allowCustomInput) {
      this.seperatorKeyCodes = changes.allowCustomInput ? [ENTER, COMMA] : []
    }
  }

  ngAfterViewInit() {
    if(this.formControl) {
      this.formControl.valueChanges.subscribe(() => this.chipList.errorState = !this.formControl.valid, (error) => { throw new Error(error); });
      this.formControl.statusChanges.subscribe(() => this.chipList.errorState = !this.formControl.valid, (error) => { throw new Error(error); });
    }
  }

  onInput(event: any) {
    if(event?.target?.value !== undefined)
      this.inputFormControl.setValidators(event?.target?.value);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }

  canAdd() {
    return !this.max || (this.selectedValues || []).length < this.max;
  }

  onChanged(event: any) {
    this.selectedChanged.emit(event);
  }

  public onItemAdded(event: {display: any, value: any}) {
    this.updateSelection();
  }

  public onItemRemoved(option) {
    const index = this.selectedValues.indexOf(option);

    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      this.updateSelection();
    }
  }

  public onAdd(event: MatChipInputEvent) {
    if(!this.allowCustomInput) { return; }
    this.selectedValues.push(event.value);
    this.updateSelection();
    if(event.input) {
      event.input.value = '';
    }
    this.inputFormControl?.setValue(null);
  }

  public onSelected(event: MatAutocompleteSelectedEvent){
    const option = event.option.value;
    this.selectedValues.push(this.valuePath ? option[this.valuePath] : option);
    this.clearInput();
    this.updateSelection();
  }

  public onTextChange(text) {
    this.textChange.emit(text);
  }

  private updateSelection() {
    this.formControl.setValue(this.selectedValues);
    this.selectedChanged.emit(this.selectedValues);
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();


  }

  private clearInput() {
    this.chipInput.nativeElement.value = '';
    this.inputFormControl.setValue('');
  }
}
