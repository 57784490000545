import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TriggeredRoutes } from '@triggered/core';
import { LoginView } from './login/login.view';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: TriggeredRoutes.app.account.login.partial },
  { path: TriggeredRoutes.app.account.login.partial, component: LoginView }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationViewsRoutingModule { }
