import { CollectionPath } from '../../data/paths'

export const STOREPATHS = {
  users: new CollectionPath('users', {
    settings: new CollectionPath('settings'),
    orders: new CollectionPath('modules/store/orders'),
    invoices: new CollectionPath('modules/store/invoices'),
    gifts: new CollectionPath('modules/store/gifts')
  }),
  subscriptions: new CollectionPath('subscriptions', {
    groups: new CollectionPath('groups', {
      members: new CollectionPath('members')
    }),
    users: new CollectionPath('users'),
    administrators: new CollectionPath('administrators'),
    settings: new CollectionPath<{ 'plan-snapshot': any, 'pricing': any, 'subscription': any, 'group-settings': any }>('settings'),
    transactions: new CollectionPath('transactions'),
  }),
  invites: new CollectionPath('modules/store/invites'),

  products: new CollectionPath('modules/store/products'),
  gifts: new CollectionPath('modules/store/gifts'),

  // TODO: Update to modules/store/plans
  plans: new CollectionPath('plans')
}
