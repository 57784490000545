
<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em">

<mat-password-strength #passwordComponent
  class="w-100"
  [password]="password"
  [min]="min"
  [enableLengthRule]="true"
  [enableDigitRule]="false"
  [enableUpperCaseLetterRule]="true"
  [enableLowerCaseLetterRule]="false"
  [enableSpecialCharRule]="false">
</mat-password-strength>

  <div fxLayout="column">
    <div class="info-row" @items *ngIf="passwordComponent.enableLowerCaseLetterRule">
      <div *ngIf="passwordComponent.containAtLeastOneLowerCaseLetter; then done else error" @flipY>
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">done</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">error</mat-icon>
      </ng-template>
      <span>{{lowerCaseCriteriaMsg}}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableUpperCaseLetterRule">
      <div *ngIf="passwordComponent.containAtLeastOneUpperCaseLetter; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">done</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">error</mat-icon>
      </ng-template>
      <span>{{upperCaseCriteriaMsg}}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableDigitRule">
      <div *ngIf="passwordComponent.containAtLeastOneDigit; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">done</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">error</mat-icon>
      </ng-template>
      <span>{{digitsCriteriaMsg}}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableSpecialCharRule">
      <div *ngIf="passwordComponent.containAtLeastOneSpecialChar; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">done</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">error</mat-icon>
      </ng-template>
      <span>{{specialCharsCriteriaMsg}}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableLengthRule">
      <div *ngIf="passwordComponent.containAtLeastMinChars; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">done</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">error</mat-icon>
      </ng-template>
      <span>{{minCharsCriteriaMsg}}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.customValidator">
      <div *ngIf="passwordComponent.containAtCustomChars; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">done</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">error</mat-icon>
      </ng-template>
      <span>{{customCharsCriteriaMsg}}</span>
    </div>

    <div *ngIf="enableScoreInfo" class="info-row" @items>
      <div *ngIf="passwordComponent.strength === 100; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">check</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">error</mat-icon>
      </ng-template>
      <span>Password's strength = {{passwordComponent.strength}} %100</span>
    </div>
  </div>
</div>
