import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogOptions, DialogService } from '../../services/dialog.service';
import { BaseDialog } from '../base.dialog';

export class PromptData {
  constructor(readonly title: string, readonly message: string, readonly primaryOption: string, readonly otherOptions?: string[]) {
  }
}

@Component({
  selector: 'triggered-prompt',
  templateUrl: './prompt.dialog.html',
  styleUrls: ['./prompt.dialog.scss']
})
export class PromptDialog extends BaseDialog<PromptData, string> implements OnInit {

  static open(service: DialogService, data: PromptData, options?: Partial<DialogOptions>): Promise<string> {
    const dialogOptions = new DialogOptions('600px', undefined, false);
    Object.assign(dialogOptions, options);
    return service.openDialog<PromptDialog, PromptData, string>(PromptDialog, data, dialogOptions);
  }

  constructor(readonly dialogRef: MatDialogRef<PromptDialog, string>,
    @Inject(MAT_DIALOG_DATA) public data: PromptData) {
    super();
   }

  ngOnInit(): void {
  }

  async onSelect(option?: string) {
    this.dialogRef.close(option);
  }
}
