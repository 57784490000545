import { Injectable } from '@angular/core';
import { FirestoreDatabase } from '@triggered/firestore';
import { FirebaseHelper } from 'libs/firestore/src/lib/clients/firebase.helper';
import { ContactRequest, ContactRequestForm } from '../core';
import { ContactServicesModule } from './services.module';

@Injectable({
  providedIn: ContactServicesModule
})
export class ContactRequestService {
  protected get collectionPath(): string { return `modules/notifications/contact-requests`; };

  constructor(private readonly database: FirestoreDatabase) { }

  async sendContactRequest(contactRequest: ContactRequestForm) {
    const question = this.database.getDocument('modules/notifications/contact-requests', FirebaseHelper.generateDateId(), ContactRequest);
    return await question.update(contactRequest);
  }
}
