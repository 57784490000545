// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
const angularModules = [ CommonModule, FlexLayoutModule, MatButtonModule, MatProgressSpinnerModule]


import { FormButtonsComponent } from './form-buttons.component';
const exportComponents = [ FormButtonsComponent ];

@NgModule({
  declarations: [exportComponents],
  exports: [ exportComponents ],
  imports: [
    ...angularModules
  ]
})
export class TriggeredFormButtonsModule { }
