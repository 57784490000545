import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TriggeredDialogServiceModule } from './dialog.module';

export class DialogOptions {
  constructor(readonly width?: string, readonly height?: string, readonly disableClose?: boolean, readonly autoFocus?: boolean, readonly panelClass?: string){}
}

@Injectable({
  providedIn: TriggeredDialogServiceModule
})
export class DialogService {

  constructor(private readonly dialog: MatDialog) {
  }

  // TODO: Get type safety to work
  async openDialog<TDialog, TData = any, TResponse = any>(dialog: ComponentType<TDialog> | TemplateRef<any>, data: TData, options?: DialogOptions): Promise<TResponse> {
    const reference = this.open(dialog, data, options);
    return await reference.afterClosed().pipe(
      tap(res => console.log(`DialogService: Dialog closing`, res))
    ).toPromise();
  }

  async openComponent<TResponse = any, TDialog = any, TData = any>(dialog: ComponentType<TDialog>, data?: TData, options?: DialogOptions) {
    return await this.openDialog<TDialog, TData, TResponse>(dialog, data, options);
  }

  openTemplate(template: TemplateRef<any>, data?: any, options?: DialogOptions) {
    const reference = this.open(template, data, options);
    return reference;
  }

  private open<TDialog, TData = any, TResponse = any>(dialog: ComponentType<TDialog> | TemplateRef<any>, data: TData, options?: DialogOptions): MatDialogRef<TDialog, TResponse> {
    const reference = this.dialog.open<TDialog, TData, TResponse>(dialog, {
      data,
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: options?.width,
      height: options?.height,
      disableClose: options?.disableClose || false,
      autoFocus: options?.autoFocus || false,
      panelClass: options?.panelClass
    });
    return reference;
  }
}
