import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable()
export class NavigationService {
  readonly routeData$: Observable<Data>;

  constructor(private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute) {
      this.routeData$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap(() => {
          let route = this.activatedRoute.firstChild;
          let child = route;

          while (child) {
            if (child.firstChild) {
                child = child.firstChild;
                route = child;
            } else {
                child = null;
            }
          }

          return route.data;
        })
      );
  }
}
