import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';

export type ProductType = 'Gift';
export type PaymentProcessor = 'stripe' | 'apple-iap' | 'android-iap';

export interface IProductVariation {
  /** The price charged per unit for this variation */
  unitPrice?: number;

  /** The ProductId associated with this payment processor */
  productId: string;
}

export interface ProductVariation extends IProductVariation { }
export class ProductVariation extends Model<IProductVariation> { }

/** The different pricing based upon the corresponding payment processor */
export interface IProductPrice {
  /** The payment processor who is managing the subcriptions */
  paymentProcessor: PaymentProcessor;

  /** The reference to the parent product. Currently only applicable to stripe */
  productId?: string;

  /** The type of variants for this product */
  variations: IProductVariation[];
}

export interface ProductPrice extends IProductPrice {
  variations: ProductVariation[];
}

export class ProductPrice extends Model<ProductPrice> {
  patchValue(model?: Partial<ProductPrice>): void {
    super.patchValue(model);
    this.variations = (model?.variations || []).map(variation => new ProductVariation(variation));
  }
}



/**
 * Representation of a plan
 */
export interface IProduct extends IRecord {
  /** The ID of the product */
  id: string

  /** The display name of the product */
  name: string;

  /** This product description */
  description?: string;

  /** The type of product this is */
  type: ProductType;

  /** Whether the product is still active */
  isActive?: boolean;

  /** The different pricing  */
  pricing: IProductPrice[];
}

export interface Product extends IProduct {
  pricing: ProductPrice[]
};

export class Product extends Record<Product> {
  patchValue(value: Partial<Product>) {
    super.patchValue(value);
    this.pricing = (value?.pricing || []).map(pricing => new ProductPrice(pricing));
  }

  getDefaults(): Partial<Product> {
    return {pricing: []};
  }

  getPricing(processor: PaymentProcessor) {
    return this.getPricings(processor)[0];
  }

  getPricings(processor: PaymentProcessor) {
    return (this.pricing || []).filter(price => price.paymentProcessor === processor);
  }
}
