// Required Firebase Modules for now: https://github.com/angular/angularfire/issues/2168#issuecomment-527950889
import 'firebase/auth';
import 'firebase/firestore';

// Angular Modules
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule, FirebaseOptions, FIREBASE_OPTIONS, FIREBASE_APP_NAME } from '@angular/fire';
const angularModules = [ CommonModule, FormsModule, ReactiveFormsModule, AngularFireModule ];

// SOLID Modules
import { AngularFirestore } from '@angular/fire/firestore';
import { UIDProvider } from '@triggered/core';
import { FirestoreUIDProvider } from './providers/firestore-uid.provider';
const solidModules = [ ];

@NgModule({
  imports: [
    ...angularModules,
    ...solidModules
  ],
  providers: [],
  exports: [AngularFireModule]
})
export class FirestoreModule {
  static forRoot(options: FirebaseOptions): ModuleWithProviders<FirestoreModule> {
    return {
      ngModule: FirestoreModule,
      providers: [
        AngularFirestore,
        { provide: UIDProvider, useClass: FirestoreUIDProvider },
        { provide: FIREBASE_OPTIONS, useValue: options },
        { provide: FIREBASE_APP_NAME, useValue: null },
      ]
    };
  }
}
