import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';
import { IMentee, Mentee } from './mentee';
import { IPartner, Partner } from './mentor';

export const AlertSettingsPaths = {
  documentId: 'alert-settings',
  collection: (userId: string) => `users/${userId}/settings`,
  document: (userId: string) => `users/${userId}/settings/alert-settings`,
};

export interface IAlertPreferences {
  isTextEnabled: boolean;
  isPushEnabled: boolean;
  isEmailEnabled: boolean;

  // Readonly fields, set by the backend
  pushTokens: string[];
  phoneNumber: string;
  emailAddress: string;
}

export interface AlertPreferences extends IAlertPreferences {}
export class AlertPreferences extends Model<AlertPreferences> {}

export interface IUserAlertSettings extends IRecord {
  /** People who are mentoring me */
  mentors: IPartner[];

  /** People who are I am mentoring */
  mentees: IMentee[];

  /** Alert notification settings */
  defaultAlerts: IAlertPreferences;
}

export interface UserAlertSettings extends IUserAlertSettings {}
export class UserAlertSettings extends Record<UserAlertSettings> {
  readonly id = 'alert-settings';

  /** People who are mentoring me */
  mentors: Partner[] = [];

  /** People who are I am mentoring */
  mentees: Mentee[] = [];

  /** Alert notification settings */
  defaultAlerts: AlertPreferences;

  constructor(model?: Partial<UserAlertSettings>) {
    super(model);
    this.patchValue(model);
  }

  getDefaults(): Partial<UserAlertSettings> {
    return { id: 'alert-settings', mentors: [], mentees: [] };
  }

  patchValue(model?: Partial<UserAlertSettings>) {
    if(!model) { return; }

    super.patchValue(model);
    this.mentors = (model?.mentors || []).map(mentor => new Partner(mentor));
    this.mentees = (model?.mentees || []).map(mentee => new Mentee(mentee));
  }
}
