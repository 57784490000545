export interface Step {
  icon: string,
  header: string,
  summary: string,
  description: string
}

export class Steps {
  static GetTheApp: Step = Object.freeze<Step>({
    icon: 'assets/images/Icon_App.png',
    header: 'Get the App',
    summary: `Download the Triggered App to your smartphone and other internet devices.`,
    description: 'Download the Triggered app on all of your devices to make it simple for you to reach out in your time of need. Triggered has a variety of different applications for different platforms as well as extensions for other tools to make sure you have what you need to Overcome with Triggered!',
  });

  static BuildYourTeam: Step = Object.freeze<Step>({
    icon: 'assets/images/Icon_Friends.png',
    header: 'Build your Team',
    summary: `Build your team of accountability and support so they can be there for you when you need it the most.`,
    description: 'Triggered is designed to make it simple to reach out for support and accountability. Choose the right partners who will support and encourage you in your time of need. We need each other in order to break free. After you get the app, build your team that will help you succeed!',
  });

  static ClickTheButton: Step = Object.freeze<Step>({
    icon: 'assets/images/Icon_Push.png',
    header: 'Click the Button',
    summary: `Simply press the Triggered button to send an instant alert to those who are there for you.`,
    description: 'When you are feeling tempted to resort to unhealty coping behaviors or thought patterns, reach out immediately by clicking the Triggered button. Pressing the Triggered button sends out instant text alerts to your group via SMS, email and push notifications. We can help ensure you get the accountability you need to find victory. ',
  });

  static ReceiveSupport: Step = Object.freeze<Step>({
    icon: 'assets/images/Icon_Call.png',
    header: 'Receive Support',
    summary: `Be ready to answer a call from one of your friends who is there to help you overcome.`,
    description: 'Expecting a call will give you the strength to fight, knowing that your team has been notified and will be reaching out to encourage and support you. Your team will be reaching out as soon as possible. Let’s support each other. We are in this together!',
  });

  static AllSteps: Readonly<Step[]> = Object.freeze<Step[]>([
    Steps.GetTheApp, Steps.BuildYourTeam, Steps.ClickTheButton, Steps.ReceiveSupport
  ]);
}
