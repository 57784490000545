import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';

/**
 * Representation of the Subscription object
 */
export interface IUserDevice {
  pushToken: string;
  dateLastLogin: Date;
  appVersion: string;
  appBuild: string;
  appId: string;
  platform: string;
  osVersion: string;
  operatingSystem: string;
  model: string;
}

export interface UserDevice extends IUserDevice { }
export class UserDevice extends Model<UserDevice> {}

export interface IUserDevices extends IRecord {
  id: string;
  devices: IUserDevice[];
}


export interface UserDevices extends IUserDevices { }
export class UserDevices extends Record<UserDevices> {
  static documentId = 'devices';
  static collection = (userId: string) => `users/${userId}/settings`;
  static documentPath = (userId: string) => `${UserDevices.collection(userId)}/${UserDevices.documentId}`;

  id: string = 'devices';
  devices: UserDevice[];

  getDefaults(): Partial<UserDevices> {
    return { id: 'devices', devices: [] };
  }

  patchValue(value: Partial<UserDevices>) {
    super.patchValue(value);
    this.devices = (this.devices || []).map(device => new UserDevice(device));
  }
}
