import { Form, SOLIDFormArray } from '@triggered/core';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { PaymentFrequencyForm } from './frequency.model';
import { Frequency, PaymentFrequency, Plan, PlanFeature, Pricing } from '@triggered/common';

export class PlanFeatureForm extends Form<PlanFeature> {

  constructor(value?: PlanFeature) {
    const init = value || new PlanFeature();
    super({
      title: new FormControl(init.title, [Validators.required]),
      description: new FormControl(init.description, []),
      isActive: new FormControl(init.isActive, []),
    });
    this.originalValue = init;
  }
}

export class PricingForm extends Form<Pricing> {

  displayPrice$: Observable<number>;
  displayFrequency$: Observable<string>;

  availableFrequencies$: Observable<Frequency[]>;
  public get frequencies(): SOLIDFormArray<PaymentFrequency>  {
    return this.getAbstractControl('frequencies') as SOLIDFormArray<PaymentFrequency>;
  }

  constructor(value?: Partial<Pricing>) {
    const init = new Pricing(value);
    super({
      paymentProcessor: new FormControl(init.paymentProcessor, [Validators.required]),
      frequencies: new SOLIDFormArray<PaymentFrequency, PaymentFrequencyForm>(init.frequencies, (frequency) => new PaymentFrequencyForm(frequency)),
      productId: new FormControl(init.productId, []),
    });

    this.originalValue = init;
    this.availableFrequencies$ = this.frequencies.valueChanges.pipe(
      startWith(init.frequencies),
      map((frequencies: PaymentFrequency[]) => {
        const allFrequencies: Frequency[] = ['Monthly', 'Yearly'];
        const remainingFrequencies = allFrequencies
          .filter(frequency => frequencies.every(existing => existing.frequency !== frequency))
        return remainingFrequencies;
      })
    );
  }
}


export class PlanForm extends Form<Plan> {
  public get features(): SOLIDFormArray<PlanFeature>  {
    return this.getFormArray<PlanFeature>('features');
  }

  constructor(value?: Partial<Plan>) {
    const init = new Plan(value);
    super({
      id: new FormControl(init.id, []),
      isActive: new FormControl(init.isActive, []),
      name: new FormControl(init.name, [Validators.required]),
      type: new FormControl(init.type, []),
      features: new SOLIDFormArray<PlanFeature, PlanFeatureForm>(init.features, (feature) => new PlanFeatureForm(feature)),
      pricing: new SOLIDFormArray<Pricing, PricingForm>(init.pricing, (pricing) => new PricingForm(pricing))
    });

  }
}
