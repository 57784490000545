import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { MenuItem } from '../models/menu-item';

export class MenuProvider {
  menuName: string;
  menuItems$: Observable<MenuItem[]>;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  readonly menus$: {[menuKey: string]: Observable<MenuItem[]> };

  constructor(@Optional() menuProvider: MenuProvider) {
    const menuProviders: MenuProvider[] = menuProvider instanceof MenuProvider
      ? [menuProvider]
      : (menuProvider || []);

    console.log(`${menuProviders.length} providers`)
    this.menus$ = menuProviders.reduce((kvp, menuProvider) => {
      kvp[menuProvider.menuName] = menuProvider.menuItems$.pipe(shareReplay(1));
      return kvp;
    }, {});
  }


}
