import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { UserDevicesForm } from '../models/user-devices.model';
import { AuthenticationService } from './authentication.service';
import { UserSettingsService } from './user-settings.service';
import { ErrorReporter } from '@triggered/core';
import { UserDevice, UserDevices } from '@triggered/common';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  readonly devices$: Observable<UserDevices>;

  constructor(private authService: AuthenticationService, private readonly settingsService: UserSettingsService) {
    this.devices$ = this.authService.user$.pipe(
      switchMap((user) => {
        if(user) {
          return this.settingsService.getSettings$('devices').pipe(
            map(settings => new UserDevices(settings))
          );
        }
        return of(null);
      }),
      shareReplay(1)
    );
  }


  /**
   * TODO: This should be an event listener
   */
  async onFCMTokenRegistered(token: string) {
    const deviceInfo = await this.getDeviceInfo();
    const appInfo = await this.getAppInfo();
    const deviceSettings = await this.devices$.pipe(take(1)).toPromise();


    // Currently looking up by FCM Token
    let currentDevice = deviceSettings.devices.find(device => device.pushToken === token);
    if(!currentDevice) {
    currentDevice = new UserDevice();
      deviceSettings.devices.push(currentDevice);
      console.log('DeviceService: Adding a new device');
    }

    if (currentDevice?.pushToken !== token ||
      currentDevice.appId !== appInfo.id ||
      currentDevice.appBuild !== appInfo.build ||
      currentDevice.appVersion !== appInfo.version ||
      currentDevice.model !== deviceInfo.model) {

      // Update the appId and appVersion.
      currentDevice.pushToken = token;
      currentDevice.appId = appInfo.id; // i.e. - com.solid.triggered
      currentDevice.appVersion = appInfo.version; // i.e. - 1.0.0
      currentDevice.appBuild = appInfo.build; // i.e. - 1015
      currentDevice.platform = deviceInfo.platform;
      currentDevice.osVersion = deviceInfo.osVersion;
      currentDevice.model = deviceInfo.model;
      currentDevice.operatingSystem = deviceInfo.operatingSystem;

      console.log('DeviceService: Updating settings' + JSON.stringify(deviceSettings));

      currentDevice.clean();
      const form = new UserDevicesForm(deviceSettings);
      await this.settingsService.updateSettings<any>(form)
    }
  }


  async getDeviceInfo() {
    try {
      return await Device.getInfo();
    } catch(error) {
      ErrorReporter.report(error, { message: `DeviceService: Error retrieving device info`});
      return;
    }
  }


  async getAppInfo() {
    try {
      return await App.getInfo();
    } catch(error) {
      ErrorReporter.report(error, { message: `DeviceService: Error retrieving app info`});
      return;
    }
  }
}
