import { Action, CollectionReference, DocumentChangeAction, DocumentSnapshot, Query, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { DocumentOptions, IRecord, QueryOptions } from '@triggered/common';
import firebase from 'firebase/app';
import isEqual from 'lodash/isequal';



export class FirebaseHelper {
  static generateDateId() {
    const threeDigitRand = Math.floor(100 + Math.random() * 900);
    const date = new Date();
    const yyyy = date.getUTCFullYear();
    const MM = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const dd = ('0' + date.getUTCDate()).slice(-2);
    const hh = ('0' + date.getUTCHours()).slice(-2);
    const mm = ('0' + date.getUTCMinutes()).slice(-2);
    const ss = ('0' + date.getSeconds()).slice(-2);
    const SSS = ('0' + date.getMilliseconds()).slice(-3);
    // return `${date.getUTCFullYear()}${('0' + date.getUTCMonth()).slice(-2)}``
    // const dateId = formatDate(new Date().toISOString(), 'yyyyMMdd?HHmmssSSS', 'en-us');
    return `${yyyy}${MM}${dd}${hh}${mm}${ss}${SSS}${threeDigitRand}`; // 20 digit identifier
  }

  static getPathParts(fullPath: string) {
    const parts = fullPath.split('/');

    const documentId = parts.pop() ?? '';
    const collectionPath = parts.join('/');

    return { collectionPath, documentId };
  }

  static buildQuery<TModel>(ref: CollectionReference, options?: QueryOptions<TModel>): Query {
    let query: Query = ref;
    if (!options) { return query; }

    (options.filters || [])
      .forEach(filter => {
        // If UID, get the documentId field https://stackoverflow.com/a/58780369
        const property = filter.propertyName === 'uid' ? firebase.firestore.FieldPath.documentId() : filter.propertyName.toString();
        query = query.where(property, filter.operator, filter.value);
      });

    (options.orderBy || [])
      .forEach(orderBy => {
        // If UID, get the documentId field https://stackoverflow.com/a/58780369
        const property = orderBy.propertyName.toString();
        query = query.orderBy(property, orderBy.direction === 'asc' ? 'asc' : 'desc');
      });

    if(options.startAfterSnapshot) {
      query = query.startAfter(options.startAfterSnapshot);
    }

    if(options.limit) {
      query = query.limit(options.limit);
    }
    return query;
  }

  static distinctRecord<TRecord>(prev: Action<DocumentSnapshot<TRecord>>, curr: Action<DocumentSnapshot<TRecord>>) {
    const prevRecord = prev?.payload?.data();
    const currRecord = curr?.payload?.data();
    return isEqual(prevRecord, currRecord);
  }

  static distinctRecords<TRecord>(prev: DocumentChangeAction<TRecord>[], curr: DocumentChangeAction<TRecord>[]) {
    const prevRecords = prev?.map(record => record?.payload?.doc?.data());
    const currRecords = curr?.map(record => record?.payload?.doc?.data());
    return isEqual(prevRecords, currRecords);
  }

  static docToModel<TRecord extends IRecord>(
    doc: DocumentSnapshot<TRecord> | QueryDocumentSnapshot<TRecord>,
    options?: DocumentOptions<TRecord>) {
    const model: any = doc?.data();
    if (model == null) { return null; }

    model.id = doc.id;
    return options?.recordFn ? new options.recordFn(model) : model;
  }
}
