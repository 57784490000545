import { Observable } from 'rxjs';

export class MenuItem {
  icon?: string;
  display: string;
  routerLink?: string | any[];
  routerLinkActive?: string | string[];
  exactLinkActive?: boolean;
  expanded?: boolean;
  children?: MenuItem[];
  color?: null | 'primary' | 'accent';

  isVisible$?: Observable<boolean>;

  /** Additional properties */
  order?: number;
  [key: string]: any;

  constructor(menuItem?: MenuItem) {
    Object.assign(this, menuItem);
  }
}
