import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationMenuItem } from './notification-menu';

@Component({
  selector: 'triggered-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent {
  unreadOnly = true;

  @Input() hasMore: boolean;
  @Input() isLoading: boolean;
  @Input() isClearingAll: boolean;
  @Input() notifications: NotificationMenuItem[];

  @Output() readonly read = new EventEmitter<NotificationMenuItem>();
  @Output() readonly markAllAsRead = new EventEmitter();
  @Output() readonly toggleRead = new EventEmitter();
  @Output() readonly loadMore = new EventEmitter();

  readonly trackById = (index: number, item: NotificationMenuItem) => item.id;

  constructor() { }

  onClick(notification: NotificationMenuItem) {
    this.read.emit(notification);
  }

  onMarkAllAsRead() {
    this.markAllAsRead.emit();
  }

  onToggleRead() {
    this.unreadOnly = !this.unreadOnly;
    this.toggleRead.emit(this.unreadOnly);
  }

  onLoadMore() {
    this.loadMore.emit();
  }
}
