import { Directive, SimpleChanges, ElementRef } from '@angular/core';

@Directive({
  selector: '[solidScrollable]'
})
export class ScrollableDirective {

  constructor(private templateRef: ElementRef<any>,  ) {
    (templateRef as any).scrollTo = this.scrollTo();
    templateRef.nativeElement.scrollTo = this.scrollTo();
  }


  scrollTo() {
    if(this.templateRef && this.templateRef.nativeElement && this.templateRef.nativeElement.scrollIntoView != null) {
      this.templateRef.nativeElement.scrollIntoView({behavior:'smooth'});
    }
  }
}
