<mat-checkbox [formControl]="formControl" [color]="color || 'primary'" [disabled]="readonly">
  <ng-content></ng-content>
  {{label}}
</mat-checkbox>
<div *ngIf="hint">
  <span class="mat-caption mat-hint">{{hint}}</span>
</div>
<div class="text-center">
  <mat-error *ngIf="errors" [innerHTML]="errors" class="mat-caption" style="display: block;"></mat-error>
</div>
