import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { FormField } from '../form-field';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'triggered-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    }
  ]
})
export class TextareaComponent extends FormField {
  @Input()
  public hint: string;

  @Input() public maxLength: number;

  @Input()
  public minRows = 3;

  @Input()
  public maxRows = 5;

  @Input()
  public label: string;

  @Input()
  public placeholder = '';

  @Input()
  public readonly: boolean;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.executeOnTouched();
  }
}
