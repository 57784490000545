<mat-nav-list class="padding-0 height-auto h-100 overlay-container">
  <div *ngIf="isClearingAll" class="overlay center disabled" fxLayout="column">
    <mat-spinner [diameter]="24"></mat-spinner>
    <span class="text-warn">Clearing...</span>
  </div>

  <mat-list-item id="unread-slider" [disableRipple]="true">
    <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2em">

      <!-- No notifications -->
      <ng-container *ngIf="!notifications?.length; else clearAll">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <mat-icon class="material-icons-outlined">checklist</mat-icon>
          <span>You're all caught up</span>
        </div>
      </ng-container>

      <!-- Clear all button -->
      <ng-template #clearAll>
        <button mat-button  (click)="onMarkAllAsRead()" [disabled]="isClearingAll" tabindex="-1" class="margin-left--1">
          <div class="w-100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <mat-icon class="material-icons-outlined">done_all</mat-icon>
            <span>Clear all</span>
          </div>
        </button>
      </ng-template>


      <mat-slide-toggle color="primary" [checked]="!unreadOnly" (change)="onToggleRead()">All</mat-slide-toggle>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>


    <!-- Display all notifications-->
    <ng-container *ngFor="let notification of notifications;trackBy:trackById">
      <mat-list-item (click)="onClick(notification)" [disabled]="notification.isClearing" class="overlay-container">
        <div *ngIf="notification.isClearing" class="overlay center">
          <mat-spinner [diameter]="24"></mat-spinner>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
          <mat-icon class="material-icons-outlined" [color]="notification.isNew ? 'primary' : ''">{{notification.icon}}</mat-icon>
          <div fxLayout="column">
            <span class="mat-body-2">{{notification.title}}</span>
            <div class="mat-body-1 text-wrap" [innerHTML]="notification.subtitle"></div>
            <span class="mat-caption">{{notification.date | date: 'MMM d, y, h:mm a'}}</span>
          </div>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- Clear All button -->
    <ng-container *ngIf="hasMore && !isLoading">
      <mat-list-item id="load-more-button" (click)="onLoadMore()">
        <div class="w-100" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <mat-icon class="material-icons-outlined">expand_more</mat-icon>
          <span>Load More</span>
        </div>
      </mat-list-item>
    </ng-container>

    <!-- Loading Spinner-->
    <ng-container *ngIf="isLoading">
      <mat-list-item id="loading">
        <div class="w-100" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <mat-spinner [diameter]="24"></mat-spinner>
          <span class="text-warn">Loading...</span>
        </div>
      </mat-list-item>
    </ng-container>

  </mat-nav-list>
