

<triggered-page>
  <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="1em">
    <div fxLayout="row" fxLayoutAlign="center">
      <div fxFlex="0 1 1000px" class="mat-card mat-elevation-z1 padding-2 overlay-container" fxLayout="column" fxLayoutGap="2em">
        <div>
          <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let link of links" [routerLink]="[link.route]" routerLinkActive #rla="routerLinkActive"[routerLinkActiveOptions]="{exact:true}" [active]="rla.isActive">
              {{link.text}}
            </a>
          </nav>

          <div class="margin-y-1">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</triggered-page>


