import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { merge, Observable, of } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { Step } from './step.model';

@Injectable()
export abstract class StepService {
  private _activeStep: Step;
  readonly steps: Step[];
  readonly activeIndex$: Observable<number>;
  get activeStep(): Step { return this._activeStep; }

  constructor(private readonly router: Router) {
    this.activeIndex$ = this.getActiveIndex$().pipe(
      tap(activeIndex => this._activeStep = this.steps[activeIndex]),
      shareReplay(1)
    );
  }

  public async onPrevious() {
    const currentIndex = this.steps.indexOf(this._activeStep);
    const previousStep = currentIndex > 0 ? this.steps[currentIndex - 1] : null;
    this.navigateToStep(previousStep);
  }

  public async hasNext() {
    const currentIndex = this.steps.indexOf(this._activeStep);
    const nextStep = (currentIndex < this.steps.length - 1) ? this.steps[currentIndex + 1] : null;
    return nextStep != null;
  }


  public async onNext(queryParams?: any) {
    const currentIndex = this.steps.indexOf(this._activeStep);
    const nextStep = (currentIndex < this.steps.length - 1) ? this.steps[currentIndex + 1] : null;
    this.navigateToStep(nextStep, queryParams);
  }

  public async navigateTo(targetIndex: number) {
    const nextStep = (targetIndex <= this.steps.length - 1) ? this.steps[targetIndex] : null;
    this.navigateToStep(nextStep);
  }

  public async navigateToStep(step: Step, queryParams?: any) {
    if(!step) { return; }

    if(step?.route !== this._activeStep?.route) {
      this.router.navigate([step.route], {queryParams, queryParamsHandling: 'merge' });
    }
  }

  protected getActiveIndex$() {
    const urlChanges$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects)
    );

    // Monitor the url changes by starting with the current url
    return merge(of(this.router.url), urlChanges$).pipe(
      map(url => (url || '').split('?')[0]), // Remove query parameters
      map(url => this.steps.findIndex(step => {
        const urlParts = url.split('/').filter(part => part != null);
        const stepParts = step.route.split('/').filter(part => part != null);
        return stepParts.every((part, index) => {
          const urlPart = urlParts[index];
          return urlPart != null && (part.startsWith(':') || urlPart === part);
        });
      })),
      map(index => Math.max(index, 0)),
    )
  }
}
