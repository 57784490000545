<triggered-card [title]="stepper?.currentStep?.header" [subtitle]="stepper?.currentStep?.subheader" [hideActions]="true">
  <ng-template #content>
    <!-- Horizontal Stepper for desktop/tablet -->
    <mat-horizontal-stepper *ngIf="stepper.initialStep | async" fxFlex [linear]="false" [selectedIndex]="stepper.currentIndex" class="hide-header no-padding no-card-padding">
      <mat-step>
        <ng-container *ngIf="stepper.currentIndex == 0">
          <ng-container *ngTemplateOutlet="SelectLoginMethod"></ng-container>
        </ng-container>
      </mat-step>


      <mat-step>
        <ng-container *ngIf="stepper.currentIndex == 1">
          <ng-container *ngTemplateOutlet="EmailLogin"></ng-container>
        </ng-container>
      </mat-step>

      <mat-step>
        <ng-container *ngIf="stepper.currentIndex == 2">
          <ng-container *ngTemplateOutlet="EmailSignup"></ng-container>
        </ng-container>
      </mat-step>


      <mat-step>
        <ng-container *ngIf="stepper.currentIndex == 3">
          <ng-container *ngTemplateOutlet="Profile"></ng-container>
        </ng-container>
      </mat-step>
    </mat-horizontal-stepper>

    <!-- Login Form -->
    <ng-template #SelectLoginMethod>

      <div fxLayout="column" fxLayoutGap="15px">
        <form [formGroup]="loginForm" fxLayout="column" fxLayoutGap="1em" (ngSubmit)="onCheckEmail(loginForm)">
          <div class="margin-bottom-0">
            <triggered-textbox type="email" name="email" [readonly]="isSubmitting" [formControl]="loginForm.getControl('email')" label="Email" placeholder="Email"></triggered-textbox>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em">

            <button id="sign-in-email-btn" fxFlex mat-raised-button color="primary" [disabled]="isSubmitting" type="submit">
              <div *ngIf="!isSubmitting || isSubmittingWithGoogle || isSubmittingWithApple; else sending" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <mat-icon>email</mat-icon>
                <span fxFlex="0 1 150px" class="text-center">Sign in with Email</span>
                <div fxFlex="24px"></div>
              </div>
              <ng-template #sending>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                  <mat-spinner [diameter]="16"></mat-spinner>
                  <span>Verifying ...</span>
                </div>
              </ng-template>
            </button>
          </div>
        </form>

        <!-- OR Divider -->
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
          <mat-divider fxFlex class="border-top-primary"></mat-divider>
          <span class="mat-caption text-primary">OR</span>
          <mat-divider fxFlex class="border-top-primary"></mat-divider>
        </div>

        <button mat-stroked-button [disabled]="isSubmitting" (click)="onGoogleLogin()">
          <div *ngIf="!isSubmittingWithGoogle; else googleLoggingIn" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
            <mat-icon svgIcon="google-1"></mat-icon>
            <span fxFlex="0 1 150px" class="text-center">Sign in with Google</span>
            <div fxFlex="24px"></div>
          </div>
          <ng-template #googleLoggingIn>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-spinner [diameter]="16"></mat-spinner>
              <span>Signing In...</span>
            </div>
          </ng-template>
        </button>

        <button mat-stroked-button color="default" [disabled]="isSubmitting" (click)="onAppleLogin()">
          <div *ngIf="!isSubmittingWithApple; else appleLoggingIn" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" >
            <mat-icon svgIcon="apple-logo" class="text-default"></mat-icon>
            <span fxFlex="0 1 150px" class="text-center">Sign in with Apple</span>
            <div fxFlex="24px"></div>
          </div>
          <ng-template #appleLoggingIn>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-spinner [diameter]="16"></mat-spinner>
              <span>Signing In...</span>
            </div>
          </ng-template>
        </button>


      </div>
      <div class="text-center margin-top-1">
        <span class="mat-caption">
          By continuing, you agree to Triggered's
          <a (click)="onOpenTermsAndConditions()">
            <span class="mat-caption text-semi-bold">Terms and Conditions</span>
          </a>
          and
          <a (click)="onOpenPrivacyPolicy()">
            <span class="mat-caption text-semi-bold">Privacy Policy</span>
          </a>
        </span>
      </div>
    </ng-template>

    <!-- Email/Password Login -->
    <ng-template #EmailLogin>
      <form [formGroup]="loginForm" fxLayout="column" fxLayoutGap="1em" (ngSubmit)="onLoginWithEmail(loginForm)">
        <triggered-card>
          <ng-template #content>
            <triggered-textbox [readonly]="true" formControlName="email" label="Email" placeholder="Email"></triggered-textbox>
            <triggered-password [readonly]="isSubmitting" name="current-password" formControlName="password" label="Password" placeholder="Password"></triggered-password>
          </ng-template>

          <ng-template #actions>
            <triggered-form-buttons saveText="Login" cancelText="Back" savingText="Logging In..." [isSaving]="isSubmitting" (cancelled)="stepper.goToStep(Steps.SelectLoginMethod)"></triggered-form-buttons>
            <button mat-button color="primary" (click)="onForgotPassword(loginForm)" type="button">Forgot Password</button>
          </ng-template>
        </triggered-card>
      </form>
    </ng-template>

    <!-- Email Password Signup -->
    <ng-template #EmailSignup>
      <form [formGroup]="signUpForm" fxLayout="column" fxLayoutGap="1em" (ngSubmit)="onSignupWithEmail(signUpForm)">
        <triggered-card>
          <ng-template #content>
            <triggered-textbox [readonly]="true" formControlName="email" label="Email" placeholder="Email"></triggered-textbox>
            <triggered-password [readonly]="isSubmitting" name="new-password" formControlName="password" label="Password" placeholder="Password"></triggered-password>
            <!-- <triggered-password-strength [password]="signUpForm?.getControl('password')?.value"></triggered-password-strength> -->
            <triggered-password [readonly]="isSubmitting" name="new-password" formControlName="passwordConfirmation" label="Confirm Password" placeholder="Confirm Password"></triggered-password>


          </ng-template>

          <ng-template #actions>
            <triggered-form-buttons saveText="Create Account" cancelText="Back" savingText="Registering..." [isSaving]="isSubmitting" (cancelled)="stepper.goToStep(Steps.SelectLoginMethod)"></triggered-form-buttons>
          </ng-template>
        </triggered-card>
      </form>
    </ng-template>

    <!-- Complete Profile -->
    <ng-template #Profile>
      <triggered-profile-form saveText="Next" (cancelled)="onCancelProfileInfo()" (saved)="onAfterProfileUpdated($event)" (loggedOut)="onAfterLoggedOut()" [hideHeader]="true"></triggered-profile-form>
    </ng-template>
  </ng-template>
</triggered-card>
