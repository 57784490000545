import { Injectable } from '@angular/core';
import { ApiService, EventLoggingService } from '@triggered/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { User, UserForm } from '../models/user.model';
import { FirestoreDatabase } from '@triggered/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private readonly database: FirestoreDatabase, private readonly apiService: ApiService) {
  }

  getUser$(userId: string): Observable<User> {
    const user = this.database.getDocument<User>(`users`, userId, User);
    return user.data$;
  }

  async updateUser(form: UserForm): Promise<UserForm> {
    const user = this.database.getDocument(`users`, form.id, User);
    await user.update(form);
    return form;
  }

  async deleteUser(userId: string): Promise<void> {
    await this.apiService.delete(`/api/v1/users/${userId}`);
  }

  async afterLogin(loggedInUser: User): Promise<User> {
    // Locate the existing user
    let user = await this.getUser$(loggedInUser.uid).pipe(take(1)).toPromise();
    if(user == null) {
      // Create the global user based on the companyUser
      user = new User(loggedInUser);
      user.isAdmin = false; // Never initialize as admin

      const userDoc = this.database.getDocument(`users`, user.uid, User);
      await userDoc.update(new UserForm(user));
    }

    EventLoggingService.logEvent('login', {userId: user?.id, firstName: user?.firstName, lastName: user?.lastName, email: user?.email, phone: user?.phoneNumber})
    return user;
  }
}
