import { EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, FormArray, FormControl } from '@angular/forms';
import { Form as DocumentForm } from '@triggered/common';
export class Form<TModel = any> extends FormGroup implements Form, DocumentForm<TModel>  {
  private _isNew: boolean;
  offline: boolean;

  get id(): string {
    const control =  this.get('id');
    return control ? control.value : null;
 }

  originalValue: Partial<TModel>;
  isEdit: boolean;
  get isNew(): boolean { return this._isNew === true || this.id == null};
  set isNew(val) { this._isNew = val; }

  isSaving: boolean;

  static createFormArray<TArrayType = any>(array: TArrayType[], formCreator: (value: TArrayType) => AbstractControl) {
    const controls = (array || []).map(formCreator);
    const formArray = new FormArray(controls || []);
    return formArray
  }


  static performOnAllControls(form: FormGroup, action: (control: AbstractControl) => any) {
    // Perform action on current form
    action(form);

    // Enumerate all controls and perform action
    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];

      if (control instanceof FormArray) {

        // Element is a FormArray. Enumerate all controls and perform action
        for(let arrayControl of control.controls){
          if(arrayControl instanceof Form) {
            Form.performOnAllControls(arrayControl, action);
          } else {
            action(arrayControl);
          }
        }
      } else {
        if(control instanceof Form) {
          Form.performOnAllControls(control, action);
        } else {
          action(control);
        }
      }
    })
  }

  getAbstractControl(propertyName: keyof TModel): AbstractControl {
    return this.get(propertyName.toString());
  }

  getControl(propertyName: keyof TModel): FormControl {
    return this.get(propertyName.toString()) as FormControl;
  }

  getGroup(propertyName: keyof TModel): FormGroup {
    return this.get(propertyName.toString()) as FormGroup;
  }

  getFormArray<TArrayType>(propertyName: keyof TModel): SOLIDFormArray<TArrayType> {
    return this.get(propertyName.toString()) as SOLIDFormArray<TArrayType>;
  }

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.originalValue = value ?? this.originalValue;
    // TODO: Reset any form arrays
    Object.keys(this.controls)
      .map(key => this.controls[key])
      .filter(control => control instanceof FormArray)
      .forEach((control: FormArray) => {
        if(control instanceof SOLIDFormArray) {
          control.reset();
        } else {
        control.controls.forEach((arrayControl, index) => {
            if (arrayControl instanceof Form && arrayControl.isNew) {
              control.removeAt(index)
            } else {
              arrayControl.reset();
            }
          });
        }
      });

    super.reset(value || this.originalValue, options);
    this.markAllAsPristine();
  }

  private controlsOf(filter: (control: AbstractControl) => boolean) {
    const controls = Object.keys(this.controls)
      .map(key => ({key, control: this.controls[key]}))
      .filter(value => filter(value.control));
    return controls;
  }
  setValue(value: any, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    super.setValue(value, options);
    this.patchFormArrays(value, options);

  }
  patchValue(value: any, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    super.patchValue(value, options);
    this.patchFormArrays(value, options);

  }

  private patchFormArrays(value: any, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    const formArrays = this.controlsOf(control => control instanceof SOLIDFormArray);
    // TODO: Do recursively
    formArrays.forEach(arrayValue => {
      const values = value[arrayValue.key] || [];
      arrayValue.control.patchValue(values, options);
    })
  }

  isDirtyValid(): boolean {
    const isValid =  Object.keys(this.controls).every(key => {
      const control = this.controls[key];
      return !control.dirty || control.valid;
    });
    return isValid;
  }

  getDirty(): any {
    const dirty = {};
    Object.keys(this.controls).forEach(key => {
      const control = this.controls[key];
      if (!control.dirty) {
        return;
      }
      if(control instanceof Form) {
        // NOTE: Not recursively calling getDirty() as we need the entire content of the sub-forms
        // dirty[key] = control.getDirty();
        dirty[key] = control.value;
      } else {
        dirty[key] = control.value;
      }
    });
    return dirty;
  }

  setFormArray(formControlName: keyof TModel, values: AbstractControl[]) {
    const array = this.getAbstractControl(formControlName) as FormArray;
    while(array.length) {
      array.removeAt(0);
    }

    values.forEach(value => array.push(value));
  }

  markInvalidAsDirty() {
    Form.performOnAllControls(this, control => {
      if(control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    });
  }

  markAllAsDirty() {
    Form.performOnAllControls(this, control => {
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  markAllAsPristine() {
    Form.performOnAllControls(this, control => {
      control.markAllAsTouched();
      control.markAsPristine();
      control.updateValueAndValidity();
    });
  }
}


export class SOLIDFormArray<TModel, TForm extends AbstractControl = Form<TModel>> extends FormArray {
  models: TModel[];
  readonly formCreator: (value: TModel) => TForm;
  readonly added: EventEmitter<TForm | AbstractControl> = new EventEmitter<TForm | AbstractControl>();
  readonly originalValues: TModel[];

  get forms(): TForm[] {
    return (this.controls as any) as TForm[];
  }

  constructor(values: TModel[], formCreator: (value: TModel) => TForm) {
    super((values || []).map(formCreator));
    this.models = values || [];
    this.formCreator = formCreator;
  }

  elementAt(index: number): TForm  {
    return this.forms[index];
  }

  removeAt(index: number){
    super.removeAt(index);
    this.markAsDirty();
  }

  addControl(value?: TModel) {
    const newForm = this.formCreator(value);
    if (newForm instanceof Form) {
      newForm.isNew = true;
      newForm.isEdit = true;
    }
    this.push(newForm);
    this.added.emit(newForm);
    this.markAsDirty()
    return newForm;
  }

  addForm(form: Form<TModel>) {
    this.push(form);
  }

  deleteControl(form: Form<TModel>) {
    const indexOfControl = this.controls.indexOf(form);
    if (indexOfControl >= 0) {
      this.removeAt(indexOfControl);
      this.markAsDirty();
    }
  }

  setValue(values: any[], options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    const controls = (values || []).map(this.formCreator);
    this.controls.splice(0, this.controls.length, ...controls);
    super.setValue(values, options);
  }

  patchValue(values: any[], options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    const controls = (values || []).map(this.formCreator);
    this.controls.splice(0, this.controls.length, ...controls);
    super.patchValue(values, options);
  }

  reset(models?: TModel[]) {
    this.controls = (models || this.models || []).map(this.formCreator);
    super.reset(models || this.models || []);
  }
}
