<div class="card mat-card overlay-container" fxLayout="column" fxLayoutGap="1em" title="" [ngClass]="'card-margins-' + margins">
  <div *ngIf="loading" class="overlay center">
    <mat-spinner [diameter]="24"></mat-spinner>
    <span *ngIf="loadingText" class="mat-body-2 text-primary margin-x-1">{{loadingText}}</span>
  </div>

  <ng-container *ngIf="imageTemplate">
    <div class="card-image image-container">
      <template [ngTemplateOutlet]="imageTemplate"></template>
    </div>
  </ng-container>

  <!-- Card Header-->
  <ng-container *ngIf="(title || subtitle) || (header || subheader) || headerTemplate || headerAction">
    <template *ngIf="headerTemplate; else defaultHeader" [ngTemplateOutlet]="headerTemplate"></template>
    <ng-template #defaultHeader>
      <div class="card-header" fxFlex="40px" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
        <div fxLayout="column">
          <h1 *ngIf="header || title" class="margin-0 mat-h3 text-ellipsis">{{header || title}}</h1>
          <h2 *ngIf="subheader || subtitle" class="margin-0 mat-caption">{{subheader || subtitle}}</h2>
        </div>

        <template *ngIf="headerAction" [ngTemplateOutlet]="headerAction"></template>
      </div>

    </ng-template>
    <mat-divider *ngIf="contentTemplate"></mat-divider>
  </ng-container>

  <div *ngIf="contentTemplate" class="card-content" fxLayout="column" fxFlex="grow">
    <template [ngTemplateOutlet]="contentTemplate"></template>
    <ng-content></ng-content>
  </div>


  <ng-container *ngIf="actionTemplate != null && !(hideActions === true)">
    <mat-divider class="action-divider"></mat-divider>

    <div class="action-content" fxLayout="column" fxLayoutGap="1em">
      <template [ngTemplateOutlet]="actionTemplate"></template>
    </div>

  </ng-container>

</div>
