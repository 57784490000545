import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '@triggered/core';

@Component({
  selector: 'triggered-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements AfterViewInit {

  @Input() collapsed: boolean;
  @Input() menuItems: MenuItem[];

  @Output() readonly clicked = new EventEmitter<MenuItem>();

  // Workaround for angular component issue #13870
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  onClick(menuItem: MenuItem) {
    this.clicked.emit(menuItem);
  }
}
