import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserSettingsForm, UserSettingsModel } from '../models/user-settings.model';
import { AuthenticationCoreModule } from '../core.module';
import { AuthenticationService } from './authentication.service';
import { FirestoreDatabase } from '@triggered/firestore';

@Injectable({
  providedIn: AuthenticationCoreModule
})
export class UserSettingsService {
  private readonly userId$: Observable<string>;

  constructor(private readonly userService: AuthenticationService, private database: FirestoreDatabase) {
    this.userId$ = this.userService.user$.pipe(map(user => user?.id));
  }

  async updateSettings<TType extends UserSettingsModel = UserSettingsModel>(form: UserSettingsForm<TType>) {
    const userId = await this.userId$.pipe(take(1)).toPromise();
    if(!userId) {
      const error = `User is not logged in. Cannot update settings!`
      throw new Error(error);
    }

    form.isSaving = true;
    const document = this.database.getDocument(`users/${userId}/settings`, form.id);
    const updatedSettings = await document.update(form);
    form.isSaving = false;
    return updatedSettings;
  }

  getSettings$(settingsId: string) {
    return this.userId$.pipe(
      switchMap(userId => this.getUserSettings$(userId, settingsId))
    );
  }

  getUserSettings$(userId: string, settingsId: string) {
    if(!userId) { return of(null); }
    const document = this.database.getDocument(`users/${userId}/settings`, settingsId);
    return document.data$;
  }

  getUserSettings(userId: string, settingsId: string) {
    return this.database.getDocument(`users/${userId}/settings`, settingsId);

  }
}
