import { Component, Input, OnInit } from '@angular/core';


export class NavigationChip {
  public label: string;
  public fullPath: string;
}

@Component({
  selector: 'triggered-navigation-chips',
  templateUrl: './navigation-chips.component.html',
  styleUrls: ['./navigation-chips.component.scss']
})
export class NavigationChipsComponent implements OnInit {
  @Input() tabs: NavigationChip[];

  constructor() { }

  ngOnInit(): void {
  }

}
