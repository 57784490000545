import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TriggeredRoutes } from '@triggered/core';
import { MainModule } from './main/main.module';

const routes: Routes = [
  { path: '', loadChildren: () => MainModule },

  // Temporary: Remove after a few releases
  { path: 'contact', redirectTo: TriggeredRoutes.app.contactUs.root, pathMatch: 'full'},
  { path: TriggeredRoutes.about.full, loadChildren: () => import('@triggered/about').then(m => m.AboutViewsModule) },
  { path: TriggeredRoutes.legal.root, loadChildren: () => import('@triggered/legal').then(m => m.LegalViewsModule) },
  { path: TriggeredRoutes.corporate.resources.root, loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule) },
  { path: TriggeredRoutes.corporate.contact.root, loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: TriggeredRoutes.corporate.give.root, loadChildren: () => import('./give/views/views.module').then(m => m.GiveViewsModule) },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
