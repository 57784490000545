// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule, MatIconModule,
  MatAutocompleteModule, MatInputModule, MatFormFieldModule, MatChipsModule
];
const exportedAngularModules = [
  FormsModule, ReactiveFormsModule
]

// Textbox Components
import { CustomErrorStateMatcher } from '../form-field';
import { ChipsComponent } from './chips.component';
const exportComponents = [ ChipsComponent ];

@NgModule({
  declarations: [...exportComponents],
  imports: [
    ...angularModules
  ],
  exports: [
    ...exportedAngularModules,
    ...exportComponents
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher }
  ]
})
export class TriggeredChipsModule { }
