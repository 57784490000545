<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em">
  <button *ngIf="showCancel" mat-stroked-button fxFlex color="default" (click)="onCancel()" [type]="cancelType" [disabled]="isSaving" tabindex="-1">{{cancelText}}</button>

  <button mat-raised-button fxFlex color="primary" type="submit" [disabled]="isSaving || saveDisabled">
    <span *ngIf="!isSaving; else submitting">{{saveText}}</span>
    <ng-template #submitting>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-spinner [diameter]="16"></mat-spinner>
        <span>{{savingText}}</span>
      </div>
    </ng-template>
  </button>
</div>
