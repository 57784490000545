// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule,
  MatInputModule, MatFormFieldModule
];
const exportedAngularModules = [
  FormsModule, ReactiveFormsModule
]

// Third Party Modules
import { NgxMaskModule } from 'ngx-mask';
const thirdPartyModules = [
  NgxMaskModule.forRoot(),
]

// Textbox Components
import { CustomErrorStateMatcher } from '../form-field';
import { TextboxMaskComponent } from './textbox-mask.component';
const exportComponents = [ TextboxMaskComponent ];

@NgModule({
  declarations: [...exportComponents],
  imports: [
    ...angularModules,
    ...thirdPartyModules
  ],
  exports: [
    ...exportedAngularModules,
    ...exportComponents
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher }
  ]
})
export class TriggeredTextboxMaskModule { }
