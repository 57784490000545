import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ErrorReporter, EventLoggingService, Form } from '@triggered/core';
import { PaymentProcessor, Subscription } from '@triggered/common';
import { SubscriptionService } from '../../core/services/subscription.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { UserService } from '../../core';
import { HttpErrorResponse } from '@angular/common/http';

export class DeleteAccountRequestForm extends Form {

  constructor() {
    super({
      confirmed: new FormControl(false, [Validators.requiredTrue])
    });
  }
}

class ViewModel {
  readonly processor: PaymentProcessor;
  readonly hasActiveSubscription: boolean;

  constructor(readonly activeSubscriptions: Subscription[]) {
    this.hasActiveSubscription = (activeSubscriptions || [])?.length > 0;
    if(this.hasActiveSubscription) {
      this.processor = activeSubscriptions[0].processor;
    }
  }
}

@Component({
  selector: 'triggered-delete-account-card',
  templateUrl: './delete-account.card.html',
  styleUrls: ['./delete-account.card.scss']
})
export class DeleteAccountCard {
  readonly form = new DeleteAccountRequestForm();
  readonly viewModel$: Observable<ViewModel>;
  readonly saving$ = new BehaviorSubject<boolean>(false);
  readonly loading$ = new BehaviorSubject<boolean>(true);

  @Output() readonly deleted = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();

  constructor(private readonly authService: AuthenticationService, subscriptionService: SubscriptionService,
             private readonly userService: UserService) {
    this.viewModel$ = combineLatest([subscriptionService.getMySubscriptions$(true)]).pipe(
      map(([activeSubscriptions]) => new ViewModel(activeSubscriptions || [])),
      tap(() => this.loading$.next(false))
    )
  }

  async onCancelled() {
    this.cancelled.emit();
  }

  async onDelete() {
    if(this.form.invalid){
      return this.form.markAllAsDirty();
    }

    this.saving$.next(true);
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    await this.userService.deleteUser(user.id)
      .then(() => {
        EventLoggingService.logEvent('account_deleted', { userId: user.id })
        this.deleted.next();
      })
      .catch((error: HttpErrorResponse) => {
        ErrorReporter.report(new Error(`SubscriptionCard: Error while deleting user account ${user.id}. Error: ` + error?.message), error);
        this.form.getControl('confirmed').setErrors({ custom: 'An error occured deleting your account. Please try again'});
      })
      .finally(() => this.saving$.next(false))
  }

}
