import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
const angularModules = [
  CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MatIconModule
];

// Triggered Modules
import { TriggeredCardModule, TriggeredCheckboxModule, TriggeredFormButtonsModule } from '@triggered/ui';
import { AuthenticationCoreModule } from '../../core/core.module';
const triggeredModules = [
  TriggeredCardModule, TriggeredFormButtonsModule, AuthenticationCoreModule,
  TriggeredCheckboxModule
];

import { DeleteAccountCard } from './delete-account.card';
const externalComponents = [ DeleteAccountCard];


@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [
    ...angularModules,
    ...triggeredModules

  ]
})
export class DeleteAccountCardModule { }
