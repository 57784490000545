import { Component, Input, OnDestroy, HostBinding, Optional, Self, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FormControl, NgControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { FormField } from '../../form-field';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'triggered-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: EditorComponent}
  ]
})
export class EditorComponent extends FormField implements MatFormFieldControl<{}>, OnDestroy {
  private static nextId = 0;
  private _placeholder: string;

  public readonly stateChanges = new Subject<void>();

  @HostBinding()
  public id = `solid-editor-${EditorComponent.nextId++}`;

  @Input()
  public get placeholder() {
    return this._placeholder;
  }

  @Output() readonly blur = new EventEmitter<void>();

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      []
    ],

    customClasses: [
      {
        name: "title",
        class: "mat-title",
        tag: "span",
      },
    ]
  };

  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  @Input()
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;

  constructor(@Optional() @Self() public ngControl: NgControl, sanitizer: DomSanitizer,
    private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>) {
    super(sanitizer);
    this.change.subscribe(() => { this.stateChanges.next(); });
    // this.errorsChanged.subscribe(errors =>)
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      // See https://material.angular.io/guide/creating-a-custom-form-field-control#-code-ngcontrol-code-
      this.ngControl.valueAccessor = this;
    }
    // this.modules = defaultLayout?.modules || {
    //   toolbar: [
    //     ['bold', 'italic', 'underline'],        // toggled buttons
    //     ['blockquote', 'code-block'],
    //     [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    //     [{ 'align': ['', 'center', 'right'] }],
    //     ['link']
    //   ]
    // };
  }

  ngOnDestroy() {
    // this.stateChanges.complete();
    // this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    // if ((event.target as Element).tagName.toLowerCase() !== 'input') {
    //   this.elRef.nativeElement.querySelector('input').focus();
    // }
  }

  public onBlur() {
    this.executeOnTouched();
    this.blur.emit();

  }
}
