const devFirebase = {
  apiKey: "AIzaSyArq0UO-CFTwD8X9OXPijHU_ZKDd1JKxjA",
  authDomain: "solid-apps-triggered-dev.firebaseapp.com",
  projectId: "solid-apps-triggered-dev",
  storageBucket: "solid-apps-triggered-dev.appspot.com",
  messagingSenderId: "417371738871",
  appId: "1:417371738871:web:0faf8c037f5281d57839f2",
  databaseURL: 'https://solid-apps-triggered-dev.firebaseio.com',
  measurementId: 'G-LL9CMWPS9L'
};

export const developmentConfig = {
  production: false,
  useEmulator: false,
  reportErrors: true,
  companyId: 'solid-apps-triggered',
  appVersion: '1.0.6',

  firebase: devFirebase,

  endpoints: {
    baseApiUrl: 'https://triggered-web-corporate-dev.web.app',
    baseAppUrl: 'https://triggered-web-app-dev.web.app',
    baseCorporateUrl: 'https://triggered-web-corporate-dev.web.app'
  },
  stripe: {
    publicKey: 'pk_test_QYn40t5qe7kNHASQwejFQwME00dSDdSHDm'
  },

  products: {
    oneYearGiftSubscription: {
      productId: 'gift-one-year-subscription-v1',
      priceId: 'price_1KTWuqCLwIdzRt749IQhmRC8'
    }
  }
}
