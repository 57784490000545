import { Component, forwardRef, Input, EventEmitter, Attribute, ContentChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormField } from '../form-field';
import { Output } from '@angular/core';
import { Mask } from './masks';

@Component({
  selector: 'triggered-textbox-mask',
  templateUrl: './textbox-mask.component.html',
  styleUrls: ['./textbox-mask.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxMaskComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextboxMaskComponent),
      multi: true,
    }
  ]
})
export class TextboxMaskComponent extends FormField {
  @Input() hint: string;

  @Input() maxLength: string;

  @Input() label: string;

  @Input() prefix: string;

  @ContentChild('prefix', { static: true})
  prefixTemplate: TemplateRef<any>;

  @ContentChild('suffix', { static: true})
  suffixTemplate: TemplateRef<any>;

  @Input() suffix: string;

  @Input() showMask: boolean;
  @Input() textMask: string;

  @Input() min: number;

  @Input() max: number;

  @Input() readonly: boolean;

  @Input() placeholder = '';

  @Input() mask: Mask;
  @Input() dropSpecialCharacters: boolean | string[];
  @Input() patterns: { [character: string]: { pattern: RegExp, optional?: boolean} };
  @Output() readonly blur = new EventEmitter<void>();

  constructor(sanitizer: DomSanitizer, private ref: ChangeDetectorRef) {
    super(sanitizer);
  }

  protected setErrors(formControl: FormControl) {
    super.setErrors(formControl);
    this.ref.markForCheck();
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }
}

