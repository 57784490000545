<mat-form-field [appearance]="appearance" floatLabel="always" [class.ng-valid]="errors === ''" [class.margin-bottom-1]="hint != null" [class.mat-form-field-readonly]="readonly">

  <!-- Prefix -->
  <div matPrefix>
    <template *ngIf="prefixTemplate; else defaultPrefix" [ngTemplateOutlet]="prefixTemplate" ></template>
    <ng-template #defaultPrefix>
      <span *ngIf="prefix">{{prefix}}</span>
    </ng-template>
  </div>

  <!-- <mat-icon matPrefix>phone</mat-icon> -->
  <mat-label>{{label}}</mat-label>
  <input matInput [placeholder]="placeholder" [formControl]="formControl"
    [type]="type" [min]="min" [max]="max"
    [readonly]="readonly"
    [name]="autoComplete ? name : 'do-not-auto'"
    [autocomplete]="autoComplete ? name || 'on' : 'do-not-auto'"
    (blur)="onBlur()" [errorStateMatcher]="matcher">


  <mat-hint>
    <template *ngIf="hintTemplate; else defaultHint" [ngTemplateOutlet]="hintTemplate" ></template>
    <ng-template #defaultHint>
      {{hint}}
    </ng-template>
  </mat-hint>
  <mat-error [innerHTML]="errors"></mat-error>

  <!-- Suffix -->
  <div matSuffix>
    <template *ngIf="suffixTemplate; else defaultSuffix" [ngTemplateOutlet]="suffixTemplate" ></template>
    <ng-template #defaultSuffix>
      <span *ngIf="suffix">{{suffix}}</span>
    </ng-template>
  </div>
</mat-form-field>
