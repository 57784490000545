import { Injectable } from '@angular/core';
import { FirestoreDatabase } from '@triggered/firestore';
import { NewsletterSubscriptionForm, NewsletterSubscriptionModel } from '../core';
import { ContactServicesModule } from './services.module';

@Injectable({
  providedIn: ContactServicesModule
})
export class NewsletterService {
  protected get collectionPath(): string { return `modules/notifications/newsletter-subscriptions`; };

  constructor(private readonly database: FirestoreDatabase) { }

  async signUp(form: NewsletterSubscriptionForm) {
    const record = this.database.getDocument(this.collectionPath, form.id, NewsletterSubscriptionModel);
    return await record.update(form);
  }
}
