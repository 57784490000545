import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'triggered-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.scss']
})
export class FormButtonsComponent implements OnInit {
  @Input() isSaving: boolean;
  @Input() saveDisabled: boolean;
  @Input() saveText: string = 'Save';
  @Input() savingText: string = 'Saving';
  @Input() showCancel: boolean = true;
  @Input() cancelText: string = 'Cancel';
  @Input() cancelType: 'reset' | 'button' = 'button';

  @Output() readonly cancelled = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.cancelled.emit();
  }

}
