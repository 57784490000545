// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule,
  MatInputModule, MatFormFieldModule
];
const exportedAngularModules = [
  FormsModule, ReactiveFormsModule
]

// Third Party Modules
import { AngularEditorModule } from '@kolkov/angular-editor';
const thirdPartyModules = [
  AngularEditorModule
];

// Textbox Components
import { CustomErrorStateMatcher } from '../form-field';
import { TexteditorComponent } from './texteditor.component';
import { EditorComponent } from './editor/editor.component';
const internalComponents = [ EditorComponent ];
const exportComponents = [ TexteditorComponent ];

@NgModule({
  declarations: [...exportComponents, ...internalComponents],
  imports: [
    ...angularModules,
    ...thirdPartyModules
  ],
  exports: [
    ...exportedAngularModules,
    ...exportComponents
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher }
  ]
})
export class TriggeredTexteditorModule { }
