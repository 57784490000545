// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule,
  MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule
];
const exportedAngularModules = [
  FormsModule, ReactiveFormsModule
]

// Dropdown Components
import { CustomErrorStateMatcher } from '../form-field';
import { DropdownComponent } from './dropdown.component';
const exportComponents = [ DropdownComponent ];

@NgModule({
  declarations: [...exportComponents],
  imports: [
    ...angularModules
  ],
  exports: [
    ...exportedAngularModules,
    ...exportComponents
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher }
  ]
})
export class TriggeredDropdownModule { }
