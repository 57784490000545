import { Component, forwardRef, Input, EventEmitter, OnChanges, SimpleChanges, ContentChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormField } from '../form-field';
import { Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'triggered-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent extends FormField implements OnChanges {
  @Input() hint: string;
  @Input() prefix: string;
  @Input() suffix: string;

  @Input() label: string;

  @Input() placeholder = 'Select an option';

  @Input() disabled: boolean;

  @Input() allowEmpty: boolean;

  @Input() options: any[] = [];

  @Input() valuePath: string;

  @Input() displayPath: string;
  @Input() panelClass: string;

  @Output() readonly selectionChanged: EventEmitter<any> = new EventEmitter<any>();


  @ContentChild('option', { static: true}) optionTemplate: TemplateRef<any>;
  @ContentChild('display', { static: true}) displayTemplate: TemplateRef<any>;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnChanges(changes: SimpleChanges){
  }

  public onBlur() {
    this.executeOnTouched();
  }

  public onSelectionChanged(event: MatSelectChange) {
    this.selectionChanged.emit(event.value);
  }
}
