import { Form } from '@triggered/core';
import { FormControl, Validators } from '@angular/forms';
import { User as CoreUser, DateTimestamp } from '@triggered/common';

export class User extends CoreUser {}

export class UserForm extends Form<User> {


  constructor(value?: User) {
    const init = value || new User();
    super({
      id: new FormControl(init?.id),
      uid: new FormControl(init?.uid),
      firstName: new FormControl(init?.firstName),
      lastName: new FormControl(init?.lastName),
      email: new FormControl(init?.email,[Validators.required]),
      phoneNumber: new FormControl(init?.phoneNumber, [Validators.required]),
      timezone: new FormControl(init?.timezone || DateTimestamp.local().zoneName, []),
    });
    this.originalValue = init;
  }
}
