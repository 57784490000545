import { CollectionPath } from '../../data/paths'

export const REPORTSPATHS = {
  alerts: new CollectionPath('modules/reports/alerts'),
  alertsLifetime: new CollectionPath<{'alerts-by-hour': any}>('modules/reports/alerts-hourly-all-time'),
  alertsWeekly: new CollectionPath('modules/reports/alerts-hourly-by-week'),

  users: new CollectionPath('users', {
    allTimeAlerts: new CollectionPath('modules/reports/alerts-hourly-all-time'),
    weeklyAlerts: new CollectionPath('modules/reports/alerts-hourly-by-week'),
  }),

  subscriptions: new CollectionPath('subscriptions', {
    groups: new CollectionPath('groups', {
      allTimeAlerts: new CollectionPath('modules/reports/alerts-hourly-all-time'),
      weeklyAlerts: new CollectionPath('modules/reports/alerts-hourly-by-week'),
    }),
    allTimeAlerts: new CollectionPath('modules/reports/alerts-hourly-all-time'),
    weeklyAlerts: new CollectionPath('modules/reports/alerts-hourly-by-week'),
  })
}
