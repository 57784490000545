import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ExpandAnimationComponent } from './expand-animation/expand-animation.component';
const sharedComponents = [ ExpandAnimationComponent ];


@NgModule({
  declarations: [...sharedComponents],
  exports: [...sharedComponents],
  imports: [
    CommonModule
  ]
})
export class TriggeredAnimationsModule { }
