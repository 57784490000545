import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
const angularModules = [
  CommonModule, FlexLayoutModule, RouterModule, MatIconModule,
  MatDividerModule, MatListModule, MatExpansionModule
];

// Menu Components
import { NavMenuComponent } from './nav-menu.component';
const externalComponents = [ NavMenuComponent ];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [
    ...angularModules
  ]
})
export class TriggeredNavMenuModule { }
