

import { IProduct } from './product';
import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Record } from '../../data/record';
import { Reference } from '../../data/reference';
import { IPlan } from './plan';
import { ProductType } from '.';
import { PaymentProcessor } from './product';

export const userInvoicesCollectionPath = (userId: string) => `users/${userId}/modules/store/invoices`;

export interface IReceipt {
  url?: string | null;
}

export interface IPayment {
  last4: string | null;
  type: string;
}

export interface IInvoice {
  /** The id of the invoice id in stripe */
  id: string;

  /** The url of the invoice in stripe */
  url?: string | null | undefined;

  /** The url of the invoice in stripe */
  downloadUrl?: string | null | undefined;
}

/**
 * Representation of the Subscription object
 */
export interface IUserInvoice extends Record<IUserInvoice> {
  id: string;

  /** @deprecated */
  invoiceId: string;
  /** @deprecated */
  invoiceUrl?: string | null;

  invoice?: IInvoice | null;
  receipt?: IReceipt | null;
  payment?: IPayment | null;
  paymentProcessor: PaymentProcessor;

  currency: string;

  status: 'paid' | 'pending' | 'failed';

  subtotal: number;
  total: number;

  taxes: number;
  discount: number;

  amountPaid: number;
  amountDue: number;

  periodStartDate: DateTimestamp | FirebaseTimestamp;
  periodEndDate: DateTimestamp | FirebaseTimestamp;
  dueDate?: DateTimestamp | FirebaseTimestamp;
  paidDate?: DateTimestamp | FirebaseTimestamp;

  items: any[];

  plans?: Reference<IPlan, { id: string, name: string }>[]
  products?: Reference<IProduct, {id: string, name: string, quantity: number, type: ProductType}>[];

  hasGift?: boolean;
  hasSubscription?: boolean;
}

export interface UserInvoice extends IUserInvoice { }
export class UserInvoice extends Record<IUserInvoice> {
  // Assert that these date fields are DateTimestamps
  periodEndDate: DateTimestamp;
  periodStartDate: DateTimestamp;
  dueDate?: DateTimestamp;
  paidDate?: DateTimestamp;

  patchValue(model: UserInvoice) {
    if(!model) { return; }
    super.patchValue(model);
    this.dueDate = model.dueDate ? new DateTimestamp(model.dueDate) : undefined;
    this.paidDate = model.paidDate ? new DateTimestamp(model.paidDate) : undefined;
    this.periodStartDate = new DateTimestamp(model.periodStartDate);
    this.periodEndDate = new DateTimestamp(model.periodEndDate);
  }

}

// export default Subscription;
