import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SubscriptionService } from '../../core/services/subscription.service';
import { User } from '../../core/models/user.model';

@Component({
  selector: 'triggered-login-page',
  templateUrl: './login.view.html',
  styleUrls: ['./login.view.scss']
})
export class LoginView {


  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly subscriptionService: SubscriptionService) {

  }

  async onAfterLogin(user: User) {
    const returnUrl = this.route.snapshot.queryParams.returnUrl;
    if (returnUrl != null) {
      // login successful so redirect to return url
      await this.router.navigateByUrl(returnUrl);
    } else {
      // Check if user is registered, if so, redirect to alert page
      const activeSubscriptions = await this.subscriptionService.getMySubscriptions$(true).pipe(take(1)).toPromise();
      if(activeSubscriptions.some(sub => sub.isActive)) {
        // The user has an active subscription. Redirect them to the 'alert' module

      }

      // Check if user is an accountability partner, if so, redirect to partner settings

      // Otherwise, redirect to sign up page (As a user, as a partner)


      await this.router.navigate([]);
    }
  }
}
