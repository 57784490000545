// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
const angularModules = [ CommonModule, FlexLayoutModule, MatButtonModule ];

// Internal Modules
import { MainRoutingModule } from './main-routing.module';
import { TriggeredCoreModule, TriggeredLayoutModule } from '@triggered/core';
import { TriggeredDialogModule } from '@triggered/ui';
import { ContactComponentsModule } from '@triggered/contact';
const internalModules = [ MainRoutingModule, TriggeredCoreModule, TriggeredLayoutModule, ContactComponentsModule, TriggeredDialogModule ];

// Internal Views
import { HomeView } from './views/home/home.view';
import { HowItWorksView } from './views/how-it-works/how-it-works.view';
import { DownloadView } from './views/download/download.view';
import { WindowsView } from './views/download/windows/windows.view';
import { AndroidView } from './views/download/android/android.view';
import { ChromeExtensionView } from './views/download/chrome-extension/chrome-extension.view';
import { IosView } from './views/download/ios/ios.view';
import { MacView } from './views/download/mac/mac.view';
import { OurMissionView } from './views/our-mission/our-mission.view';
const internalViews = [
  HomeView, OurMissionView, HowItWorksView, DownloadView, WindowsView, AndroidView, ChromeExtensionView, IosView, MacView];

@NgModule({
  declarations: [...internalViews ],
  imports: [
    ...angularModules,
    ...internalModules
  ]
})
export class MainModule { }
