<!-- Delete Account -->

<form [formGroup]="form" (ngSubmit)="onDelete()">
<triggered-card [loading]="loading$ | async" title="Delete Account" subtitle="Please review the following notes">
    <ng-container *ngIf="viewModel$ | async as viewModel">
      <ng-template #content>
        <div  fxLayout="column" fxLayoutGap="1em">
          <div>
            <p class="mat-body-1 margin-0">
              By continuing, you are confirming you have read the following information regarding
              your account deletion:
            </p>
          </div>


            <!-- Warning: Active Subscription -->
          <div *ngIf="viewModel.hasActiveSubscription" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" class="bg-warn-100 padding-1">

            <mat-icon class="material-icons-outlined">warning</mat-icon>

            <p class="mat-body-1 margin-0">
              It appears you still have an active subscription.
              <ng-container [ngSwitch]="viewModel.processor">
                <ng-container *ngSwitchCase="'apple-iap'">
                  You may cancel your subscription through your Apple account settings at any time.
                </ng-container>

                <ng-container *ngSwitchCase="'android-iap'">
                  You may cancel your subscription through your Google Play account settings at any time.
                </ng-container>

                <ng-container *ngSwitchDefault>
                  You may cancel your subscription on the
                  <a [routerLink]="['/settings/billing']">
                    <span class="mat-hint"><u>Subscriptions&nbsp;Page</u></span>
                  </a> prior to deleting your account or we will deactivate your account for you.
                </ng-container>

              </ng-container>
            </p>
          </div>

          <!-- Info: Date Deletion -->
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" class="bg-accent padding-1">

            <mat-icon class="material-icons-outlined">info</mat-icon>

            <p class="mat-body-1 margin-0">
              Deleting your account will remove all data associated to you and your user.
              If you choose to re-join Triggered, all of your previous account data, alert trends, reports
              and teams will no longer be available and you will need to start with a fresh account.
            </p>
          </div>

          <!-- Info: Partners Notified -->
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" class="bg-accent padding-1">

            <mat-icon class="material-icons-outlined">info</mat-icon>
            <p class="margin-0 mat-body-1">
              Your support team and the people you are supporting will be notified that you will no longer be using Triggered.
            </p>

          </div>


        </div>
      </ng-template>

      <ng-template #actions>
        <div fxLayout="row" fxLayoutAlign="center">
          <triggered-checkbox [formControl]="form.getControl('confirmed')">Yes, I confirm</triggered-checkbox>
        </div>

        <triggered-form-buttons saveText="Confirm" [isSaving]="saving$ | async" savingText="Deleting..." (cancelled)="onCancelled()"></triggered-form-buttons>
      </ng-template>
    </ng-container>
</triggered-card>

</form>
