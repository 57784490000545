import {
  Component, forwardRef, Input, TemplateRef, ContentChild, EventEmitter, Output, ViewEncapsulation, ChangeDetectionStrategy
} from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormField } from '../form-field';

@Component({
  selector: 'triggered-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    }
  ]
})
export class PasswordComponent extends FormField {
  @Input() hint: string;

  @Input() maxLength: string;

  @Input() label: string;

  @Input() prefix: string;

  @Input() suffix: string;

  @Input() readonly: boolean;

  @Input() placeholder = '';

  @ContentChild('prefix', { static: true})
  prefixTemplate: TemplateRef<any>;

  @ContentChild('suffix', { static: true})
  suffixTemplate: TemplateRef<any>;

  @Output()
  public readonly blur = new EventEmitter<void>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }

}
