<div fxFlex="0 1 500px" fxLayout="column" fxLayoutGap="2em">
  <div class="">
    <h3 class="mat-h2 margin-0">Windows App</h3>
    <h4 class="mat-h3 margin-0">Coming summer 2021</h4>
  </div>

  <div class="mat-body-1">
    <p>
      Triggered will soon be installable on Windows desktop and laptop devices, providing you
      with more tools to get instant accountability and support in your time of need.
    </p>

    <p class="margin-bottom-0">
      Subscribe below to receive product updates so that you can be the first to overcome with Triggered.
    </p>
  </div>

  <div class="w-100">
    <triggered-sendgrid-form fxFlex="0 1 500px" [newsletterIds]="['8e4f851a-9b84-465e-90b1-752038381e8b']" (submitted)="onSubmitted($event)" [displayName]="false"></triggered-sendgrid-form>
  </div>
</div>
