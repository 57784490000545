import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'triggered-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss']
})
export class ExpanderComponent implements OnInit {
  @Input() expanded: boolean = false;

  @Input() icon: string;
  @Input() header: string;
  @Input() subheader: string;
  @Input() description: string;
  @Input() tooltip: string;

  @ContentChild('expandedContent', { static: true}) descriptionTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
