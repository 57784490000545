import { Injectable } from '@angular/core';
import { DevicesSizeService, MenuItem, MenuProvider, TriggeredRoutes } from '@triggered/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppMenuProvider implements MenuProvider {
  readonly menuName: string = 'app';
  readonly menuItems$: Observable<MenuItem[]>;

  constructor(screenService: DevicesSizeService) {
    this.menuItems$ = screenService.isMobile$.pipe(
      map(isMobile => {
        const links: MenuItem[] = [];
        if(isMobile){
          links.push(new MenuItem({
            display: 'Home',
            routerLink: '/',
            icon:  isMobile ? 'home' : null,
            exactLinkActive: true
          }));
        }
        links.push(
          {
            display: 'Blog',
            routerLink: `/${TriggeredRoutes.corporate.resources.blogs.full}`,
            icon: isMobile ? 'library_books' : null
          },
          {
            display: 'Our Mission',
            routerLink: '/' + TriggeredRoutes.corporate.ourMission.full,
            icon: isMobile ? 'public' : null
          },
          {
            display: 'How it Works',
            routerLink: '/how-it-works' ,
            icon: isMobile ? 'system_security_update_good' : null
          },
          new MenuItem({
            isVisible$: of(isMobile),
            display: 'Give',
            routerLink: '/give',
            icon: isMobile ? 'redeem' : null
          }),
          {
            display: 'Sign Up',
            routerLink: '/pricing',
            type: 'raised',
            icon: isMobile ? 'how_to_reg' : null
          },
        );
        return links;
      })
    );
  }
}

