import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { IRecord, Record } from '../../data/record';
import { ContactInfo } from '../../models/contact-info';
import { IDelivery } from '../../notifications';

export enum AlertLevel {
  Red = 0,
  Yellow = 1,
}

export interface IUserContactInfo extends ContactInfo {

  /** The database reference to the user document  */
  userRef: any;
}

export interface IPartnerContactInfo extends ContactInfo {
  /** The partnerId corresponding to this partner */
  partnerId: string;
}


export interface IUserAlert extends IRecord {
  /** The level of the alert */
  level: AlertLevel;

  /** The timezone this alert was sent in */
  timezone?: string;

  /** The date the alert was sent */
  dateSent: DateTimestamp | FirebaseTimestamp;

  /** The ContactInfo of the user who sent the alert */
  respondTo: IUserContactInfo;

  /** The ContactInfo of the partners who received the alert */
  sentTo: IPartnerContactInfo[];

  delivery?: IDelivery;
}

export interface UserAlert extends IUserAlert {}
export class UserAlert extends Record<UserAlert> {
  static collection = (userId: string) => `users/${userId}/alerts`;
  static documentPath = (userId: string, alertId: string) => `${UserAlert.collection(userId)}/${alertId}`;

  dateSent: DateTimestamp;
  level = AlertLevel.Red;

  constructor(value?: Partial<UserAlert>) {
    super(value);
    this.patchValue(value);
  }

  patchValue(model?: Partial<UserAlert>) {
    if(!model) { return; }
    super.patchValue(model);
    this.dateSent =  new DateTimestamp(model.dateSent);

    delete this.respondTo?.userRef;
    if(this.delivery) {
      this.delivery.sentOn = new DateTimestamp(this.delivery.sentOn);
      this.delivery?.responses?.forEach(res => delete res.userRef);
    }
  }
}
