import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'smsLink'
})
export class SmsLinkPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(value: string, ...args: any[]): unknown {
    const phoneNumber: string = value;
    if(!phoneNumber) {
      console.error('No phone number provided');
      return;
    }

    const bodyText: string = args[0];

    // TODO: Add country code?
    const includesCountryCode = phoneNumber.startsWith('+1');
    const formattedNumber = (includesCountryCode ? '+' : '+1') + phoneNumber.replace(/\D/g, '');

    let link = `sms://${formattedNumber}`;
    if(bodyText?.length) {
      link += `?&body=${bodyText}`;
    }
    return this.domSanitizer.bypassSecurityTrustUrl(link);
  }

}
