import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { IRecord, Record } from '../../data/record';

export type MessageType = 'Alert' | 'Partner Response' | 'Peer Response' | 'Partner' | 'Group' | 'Partner' | 'Admin';

export interface InAppMessage {
  // The type of message being sent
  type: MessageType | string;

  /** The title to be displayed to the user */
  title: string;

  /** The subtitle */
  subtitle: string;

  /** Additional data used for routing, etc. */
  data?: {
    /** Where the notification should open to */
    route?: string;

    /** The icon to be displayed in the UI */
    icon?: string;

    /** Additional properties */
    [key: string]: any;
  };

  /**
   * Where the notification should open to
   * @deprecated Use data
   */
  route?: string;

  /**
   * The icon to be displayed in the UI
   * @deprecated Use data.icon
   **/
  icon?: string;
}
export interface IUserNotification extends InAppMessage, IRecord {
  isRead: boolean;

  /** The date this alert was sent */
  dateSent: DateTimestamp | FirebaseTimestamp;
}

export interface UserNotification extends IUserNotification { }
export class UserNotification extends Record<UserNotification> {
  static collection = (userId: string) => `users/${userId}/notifications`;
  static documentPath = (userId: string, id: string) => `${UserNotification.collection(userId)}/${id}`;

  // Assert that these date fields are DateTimestamps
  dateSent: DateTimestamp;

  patchValue(model: UserNotification) {
    if(!model) { return; }
    super.patchValue(model);
    this.dateSent = new DateTimestamp(model.dateSent);
  }
 }
