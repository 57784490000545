import { Component, Input } from '@angular/core';

@Component({
  selector: 'triggered-menu-item-content',
  templateUrl: './menu-item-content.component.html',
  styleUrls: ['./menu-item-content.component.scss']
})
export class MenuItemContentComponent {
  @Input() icon?: string;
  @Input() iconStyle?: 'outlined' | undefined = 'outlined';
  @Input() header: string;
  @Input() subheader: string;
}
