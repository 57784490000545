
import { environment } from '../environments/environment';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { MatSidenavModule } from '@angular/material/sidenav';
const angularModules = [
  BrowserAnimationsModule,
  // TODO: We could probably eliminate xs, sm, md, lg, and xl as they are not being used by themselves
  FlexLayoutModule.withConfig({ssrObserveBreakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'lt-md', 'lt-sm', 'gt-sm', 'gt-xs', 'gt-md']}),
  BrowserModule.withServerTransition({ appId: 'serverApp' }),
  HttpClientModule,
  MatSidenavModule
];

// SOLID Modules
const solidModules = [];

// Internal Modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@triggered/shared';
import { TriggeredNavMenuModule } from '@triggered/ui';
import { CustomDrawerScrollService, TriggeredCoreModule, TriggeredLayoutModule } from '@triggered/core';
const internalModules = [
  AppRoutingModule,
  SharedModule.forRoot(environment),
  TriggeredCoreModule.forRoot(),
  TriggeredLayoutModule,
  TriggeredNavMenuModule
];

// Internal Components
import { AppComponent } from './app.component';
const internalComponents = [ AppComponent ];

// Route Providers
import { menuProviders } from './providers/menu.providers';
@NgModule({
  declarations: [AppComponent, ...internalComponents],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    ...angularModules,
    ...solidModules,
    ...internalModules
  ],
  providers: [
    ...menuProviders
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(scrollService: CustomDrawerScrollService) {
    scrollService.initialize();
  }
}
