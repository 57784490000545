import { Component, Input, OnInit } from '@angular/core';

interface TitleOverride {
  /** Title: Override title tag*/
  title?: string;
}
interface DescriptionOverride {
  /** Title: Override title tag*/
  description?: string;
}

interface ImageOverride {
  /** Title: Override image tag*/
  image?: string;
}
interface TagsOverride {
  /** Title: Override image tag*/
  tags?: string;
}

export interface ShareConfig {
  /** The url to share. If empty, uses current page */
  url?: string;

  /** Overrides for linkedIn */
  linkedInOverrides?: TitleOverride & DescriptionOverride;

  /** Overrides for pinterest */
  pinterestOverrides?:  DescriptionOverride & ImageOverride;

  /** Title: Override title tag for linkedIn and Reddit */
  twitterOverrides?: TagsOverride;
}

@Component({
  selector: 'triggered-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss']
})
export class ShareButtonsComponent implements OnInit {
  @Input() url: string;
  @Input() config: ShareConfig;

  constructor() {
  }

  ngOnInit(): void {
  }

}
