import { BehaviorSubject, Observable } from 'rxjs';
import { IRecord, Collection, QueryOptions, RecordFn } from '@triggered/common';
import { FirestoreDatabase } from './firestore.database';
import { FirestoreDocument, FirestoreQueryDocument } from './firestore.document';
import { FirestorePaginator } from './firestore.pager';
import { CollectionQuery } from './firestore.query';
import { map, shareReplay, tap } from 'rxjs/operators';

export class FirestoreCollection<TRecord extends IRecord> extends Collection<TRecord> {

  private readonly _isLoading$ = new BehaviorSubject<boolean>(true);

  readonly isLoading$ = this._isLoading$.asObservable();
  readonly documents$: Observable<FirestoreQueryDocument<TRecord>[]>;

  constructor(collectionPath: string,
              readonly queryFn: CollectionQuery<TRecord>,
              readonly queryOptions?: QueryOptions<TRecord>,
              database?: FirestoreDatabase) {
    super(collectionPath, database);

    this.documents$ = queryFn(this.collectionPath, queryOptions).pipe(
      tap(() => this._isLoading$.next(false)),
      shareReplay(1)
    );
  }

  async create(record: Partial<TRecord>) {
    if(!this.database) { throw new Error('Document: Cannot update! Database not attached.'); }

    const created = await this.database.add(this.collectionPath, record);
    return new FirestoreDocument<TRecord>(this.collectionPath, record.id!, created as TRecord, this.database);
  }

  get(id: string) {
    if(!this.database) { throw new Error('Document: Cannot update! Database not attached.'); }
    return this.database.getDocument<TRecord>(this.collectionPath, id, this.queryOptions);
  }

  queryDocuments$(options?: QueryOptions<TRecord>) {
    return this.queryFn(this.collectionPath, options);
  }

  query$(options?: QueryOptions<TRecord>) {
    // Use the mapper from the constructor if not defined in the options;
    const queryOptions = options || {};
    if (!queryOptions.recordFn && this.queryOptions?.recordFn) {
      queryOptions.recordFn = this.queryOptions?.recordFn;
    }
    return this.queryFn(this.collectionPath, queryOptions).pipe(map(documents => documents.map(doc => doc.record)));
  }

  getPager(options?: QueryOptions<TRecord>) {
        // Use the mapper from the constructor if not defined in the options;
        const queryOptions = options || {};
        if (!queryOptions.recordFn && this.queryOptions?.recordFn) {
          queryOptions.recordFn = this.queryOptions?.recordFn;
        }
    return new FirestorePaginator(this.collectionPath, this.queryFn, queryOptions);
  }
}
