import { Collection } from './collection';
import { Document, DocumentOptions } from './document';
import { QueryOptions } from './queries';
import { IRecord } from './record';


export interface DocumentClient {
  document<TRecord extends IRecord>(fullPath: string, options?: DocumentOptions<TRecord>): Document<TRecord>;


  getCollection<TRecord extends IRecord>(collectionPath: string, options?: QueryOptions<TRecord>): Collection<TRecord>;
  getDocument<TRecord extends IRecord>(collectionPath: string, id: string, options?: DocumentOptions<TRecord>): Document<TRecord>;
  add<TRecord extends IRecord>(collectionPath: string, values: Partial<TRecord>, options?: {forceCommit?: boolean}): Promise<Partial<TRecord>>;
  update<TRecord extends IRecord>(documentPath: string, values: Partial<TRecord>, options?: {forceCommit?: boolean}): Promise<Partial<TRecord>>;

  deleteDocument(documentPath: string): Promise<void>;
  createDocument<TRecord extends IRecord>(collectionPath: string, values: Partial<TRecord>, options?: DocumentOptions<TRecord>): Promise<Document<TRecord>>
}
