
export const TriggeredRoutes = {
  corporate: {
    home: '',
    ourMission: {
      full: 'our-mission',
      relative: 'our-mission',
    },
    beta: {
      root: 'beta',
      join: {
        full: 'beta/join',
        partial: 'join',
      },
      instructions: {
        full: 'beta/instructions',
        partial: 'instructions',
      }
    },
    blog: {
      root: 'blog',
      blogs: {
        full: 'blog',
        partial: '',
      },
      blog: {
        full: 'blog/:path',
        partial: ':path',
        build: (path: string) => `blog/${path}`
      },
      category: {
        full: 'blog/category/:categoryName',
        partial: 'category/:categoryName',
        build: (categoryName: string) => `blog/category/${categoryName}`
      },
    },
    give: {
      root: 'give',
      full: 'give',
      partial: 'give',

      campaigns: {
        partial: 'campaigns',
        full: 'give/campaigns',
      },

      campaign: {
        partial: 'campaigns/:campaignId',
        full: `give/campaings/:campaignId`
      }
    },
    contact: {
      root: '',
      contactUs: {
        full: 'contact-us',
        partial: 'contact-us',
      },
      preLaunch: {
        full: 'pre-launch',
        partial: 'pre-launch',
      },
      pricing: {
        full: 'pricing',
        partial: 'pricing',
      },
      requestInfo: {
        full: 'request-info',
        partial: 'request-info',
      }
    },
    howItWorks: {
      full: 'how-it-works',
      relative: 'how-it-works',
    },
    preLaunch: {
      full: 'pre-launch',
      relative: 'pre-launch',
    },
    resources: {
      root: 'resources',
      full: 'resources',

      blogs: {
        relative: 'blog',
        full: 'resources/blog',
      }
    }
  },
  about: {
    full: 'about',
    relative: '',

    ourTeam: {
      full: 'about/our-team',
      relative: 'our-team',
    },
    ourPartners: {
      full: 'about/our-partners',
      relative: 'our-partners',
    },

    faqs: {
      full: 'about/faqs',
      relative: 'faqs',
    }

  },
  legal: {
    root: 'legal',
    full: 'legal',
    relative: '',

    privacyPolicy: {
      full: 'legal/privacy-policy',
      relative: 'privacy-policy',
    },
    termsAndConditions: {
      full: 'legal/terms-and-conditions',
      relative: 'terms-and-conditions',
    },
  },

  app: {

    alert: {
      root: 'alert',
      full: 'alert',

      alert: {
        relative: '',
        full: 'alert'
      }
    },
    download: {
      root: 'download',
      full: 'download'
    },
    contactUs: {
      root: 'contact-us',
      full: 'contact-us',

      contactUs: {
        relative: '',
        full: 'contact-us'
      }
    },

    invites: {
      root: 'invites',
      full: 'invites',

      login: {
        partial: 'login',
        full: 'invites/login'
      },

      accept: {
        partial: 'accept',
        full: 'invites/accept',

        buildFull: (code: string) => `/invites/accept?code=${code}`,

        short: {
          partial: 'a',
          full: 'i/a',
        }
      },


      joinTeam: {
        partial: ':code/join-team',
        full: 'invites/:code/join-team',
        buildFull: (code: string) => `/invites/${code}/join-team`,

        short: {
          partial: ':code/t',
          full: 'i/:code/t',
          buildFull: (code: string) => `/i/${code}/t`,
        }
      },

      joinGroup: {
        partial: ':code/join-group',
        full: 'invites/:code/join-group',
        buildFull: (code: string) => `/invites/${code}/join-group`,

        short: {
          partial: ':code/g',
          full: 'i/:code/g',
          buildFull: (code: string) => `/i/${code}/g`,
        }
      },

      joinAdmin: {
        partial: ':code/admin',
        full: 'invites/:code/admin',
        buildFull: (code: string) => `/invites/${code}/admin`,

        short: {
          partial: ':code/a',
          full: 'i/:code/a',
          buildFull: (code: string) => `/i/${code}/a`,
        }
      },

      acceptGift: {
        partial: ':code/accept-gift',
        full: 'invites/:code/accept-gift',
        buildFull: (code: string) => `/invites/${code}/accept-gift`,

        short: {
          partial: ':code/g',
          full: 'i/:code/g',
          buildFull: (code: string) => `/i/${code}/g`,
        }
      },

      download: {
        partial: 'download',
        full: 'invites/download'
      },



    },
    account: {
      root: 'account',
      full: 'account',

      login: {
        full: 'account/login',
        partial: 'login'
      },
      delete: {
        full: 'account/delete',
        partial: 'delete'
      },
      action: {
        full: 'account/action',
        partial: 'action'
      },

      logout: {
        partial: 'logout',
        full: 'account/logout'
      },

      welcome: {
        full: 'account/welcome',
        partial: 'welcome'
      },

      invites: {
        root: 'invites',
        full: 'account/invites',
        partial: '',

        download: {
          partial: 'download',
          full: 'account/invites/download'
        },
        login: {
          partial: 'login',
          full: 'account/invites/login'
        },
        accept: {
          partial: 'accept',
          full: 'account/invites/accept'
        },
        acceptGroup: {
          partial: ':inviteCode/join-group',
          full: 'account/invites/:inviteCode/join-group',

          buildFull: (inviteCode) => `/account/invites/${inviteCode}/join-group`
        }
      },

      register: {
        root: 'register',

        partial: '',
        full: 'account/register',

        login: {
          full: 'account/register/login',
          relative: 'login'
        },
        plans: {
          full: 'account/register/plans',
          relative: 'plans'
        },
        payment: {
          full: 'account/register/payment',
          relative: 'payment'
        },
        gettingStarted: {
          full: 'account/register/getting-started',
          relative: 'getting-started'
        }
      }
    },


    register: {
      root: 'register',
      full: 'register',

      login: {
        full: 'register/login',
        relative: 'login'
      },
      plans: {
        full: 'register/plans',
        relative: 'plans'
      },
      payment: {
        full: 'register/payment',
        relative: 'payment'
      },
      gettingStarted: {
        full: 'register/getting-started',
        relative: 'getting-started'
      },

      groups: {
        relative: 'groups',
        full: 'register/groups',
        login: {
          full: 'register/groups/login',
          relative: 'login'
        },
        plans: {
          full: 'register/groups/plans',
          relative: 'plans'
        },
        payment: {
          full: 'register/groups/payment',
          relative: 'payment'
        },
        gettingStarted: {
          full: 'register/groups/getting-started',
          relative: 'getting-started'
        }
      }


    },
    give: {
      root: 'give',
      full: 'give',
      relative: '',

      login: {
        full: 'give/login',
        relative: 'login'
      },
      checkout: {
        full: 'give/checkout',
        relative: 'checkout'
      },
      instructions: {
        full: 'give/instructions',
        relative: 'instructions'
      },
    },
    partners: {
      root: 'partners',
      full: 'partners',

      alertResponse: {
        full: 'partners/:partnerId/users/:userId/alerts/:alertId/respond',
        relative: ':partnerId/users/:userId/alerts/:alertId/respond',

        buildFull: (partnerId: string, userId: string, alertId: string) => `/partners/${partnerId}/users/${userId}/alerts/${alertId}/respond`
      },

      unsubscribe: {
        full: 'partners/:partnerId/users/:userId/unsubscribe',
        relative: ':partnerId/users/:userId/unsubscribe',

        buildFull: (partnerId: string, userId: string) => `${partnerId}/users/${userId}/unsubscribe`
      }
    },
    settings: {
      root: 'settings',
      full: 'settings',

      teams: {
        full: 'settings/teams',
        relative: 'teams',
      },
      billing: {
        full: 'settings/billing',
        relative: 'billing',


        groupSettings: {
          full: 'settings/billing/:subscriptionId',
          relative: 'billing/:subscriptionId',

          buildFull: (subscriptionId: string) => `settings/billing/${subscriptionId}`
        },
      },
      alertSettings: {
        full: 'settings/alert-settings',
        relative: 'alert-settings',
      },
      reports: {
        full: 'settings/reports',
        relative: 'reports',
      }
    },

    response: {
      /** The Full path to the response page */
      full: `:userId/alerts/:alertId/response/:partnerId`,
      build: (userId: string, alertId: string, partnerId: string) => `${userId}/alert/${alertId}/response/${partnerId}`
    },


  },

  downloads: {
    root: 'download',
    full: 'download',

    android: {
      full: '/download/android',
      relative: 'android'
    },

    windows: {
      full: '/download/windows',
      relative: 'windows'
    },

    ios: {
      full: '/download/ios',
      relative: 'ios'
    },

    mac: {
      full: '/download/mac',
      relative: 'mac'
    },

    chrome: {
      full: '/download/chrome',
      relative: 'chrome'
    }
  }


}
