import { Injectable } from '@angular/core';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FirestoreDatabase, FirestoreDocument } from '@triggered/firestore';
import { ApiService, CacheProvider } from '@triggered/core';
import { FilterOperation, IPlan, ISubscription, Paths, Plan, PlanType, Subscription, SubscriptionPricing, UserStripeSettings } from '@triggered/common';
import { AuthenticationService } from './authentication.service';
import { SubscriptionSettingsId, SubscriptionSettingsIds } from '../models/subscription-settings.model';
import { SubscriptionPlanModel } from '../models/subscription-plan.model';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  /** UserSettings cached in memory */
  private readonly activeUserSubscriptions: {[userId: string]: BehaviorSubject<Subscription[]> } = {};
  private readonly subscriptionPricing: {[subscriptionId: string]: BehaviorSubject<SubscriptionPricing> } = {};
  private readonly subscriptionPlanDetails: {[subscriptionId: string]: BehaviorSubject<SubscriptionPlanModel> } = {};
  private readonly userStripeSettings: {[userId: string]: FirestoreDocument<UserStripeSettings> } = {};


  constructor(
    private userProvider: AuthenticationService,
    private readonly database: FirestoreDatabase,
    private readonly apiService: ApiService) { }

  getStripeSettings$() {
    return this.userProvider.user$.pipe(
      map(user => user ? this.getUserStripeSettings(user.id) : null),
      shareReplay(1)
    );
  }

  getUserStripeSettings(userId: string) {
    return CacheProvider.getCacheOrAdd(this.userStripeSettings, userId, () => {
      return this.database.getDocument<UserStripeSettings>(Paths.collectionPath(userId), Paths.documentId, UserStripeSettings);
    });
  }


  getPlans$(planFilters?: { planType?: PlanType }, includeInactive: boolean = false) {
    const filters = [  ];
    if(!includeInactive) {
      filters.push(new FilterOperation<IPlan>('isActive', '==', true));
    }
    if(planFilters?.planType) {
      filters.push(new FilterOperation<IPlan>('type', '==', planFilters.planType));
    }
    const planCollection = this.database.getCollection<Plan>('plans', Plan);
    return planCollection.query$({ filters });
  }

  getPlan$(planId: string) {
    // TODO: Filter active plans
    const planDocument = this.database.getDocument<Plan>('plans', planId, Plan);
    return planDocument.data$;
  }

  getSubscription$(subscriptionId: string) {
    const subscription = this.database.getDocument<Subscription>('subscriptions', subscriptionId, Subscription );
    return subscription.data$;
  }

  getUserSubscriptions$(userId: string, activeOnly: boolean) {
    if(activeOnly) {
      return this.getCachedActiveUserSubscriptions$(userId);
    }
    return this.getCachedUserSubscriptions$(userId);
  }

  getMySubscriptions$(onlyActive?: boolean): Observable<Subscription[]> {
    return this.userProvider.user$.pipe(
      switchMap(user => {
        if(!user?.id) {
          console.log('No user id')
          return of([]);
        }
        return this.getUserSubscriptions$(user.id, onlyActive);
      })
    );
  }

  hasActiveSubscription$() {
    const types: PlanType[] = ['Group', 'Group-Member', 'Individual']; // Group owners also should have access for testing purposes
    return this.getMySubscriptions$(true).pipe(
      map(subscriptions => (subscriptions || []).filter(subscription => types.includes(subscription.planType)).length > 0)
    );
  }

  userIsRegistered$(userId: string) {
    return this.getUserSubscriptions$(userId, true).pipe(
      switchMap(subscriptions => this.subscriptionsHaveAccess$(subscriptions))
    );
  }

  subscriptionsHaveAccess$(subscriptions: Subscription[]) {
    // If user has an active individual subscription, use that.
    const isIndividual = (subscriptions || []).some(sub => sub.isIndividual || sub.isGroupMember);
    if(isIndividual) { return of(true); }

    // Check if user is a group admin
    return this.hasFreeGroupAdminAccess$(subscriptions);
  }

  hasFreeGroupAdminAccess$(subscriptions: Subscription[]) {
    // Check if user is a group admin
    const groupSubscription = (subscriptions || []).find(sub => sub.planType === 'Group');
    if(groupSubscription) {
      return this.getPlanDetails$(groupSubscription).pipe(
        map(details => details?.hasFreeAdminAccount === true)
      );
    }

    return of(false);
  }

  async addOrUpdate(plan: IPlan) {
    const planDocument = this.database.getDocument('plans', plan.id, Plan);
    return await planDocument.updateValue(plan);
  }

  getPricing$(subscription: Subscription): Observable<SubscriptionPricing> {
    return this.getSubscriptionSettings$(subscription, SubscriptionSettingsIds.pricing, this.subscriptionPricing).pipe(
      map(settings => settings ? new SubscriptionPricing(settings) : null)
    );
  }

  getPlanDetails$(subscription: Subscription) {
    return this.getSubscriptionSettings$(subscription, SubscriptionSettingsIds.plan, this.subscriptionPlanDetails).pipe(
      map(settings => settings ? new SubscriptionPlanModel(settings) : null)
    );
  }

  private getSubscriptionSettings$(subscription: Subscription, settingsId: SubscriptionSettingsId, cache: {[key: string]: BehaviorSubject<any>;}) {
    if(!subscription?.id) { return of(null); }

    // const settingsDocument = this.database

    return CacheProvider.getCachedOrQuery(cache, subscription.id, () => {
      return this.userProvider.user$.pipe(
        switchMap(user => {
          // Don't allow user to access the settings if they are not the owner
          if(user?.id !== subscription.ownerId) { return of(null); }

          // They are the owner, so request the plan details
          const document = this.database.getDocument(`subscriptions/${subscription.id}/settings`, settingsId);
          return document.data$;
        })
      );
    });
  }

  async cancelSubscription(subscription: ISubscription) {
    const route = `api/subscriptions/${subscription.id}`;
    await this.apiService.delete(route);
  }

  private getCachedActiveUserSubscriptions$(userId: string) {
    return CacheProvider.getCachedOrQuery(this.activeUserSubscriptions, userId, () => {
      return this.getCachedUserSubscriptions$(userId).pipe(
        map(subscriptions => (subscriptions || []).filter(sub => sub.isActive))
      );
    });
  }

  private getCachedUserSubscriptions$(userId: string) {
    const filters = [ new FilterOperation<Subscription>('ownerId', '==', userId) ];
    const subscriptions = this.database.getCollection<Subscription>('subscriptions', Subscription);
    return subscriptions.query$({filters});
  }
}
