export class UnauthorizedException extends Error {
  isCustom = true;

  /** Status Code: Unauthorized (401) */
  statusCode = 401;

  constructor(readonly message: string, readonly data?: unknown) {
    super(message);
  }
}

export class NotFoundException extends Error {
  isCustom = true;

  /** Status Code: Not Found (404) */
  statusCode = 404;
  constructor(readonly message: string, readonly data?: unknown) {
    super(message);
  }
}
export class BadRequestException extends Error {
  isCustom = true;

  /** Status Code: BadRequest (400) */
  statusCode = 400;
  constructor(readonly message: string, readonly data?: unknown) {
    super(message);
  }
}


export class ValidationException extends Error {
  isCustom = true;

  /** Status Code: Unprocessable Entity (422) */
  statusCode = 422;
  constructor(readonly message: string, readonly data?: unknown) {
    super(message);
  }
}

export class UnknownError extends Error {
  isCustom = true;

  /** Status Code: Internal server error (500) */
  statusCode = 500;
  constructor(readonly message: string, readonly data?: unknown) {
    super(message);
  }
}
