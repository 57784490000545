export class CollectionPath<TDocPaths = any, TCollections extends {[collectionId: string]: CollectionPath<any>} = {}> {
  path = (id?: keyof TDocPaths) => { return id ? `${this.collectionPath}/${id}` : this.collectionPath;  }

  doc = (id: Extract<keyof TDocPaths, string>) => {
    const cloned: any = {};
    const childCollections = (this.collections || {}) as TCollections;
    for (const key in childCollections) {
      if (Object.prototype.hasOwnProperty.call(childCollections, key)) {
        const childCollection = childCollections[key];
        cloned[key] = new CollectionPath(`${this.path(id)}/${childCollection.path()}`, childCollection.collections)
      }
    }
    return cloned as TCollections;
  }

  constructor(private readonly collectionPath: string, private readonly collections?: TCollections) {
  }

  toString(): string {
    return this.path();
  }
}
