// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
const angularModules = [ CommonModule, FlexLayoutModule ];

// Triggered Modules
import { TriggeredCheckboxModule, TriggeredDialogModule, TriggeredFormButtonsModule, TriggeredFormCardModule, TriggeredTextareaModule, TriggeredTextboxMaskModule, TriggeredTextboxModule } from '@triggered/ui'
const triggeredModules = [
  TriggeredDialogModule, TriggeredTextboxMaskModule,
  TriggeredTextboxModule, TriggeredTextareaModule,
  TriggeredFormButtonsModule, TriggeredCheckboxModule, TriggeredFormCardModule
];

// Contact Modules
import { ContactCoreModule } from '../core/core.module';
import { ContactServicesModule } from '../services/services.module';
const contactModules = [ ContactCoreModule, ContactServicesModule ];

// Contact Components
import { ContactRequestFormComponent } from './contact-request/contact-request.form';
import { SendgridForm } from './sendgrid/sendgrid.form';
import { RequestInfoForm } from './request-info/request-info.form';
const externalComponents = [ ContactRequestFormComponent, SendgridForm, RequestInfoForm ];

@NgModule({
  declarations: [...externalComponents ],
  exports: [ ...externalComponents ],
  imports: [
    ...angularModules,
    ...triggeredModules,
    ...contactModules
  ]
})
export class ContactComponentsModule { }
