<form *ngIf="form" [formGroup]="form" (ngSubmit)="onResetPassword()">
  <triggered-card fxFlex="0 1 400px" title="Reset Password" subtitle="Enter your new password">

    <ng-template #content>
      <triggered-password [readonly]="isSubmitting" name="new-password" [formControl]="form.getControl('password')" label="Password" placeholder="Password"></triggered-password>
      <triggered-password [readonly]="isSubmitting" name="new-password" [formControl]="form.getControl('passwordConfirmation')" label="Confirm Password" placeholder="Confirm Password"></triggered-password>
    </ng-template>

    <ng-template #actions>
      <triggered-form-buttons [isSaving]="isSubmitting" saveText="Update" cancelText="Cancel" savingText="Updating..." (cancelled)="onCancel()"></triggered-form-buttons>
    </ng-template>
  </triggered-card>
</form>
