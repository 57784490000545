import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FirebaseOptions, FIREBASE_OPTIONS } from '@angular/fire';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import { AppConfig } from '../config';
import { APP_CONFIG } from '../tokens';
import { EventLoggingService } from './event-logging.service';

export class ErrorReporter {
  private static errorHandler: StackdriverErrorReporter;
  private static useStackDriver: boolean;

  static configure(firebaseConfig: FirebaseOptions, @Inject(APP_CONFIG) appConfig: AppConfig, platformId: any) {
    if(!isPlatformBrowser(platformId) ) {
      console.log('Cannot report errors on server');
      return;
    }

    this.useStackDriver = appConfig.reportErrors ?? appConfig.production;
    if(this.errorHandler) {
      console.log('Error handler is already configured');
      return;
    }

    try {
      this.errorHandler = new StackdriverErrorReporter();
      this.errorHandler.start({
          key: firebaseConfig?.apiKey,
          projectId: firebaseConfig?.projectId,
          service: appConfig?.isInstalled && appConfig?.isAndroid
            ? 'android'
            : appConfig?.isInstalled && appConfig?.isIOS
              ? 'iOS'
              : 'web'
      });
    } catch(e) {
      console.log('Cannot register Stack Driver Error Reporter on server');
      this.errorHandler = null;
    }
  }

  static report(error: Error, data?: any) {

    try {
      if(this.errorHandler && this.useStackDriver) {
        this.errorHandler?.report(error);
      }
    } catch(e) { /** Do nothing. Fail gracefully */ }
    finally {
      console.error(`Logging error: ` + error?.message + JSON.stringify(error), data);
    }


    try {
      const payload: any = Object.assign({}, data, { error });
      EventLoggingService.logEvent('error', payload);
    } catch(e) { /** Do nothing. Fail gracefully */ }
  }

}

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {

  constructor(@Inject(PLATFORM_ID) platformId, @Inject(FIREBASE_OPTIONS) public firebaseConfig: FirebaseOptions, @Inject(APP_CONFIG) private readonly appConfig: AppConfig) {
    super();
    ErrorReporter.configure(firebaseConfig, appConfig, platformId);
  }

  handleError(error: Error) {

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if(chunkFailedMessage?.test(error?.message)) {
      try {
        if(window?.location) {
          window?.location.reload();
          EventLoggingService?.logEvent('error', { message: 'Chunk load error, attempting to reload.'});
          console.warn(`Chunk failed to load. Reloading. `, error.message)
          return;
        }
      } catch(error) { /** Do nothing */}
    }

    try {
      ErrorReporter?.report(error);

      if(!this.appConfig.production) {
        super.handleError(error);
      }
    } catch(e) { /** Do nothing. Fail gracefully */ }
  }
}
