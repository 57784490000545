import { Attribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { Masks } from '@triggered/ui';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { User, UserForm } from '../../core/models/user.model';
import { AuthenticationService } from '../../core/services/authentication.service';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'triggered-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {
  readonly phoneMask = Masks.phoneNumber;
  readonly form$: Observable<UserForm>;

  @Input() hideHeader: boolean;

  @Output() readonly loggedOut = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();
  @Output() readonly saved = new EventEmitter<User>();

  constructor(
    @Attribute('saveText') readonly saveText: string,
    @Attribute('cancelText') readonly cancelText: string,
    @Attribute('logoutText') readonly logoutText: string,
    private authService: AuthenticationService,
    private userService: UserService) {

    this.saveText = this.saveText || 'Save';
    this.cancelText = this.cancelText || 'Cancel';
    this.logoutText = this.logoutText || 'Log Out';

    this.form$ = this.authService.user$.pipe(
      // If user is null, don't initialize a form
      map(user => user ? new UserForm(user) : null),
      tap(userForm => {
        userForm?.getControl('firstName').setValidators([Validators.required]);
        userForm?.getControl('lastName').setValidators([Validators.required])
      })
    );
  }

  ngOnInit(): void {
  }

  async onSave(form: UserForm) {
    if(form.invalid) { return form.markAllAsDirty(); }

    if(form.dirty) {
      await this.userService.updateUser(form);
    }

    this.saved.emit(new User(form.value));
  }

  async onCancel(form: UserForm) {
    form.reset();
    this.cancelled.emit();
  }

  async onLogout() {
    await this.authService.logout();
    // Wait for log out to finish
    await this.authService.user$.pipe(
      filter(user => user == null),
      take(1)
    ).toPromise();
    this.loggedOut.emit();
  }

}
