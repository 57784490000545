import { Injectable } from '@angular/core';
import { ToasterServicesModule } from './services.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { ErrorReporter } from '@triggered/core';

@Injectable({
  providedIn: ToasterServicesModule
})
export class ToasterService {
  private static instance: ToasterService;

  constructor(private readonly snackbar: MatSnackBar) {
    ToasterService.instance = ToasterService.instance || this;
  }

  static async open(message: string, dismissText?: string, type?: 'info' | 'warn' | 'error') {
    if(this.instance == null) {
      ErrorReporter.report(new Error('Cannot display toaster. Toaster not initialized yet.'), {message, dismissText});
      return;
    }
    this.instance.open(message, dismissText, type)
  }

  async open(message: string, dismissText?: string, type?: 'info' | 'warn' | 'error') {
    const ref = this.snackbar.open(message, dismissText, {
      panelClass: this.getClass(type),
      duration: ['warn', 'error'].includes(type) ? 10000 : 5000,
    });
    return ref.afterDismissed().pipe(map(info => info.dismissedByAction === true)).toPromise();
  }

  private getClass(type: 'info' | 'warn' | 'error' = 'info') {
    switch(type) {
      case 'warn':
      case 'error': return ['bg-primary'];
      case 'info':
      default: return ['bg-card', 'text-primary'];
    }
  }

}
