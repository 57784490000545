import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen, switchMap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let retryCount = 0;
    return next.handle(request).pipe(
      retryWhen(errors => errors.pipe(
        switchMap(async (error) => {
          retryCount++;

          if(error instanceof HttpErrorResponse) {
            // Attempt the cheapest first
            const shouldRetry = await this.retryIconLoadFailure(request, error, retryCount);
            if(shouldRetry === true) { return true; }
          }

          throw error;
        }))
      ),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }

  private async retryIconLoadFailure(request: HttpRequest<any>, error: HttpErrorResponse, retryCount: number) {
    const shouldRetry = error.status === 404 && error?.url?.indexOf('assets/icons/') >= 0;
    if(!shouldRetry) { return false; }

    if(retryCount > 1) { throw new Error(`Tried to loading icon multiple times but failed: ${error.message}`); }

    // Attempt a pause
    await new Promise(resolve => setTimeout(resolve, 100));
    return true;
  }
}
