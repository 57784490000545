import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Form } from '../../../data/form';
import { EventLoggingService } from '../../../services';
import { BASE_API_ROUTE } from '../../../tokens';

export class SuccessResponse<TValue = any> {
  readonly status = 'success';
  constructor(readonly value: TValue) {}
}

export class FailedResponse<TValue = any> {
  readonly status = 'failure';
  constructor(readonly error: TValue) {}
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private readonly http: HttpClient,
    @Optional() @Inject(BASE_API_ROUTE) readonly baseUrl: string) {

    this.baseUrl = (this.baseUrl || '').trim();
    if(this.baseUrl && !this.baseUrl.endsWith('/')) {
      this.baseUrl = `${this.baseUrl}/`;
    }
  }

  get<TModel = any>(path: string, params: any = {}): Promise<TModel> {
    return this.get$<TModel>(path, params).pipe(take(1)).toPromise();
  }

  get$<TModel = any>(path: string, params: any = {}): Observable<TModel> {
    const httpParams = new HttpParams({fromObject: this.buildParams(params || {})});
    return this.http.get<TModel>(this.buildUrl(path), { params: httpParams });
  }

  async put<TModel = any>(path: string, form: Form<TModel>): Promise<TModel> {
    if(form.invalid) {
      EventLoggingService.logEvent('warning', {
        message: 'Attempting to put an invalid form',
        form: form.value
      });
    }
    form.isSaving = true;
    return this.http.put<TModel>(this.buildUrl(path), form.value)
      .pipe(take(1))
      .toPromise()
      .finally(() => form.isSaving = false);
  }

  async postValue<TModel = any>(path: string, body: object = {}): Promise<TModel> {
    return await this.http.post<TModel>(this.buildUrl(path), body)
      .pipe(take(1))
      .toPromise();
  }

  async post<TModel = any>(path: string, form: Form<TModel>): Promise<TModel> {
    if(form.invalid) {
      EventLoggingService.logEvent('warning', {
        message: 'Attempting to post an invalid form',
        form: form.value
      });
    }
    form.isSaving = true;
    return await this.postValue<TModel>(path, form.value)
      .finally(() => form.isSaving = false);
  }

  async patch<TModel = any>(path: string, body: object = {}): Promise<TModel> {
    return await this.http.patch<TModel>(this.buildUrl(path), body).pipe(take(1)).toPromise();
  }

  async delete<TModel = any>(path: string, parameters: any = {}): Promise<TModel> {
    const params = new HttpParams({fromObject: parameters});
    return await this.http.delete<TModel>(this.buildUrl(path), { params }).pipe(take(1)).toPromise()
      // .catch((error: HttpErrorResponse) => {
      // });
  }

  /**
   * Builds a full url based on a relative path
   * @param paramObjects
   * @returns
   */
  private buildUrl(relativePath: string) {
    // Combine the baseUrl with the relative path. Remove leading forward slash if it exists on a relative path
    // https://stackoverflow.com/a/2182602/1529214
    return this.baseUrl + relativePath.replace(/^\//, '');
  }

  /**
   * Combines multiple param objects into a array of objects and returns the non-nullable properties
   */
  private buildParams(paramObjects: any | any[]) {
    const paramArray = paramObjects instanceof Array ? paramObjects : [paramObjects];

    // Combine all parameters and only return the values that are defined (i.e. - not null)
    let allParams = Object.assign({}, ...paramArray);
    // @ts-ignore
    allParams = Object.entries(allParams)
      .reduce((combined, [name, value]) => {
        if(value){
          combined[name] = value;
        }
        return combined;
      }, {});
    return allParams;
  }

  static formatError(response: HttpErrorResponse) {
    const error = response.error;
    const customError = response.headers.get('custom-error');
    if (response.status === 0 || error instanceof ProgressEvent) {
      return 'An unknown network error occurred.';
    }
    if (response.status === 401) {
      return 'Unauthorized. You may need to relogin and try again.'
    }
    if (response.status === 404 && !customError) {
      return 'Could not communicate with server.';
    }
    return customError;
  }
}
