<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div fxLayout.lt-md="column" fxLayout="row" fxLayoutGap="2em">
    <div fxFlex.gt-sm="0 1 600px" fxLayout="column"  fxFlexOrder.lt-md="1">
      <!-- First and Last Name -->
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="1em">
        <triggered-textbox fxFlex="1 1 auto" name="given-name" label="First Name" placeholder="First Name" [formControl]="form.getControl('firstName')"></triggered-textbox>
        <triggered-textbox fxFlex="1 1 auto" name="family-name" label="Last Name" placeholder="Last Name" [formControl]="form.getControl('lastName')"></triggered-textbox>
      </div>

      <!-- Email and Phone -->
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="1em">
        <triggered-textbox type="email" name="email" label="Email" placeholder="Email" [formControl]="form.getControl('email')"></triggered-textbox>
        <triggered-textbox-mask name="tel-national" label="Phone" placeholder="Phone" [formControl]="form.getControl('phone')" [textMask]="phoneMask.mask" [patterns]="phoneMask.pattern"></triggered-textbox-mask>
      </div>


      <!-- Message -->
      <triggered-textarea label="Message" formControlName="message" [minRows]="3" placeholder="How else may we help you?"></triggered-textarea>

      <!-- Form Buttons -->
      <triggered-form-buttons [showCancel]="false" [isSaving]="isSubmitting" saveText="Send" savingText="Sending..."></triggered-form-buttons>
    </div>

    <div fxFlex.gt-sm="300px" fxLayout="column" fxFlexOrder.lt-md="0">
      <span class="mat-body-1 margin-0"><b>Check additional interests:</b></span>
      <triggered-checkbox [formControl]="form.getControl('selectedAbout')" label="About us"></triggered-checkbox>
      <triggered-checkbox [formControl]="form.getControl('selectedPartnerships')" label="Partnerships"></triggered-checkbox>
      <triggered-checkbox [formControl]="form.getControl('selectedGroupRates')" label="Group Rates"></triggered-checkbox>
      <triggered-checkbox [formControl]="form.getControl('selectedIndividualRates')" label="Individual Rates"></triggered-checkbox>
      <triggered-checkbox [formControl]="form.getControl('selectedMoreResources')" label="Brochures and Resources"></triggered-checkbox>


    </div>
  </div>



</form>
