import { Directive, EventEmitter, HostListener, ElementRef, Output } from '@angular/core';

@Directive({
  selector: '[solidClickOutside]'
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {
  }

  @Output('solidClickOutside')
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) { return;  }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
        this.clickOutside.emit(event);
    }
  }
}
