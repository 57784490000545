import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UIDProvider } from '@triggered/core';

@Injectable()
export class FirestoreUIDProvider implements UIDProvider  {
  constructor(private db: AngularFirestore) {}

  createUID(): string {
    return this.db.createId();
  }
}
