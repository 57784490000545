import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotFoundException } from '../core/exceptions';
import { DocumentClient } from './document-client';
import { Form } from './form';
import { QueryOptions } from './queries';
import { IRecord } from './record';


type RecordFn<TRecord> = new (value?: Partial<TRecord>) => TRecord;

export class DocumentOptions<TRecord> {
  constructor(readonly recordFn?: RecordFn<TRecord>){}
}

export abstract class Document<TRecord extends IRecord> {
  abstract get data$(): Observable<TRecord | undefined>;
  abstract get isLoading$(): Observable<boolean>

  get id() { return this._id; }
  get collectionPath(): string { return this._collectionPath; }
  get fullPath(): string { return `${this._collectionPath}/${this.id}`; }
  get status() { return this.database && this.id ? 'attached' : 'unattached'; }

  constructor(protected _id: string, protected _collectionPath: string, protected readonly database?: DocumentClient) {
    // Removing trailing and duplicate slashes from collection path
    const collectionPath = this._collectionPath.replace(/\/\//g, '/');
    this._collectionPath = collectionPath.endsWith('/') ? collectionPath.slice(0, -1) : collectionPath;
  }

  async data(): Promise<TRecord | undefined> {
    return await this.data$.pipe(take(1)).toPromise();
  }

  async assertData(errorMessage?: string, errorData?: any): Promise<TRecord> {
    const data = await this.data();
    if (!data) { throw new NotFoundException(errorMessage || `Requested data not found`, errorData);  }
    return data;
  }

  async update(form: Form<TRecord>): Promise<Partial<TRecord>> {
    form.isSaving = true;
    return await this.updateValue(form.value)
      .finally(() => form.isSaving = false);
  }

  async updateValue(values: Partial<TRecord>, options?: {forceCommit: boolean}): Promise<Partial<TRecord>> {

    if(!this.database) { throw new Error('Document: Cannot update! Database not attached.'); }

    const value = this.toSimpleObject(values);
    if(!this.id) {
      const added = await this.database.add<TRecord>(this.collectionPath, value, options);
      return added;
    } else {
      return await this.database.update<TRecord>(`${this.collectionPath}/${this.id}`, value, options);
    }
  }


  getChild<TChildRecord extends IRecord>(collectionPath: string, documentId: string, options?: DocumentOptions<TChildRecord>): Document<TChildRecord> {
    if(!this.database) { throw new Error('Document: Cannot update! Database not attached.'); }
    const childCollectionPath = `${this.collectionPath}/${this.id}/${collectionPath}`;
    return this.database.getDocument(childCollectionPath, documentId, options);
  }

  getChildren<TChildRecord extends IRecord>(collectionPath: string, query?: QueryOptions<TChildRecord>) {
    if(!this.database) { throw new Error('Document: Cannot update! Database not attached.'); }
    const childCollectionPath = `${this.collectionPath}/${this.id}/${collectionPath}`;
    return this.database.getCollection(childCollectionPath, query);
  }

  protected toSimpleObject(values: Partial<TRecord>) {
    const value = JSON.parse(JSON.stringify(values));
    return value;
  }
}
