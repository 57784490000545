import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platforms } from '@ionic/core';
import { Platform as IonicPlatform } from '@ionic/angular';
import { AppConfig, ErrorReporter } from '@triggered/core';
import { isPlatformBrowser } from '@angular/common';

/**
 * Configuration found: https://betterprogramming.pub/how-to-handle-async-providers-in-angular-51884647366
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private static _appConfig: Readonly<AppConfig>;

  static get appConfig(): Readonly<AppConfig> {
    if(ConfigService._appConfig == null) {
      throw new Error('Configuration has not been initialized');
    }
    return this._appConfig;
  }

  constructor(private platform: IonicPlatform, @Inject(PLATFORM_ID) readonly platformId: Object) { }

  configureApp(appConfig: Partial<AppConfig>) {

    if(ConfigService._appConfig) {
      return ConfigService._appConfig;
    }

    console.log('ConfigService: Configuring App');
    const config: AppConfig = JSON.parse(JSON.stringify(appConfig));

    try{
      // Check if the app is installed or is a web browser
      config.isInstalled = this.isInstalledApp();

      // Check if the app is installed or is a web browser
      config.isInstalled = this.isInstalledApp();
      config.isAndroid = this.isPlatform(['android']);
      config.isIOS = this.isPlatform(['ios']);
      config.isMobile = this.isPlatform(['mobile']);
      config.isDesktop = this.isPlatform(['desktop']);
      config.isTablet = this.isPlatform(['tablet']);
      config.deviceSize = config.isMobile
        ? 'mobile'
        : config.isTablet
          ? 'tablet'
          : config.isDesktop
            ? 'desktop'
            : 'unknown';
      config.platform = config.isIOS ? 'iOS' : config.isAndroid ? 'Android' : 'Web';


      const useEmulator = appConfig.useEmulator && appConfig.production !== true;
      config.useEmulator = useEmulator;


      // Set the appropriate endpoints
      config.endpoints = {
        baseApiUrl: config.endpoints.baseApiUrl,
        baseAppUrl: config.endpoints.baseAppUrl,
        baseCorporateUrl: config.endpoints.baseCorporateUrl
      };
    }
    catch(e){
      console.error('ConfigService: Error: ' + e.toString());
    }

    ConfigService._appConfig = Object.freeze(config);
    return ConfigService._appConfig;
  }

  private isServer() {
    const isServer = !isPlatformBrowser(this.platformId);
    return isServer;
  }

  private isPlatform(platforms: Platforms[]) {
    if(this.isServer()) { return false; }
    return platforms.some(device => this.platform.is(device))
  }


  private isInstalledApp() {
    try {
      if(this.isServer()) { return false; }
      if(this.platform.is('mobileweb')) { return false; }
      return this.isPlatform(['ipad', 'ios', 'iphone', 'android']);
    } catch(error) {
      ErrorReporter.report(error, { message: `ConfigService: Error checking if the app is isntalled` });
      return false;
    }
  }
}
