// Angular Modules
import { Injector, ModuleWithProviders, NgModule, forwardRef, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
const angularModules = [ CommonModule ];

// Internal Directives
// TODO: Refine these and eliminate the ones that are not necessary
import { DeviceDirective } from './directives/device.directive';
import { DeviceSizeDirective } from './directives/device-size.directive';
import { SEODirective } from './directives/seo.directive';
import { SmsLinkPipe } from './pipes/sms-link.pipe';
import { PhoneLinkPipe } from './pipes/phone-link.pipe';
const coreDirectives = [ DeviceDirective, DeviceSizeDirective, SEODirective ];
const corePipes = [ SmsLinkPipe, PhoneLinkPipe ];

// Triggered Core Services
import { MetaTagService } from './services/meta-tag.service';
import { CustomDrawerScrollService } from './services/custom-drawer-scroll.service';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { EventLoggingService } from './services/event-logging.service';
import { AppConfig } from '..';

import { WebEventLogger } from './logging/web-event-logger';
import { AppEventLogger } from './logging/app-event-logger';
import { EventLogger } from './logging/event-logger';
import { APP_CONFIG } from './tokens';

function eventLoggerFactory(injector: Injector, appConfig: Partial<AppConfig>): EventLogger {
  const isAppLoggerEnabled = true;
  let logger: EventLogger;
  if (isAppLoggerEnabled && appConfig?.isIOS && appConfig?.isInstalled) {
    console.log('LoggerFactory: Using AppEventLogger for iOS');
    logger = injector.get(AppEventLogger);
  } else {
    console.log('LoggerFactory: Using WebEventLogger');
    logger = injector.get(WebEventLogger);
  }
  return logger;
}

// TODO: Ensure this only gets imported once
@NgModule({
  declarations: [...coreDirectives, ...corePipes ],
  exports: [...coreDirectives, ...corePipes],
  imports: [
    ...angularModules
  ],
  providers: [
    CustomDrawerScrollService,
    WebEventLogger,
    AppEventLogger
  ]
})
export class TriggeredCoreModule {
  constructor(_loggingService: EventLoggingService) {
  }

  static forRoot(): ModuleWithProviders<TriggeredCoreModule> {
    return {
      ngModule: TriggeredCoreModule,
      providers: [
        MetaTagService,
        {
          // processes all errors
          provide: ErrorHandler,
          useClass: GlobalErrorHandlerService
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptorService,
          multi: true
        },

        {
          provide: EventLogger,
          useFactory: eventLoggerFactory,
          deps: [ Injector, forwardRef( () => APP_CONFIG) ]
        }
      ]
    };
  }
}
