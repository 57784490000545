import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DocumentClient, DocumentOptions, IRecord, QueryHelper, QueryOptions, RecordFn } from '@triggered/common';
import { CacheProvider, ErrorReporter, NetworkProvider } from '@triggered/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { FirestoreCollection } from './firestore.collection';
import { FirestoreDocument, FirestoreQueryDocument } from './firestore.document';
import { CollectionQuery } from './firestore.query';
import { FirebaseHelper } from './firebase.helper';


/** TODO: MAKE PRIVATE! */
@Injectable({
  providedIn: 'root'
})
export class FirestoreDatabase implements DocumentClient {
  /** The default cache key for firestore objects */
  private static CacheKey = 'FIRESTORE';

  /** The default cache duration for firestore objects (2 minutes) */
  private static CacheDurationSeconds = 60 * 2;

  constructor(private cache: CacheProvider, private db: AngularFirestore, private readonly networkStatus: NetworkProvider) { }

  // getCollection<TRecord extends IRecord>(collectionPath: string, query?: QueryOptions<TRecord> ): FirestoreCollection<TRecord>;
  // getCollection<TRecord extends IRecord>(collectionPath: string, recordFn?: RecordFn<TRecord>): FirestoreCollection<TRecord>;
  getCollection<TRecord extends IRecord>(collectionPath: string, queryOrFn?: QueryOptions<TRecord> | RecordFn<TRecord>): FirestoreCollection<TRecord> {
    const query = typeof queryOrFn === 'function' ? { recordFn: queryOrFn } : queryOrFn;

    const path = collectionPath.replace(/\/\//g, '/');
    const cacheString = path + QueryHelper.toString(query);
    return this.cache.getOrAdd('FirestoreCollection', cacheString, () => {
      console.debug(`FirestoreDatabase: Getting collection: ${ cacheString }`);
      const queryProvider: CollectionQuery<TRecord> = (path, options) => this.queryCollection$<TRecord>(path, options);
      return new FirestoreCollection(path, queryProvider, query, this);
    }, FirestoreDatabase.CacheDurationSeconds);
  }

  document<TRecord extends IRecord>(path: string, optionsOrFn?: DocumentOptions<TRecord> | RecordFn<TRecord>): FirestoreDocument<TRecord> {
    const fullPath = path.replace(/\/\//g, '/');
    const { collectionPath, documentId } = FirebaseHelper.getPathParts(fullPath);
    return this.getDocument(collectionPath, documentId, optionsOrFn);
  }

  getDocument<TRecord extends IRecord>(
    collectionPath: string,
    id: string,
    optionsOrFn?: DocumentOptions<TRecord> | RecordFn<TRecord>): FirestoreDocument<TRecord> {
    const options = typeof optionsOrFn === 'function' ? { recordFn: optionsOrFn } : optionsOrFn;

    const documentPath = `${collectionPath}/${id}`.replace(/\/\//g, '/');
    return this.cache.getOrAdd('FirestoreDocument', documentPath, () => {
      console.debug(`FirestoreDatabase: Getting document: ${ documentPath }`);
      return new FirestoreDocument<TRecord>(collectionPath, id, this.queryDocument$(documentPath, options), this);
    });
  }



  async createDocument<T extends IRecord>(c: string, v: Partial<T>, o?: DocumentOptions<T>): Promise<FirestoreDocument<T>> {
    throw Error('Not implemented');
  }

  async add<TRecord extends IRecord>(collectionPath: string, values: Partial<TRecord>): Promise<Partial<TRecord>> {
    values.createdBy = values.updatedBy = 'app';
    values.createdAt = values.updatedAt = new Date();

    if(this.networkStatus.currentStatus === 'none') {
      // Do not await response. It will update when it comes back online
      this.db.collection(collectionPath).add(values);
      return values;
    }
    return await this.db.collection(collectionPath).add(values)
      .then((added) => {
        values.id = added.id;
        return values;
      })
      .catch(error => {
        ErrorReporter.report(new Error(`FirestoreClient: Error adding document to ${collectionPath}` + error?.message), { error, values });
        throw error;
      });
  }


  async update<TRecord extends IRecord>(documentPath: string, values: Partial<TRecord>): Promise<Partial<TRecord>> {
    values.updatedAt = new Date();
    values.updatedBy = 'app';

    if (this.networkStatus.currentStatus === 'none') {
      // Do not await response. It will update when it comes back online
      this.db.doc(documentPath).set(values, {merge: true});
      return values;
    }
    return await this.db.doc(documentPath).set(values, {merge: true})
      .then(() => values)
      .catch(error => {
        ErrorReporter.report(new Error(`FirestoreClient: Error updating document at ${documentPath}` + error?.message), { error, values });
        throw error;
      });
  }

  async deleteDocument(documentPath: string): Promise<void> {
    return await this.db.doc(documentPath).delete();
  }

  private queryDocument$<TRecord extends IRecord>(path: string, options?: DocumentOptions<TRecord>): Observable<TRecord> {
    const documentPath = path.replace(/\/\//g, '/')
    return this.cache.getOrAdd(FirestoreDatabase.CacheKey, path, () => {
      console.debug(`FirestoreDatabase: Querying document: ${ documentPath }`);
      return this.db.doc<TRecord>(documentPath).snapshotChanges().pipe(
        // Executing was firing twice due to enablePersistance.
        // https://github.com/angular/angularfire/issues/2808#issuecomment-830977609
        // https://github.com/angular/angularfire/issues/2336#issuecomment-791314096
        distinctUntilChanged(FirebaseHelper.distinctRecord),
        map(doc => FirebaseHelper.docToModel(doc.payload, options)),
        shareReplay(1)
      );
    });
  }

  private queryCollection$<TRecord extends IRecord>(path: string, options?: QueryOptions<TRecord>): Observable<FirestoreQueryDocument<TRecord>[]> {
    const collectionPath = path.replace(/\/\//g, '/');
    const cacheString = collectionPath + QueryHelper.toString(options);

    return this.cache.getOrAdd(FirestoreDatabase.CacheKey, cacheString, () => {
      console.debug(`FirestoreDatabase: Querying collection: ${ cacheString }`);

      return this.db.collection<TRecord>(collectionPath, ref => FirebaseHelper.buildQuery(ref, options)).snapshotChanges().pipe(
        // Executing was firing twice due to enablePersistance.
        // https://github.com/angular/angularfire/issues/2808#issuecomment-830977609
        // https://github.com/angular/angularfire/issues/2336#issuecomment-791314096
        distinctUntilChanged(FirebaseHelper.distinctRecords),
        map(changes => {
          return changes.map(change => new FirestoreQueryDocument<TRecord>(change, options, this));
        }),
        shareReplay(1)
      )}, FirestoreDatabase.CacheDurationSeconds);
  }
}
