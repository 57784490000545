
export class NotificationMenuItem<TModel = any> {
  id: string;
  icon?: string;
  title: string;
  subtitle: string;
  date: Date;
  routerLink?: string | any[];
  routerLinkActive?: string | string[];
  color?: 'primary' | 'accent';
  isNew?: boolean;

  isClearing?: boolean;

  /** Additional properties */
  model: TModel;
  [key: string]: any;

  constructor(menuItem?: NotificationMenuItem<TModel>) {
    Object.assign(this, menuItem);
  }
}
