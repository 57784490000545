import { Component, Input, OnInit } from '@angular/core';
import { TriggeredRoutes } from '../../../constants/routes';

@Component({
  selector: 'triggered-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  readonly routes = TriggeredRoutes;
  readonly socialMediaLinks  = [
    { name: 'LinkedIn', url: 'https://linkedin.com/company/triggered-app', icon: 'linked-in-1' },
    { name: 'Facebook', url: 'https://facebook.com/TriggeredApp', icon: 'facebook-1' },
    { name: 'Instagram', url: 'https://instagram.com/triggered_app', icon: 'instagram-1' },
  ];


  @Input()
  displayLinks: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
