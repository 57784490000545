<mat-form-field [appearance]="appearance" floatLabel="always" [class.margin-bottom-1]="hint != null">
  <mat-label>{{label}}</mat-label>
  <mat-select #select (blur)="onBlur()" [formControl]="formControl" [placeholder]="placeholder" (selectionChange)="onSelectionChanged($event)" [errorStateMatcher]="matcher" [panelClass]="panelClass">
    <mat-option *ngIf="placeholder && allowEmpty" [value]="">
      {{ placeholder }}
    </mat-option>
    <mat-option *ngFor="let option of options; let i = index" [value]="valuePath ? option[valuePath] : option">
      <ng-container *ngIf="select.panelOpen; else collapsed">
        <ng-container *ngIf="optionTemplate; else defaultOption" [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{option: option, index: i}"></ng-container>
      </ng-container>

      <ng-template #collapsed>
        <ng-container *ngIf="displayTemplate; else defaultOption" [ngTemplateOutlet]="displayTemplate" [ngTemplateOutletContext]="{option: option, index: i}"></ng-container>
      </ng-template>

      <ng-template #defaultOption>
        {{ displayPath ? option[displayPath] : option }}
      </ng-template>
    </mat-option>
  </mat-select>
  <mat-hint>{{hint}}</mat-hint>
  <mat-error [innerHTML]="errors"></mat-error>
</mat-form-field>
