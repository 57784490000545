// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
const angularModules = [ CommonModule, MatIconModule ];

// SOLID Modules
const solidModules = [ ]

// Triggered Modules
import { AuthenticationViewsRoutingModule } from './views-routing.module';
import { TriggeredLayoutModule } from '@triggered/core';
import { TriggeredDialogModule } from '@triggered/ui';
import { LoginView } from './login/login.view';
import { AuthenticationCoreModule } from '../core/core.module';
import { AuthenticationComponentsModule } from '../components/components.module';
const triggeredModules = [ AuthenticationCoreModule, AuthenticationComponentsModule, TriggeredLayoutModule, TriggeredDialogModule ]

// Internal Views

const internalView = [ LoginView ];

@NgModule({
  declarations: [...internalView],
  imports: [
    AuthenticationViewsRoutingModule, // Routing module must come first!
    ...angularModules,
    ...solidModules,
    ...triggeredModules
  ]
})
export class AuthenticationViewsModule { }
