

<div id="triggered-app" solidSEO solidDeviceSize class="mat-app-background app-container">
  <triggered-toolbar id="triggered-app-toolbar" [notificationRowClass]="'small'">

    <!-- <div fxHide.gt-xs fxFlex="0 0 40px"></div> -->
    <ng-template #notification>
      <div>
        <p class="mat-caption text-wrap text-center w-100">
          <b>Triggered has launched!</b> Sign up today<br>
        </p>
      </div>
    </ng-template>

    <!-- Logo: Display Always -->
    <a id="triggered-logo" [routerLink]="['']">
      <img height="44" width="133" src="assets/images/icons/triggered-logo.png" alt="Triggered logo">
    </a>

    <div fxHide.lt-sm fxLayout="row" fxLayoutAlign="end center">
      <ng-container *ngFor="let link of links$ | async" [ngSwitch]="link.type">
        <ng-container *ngIf="link.isVisible$ == null || (link.isVisible$ | async)">
          <ng-container *ngSwitchCase="'raised'">

            <a mat-raised-button color="primary" [routerLink]="[link.routerLink]">{{link.display}}</a>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <a mat-button color="primary" [routerLink]="[link.routerLink]">{{link.display}}</a>
          </ng-container>

        </ng-container>
      </ng-container>
    </div>


    <div fxHide.gt-xs>
      <button mat-icon-button color="primary" (click)="opened = !opened"  aria-label="Mobile Menu">
        <mat-icon>menu</mat-icon>
      </button>
    </div>


  </triggered-toolbar>

  <mat-drawer-container id="triggered-app-content">

    <!-- Sidenav container for mobile -->
    <mat-drawer id="triggered-sidenav" mode="over" position="end" [(opened)]="opened" >
      <triggered-nav-menu [menuItems]="links$ | async" (clicked)="opened = false"></triggered-nav-menu>
    </mat-drawer>

    <div id="triggered-layout-container" fxLayout="column">
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>

      <triggered-footer class="w-100" [displayLinks]="true"></triggered-footer>
    </div>

  </mat-drawer-container>
</div>
