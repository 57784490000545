import { ContactInfo } from '@triggered/common';
import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';


export interface IMentee extends ContactInfo {
  /**
   * The ID of the user we are supporting
   * @deprecated in favor of user.id
   */
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;

  /** The date the invite was accepted */
  acceptedDate: DateTimestamp | FirebaseTimestamp;

  /**
   * The reference to the user that is being mentored
   * @deprecated Use user instead
   */
  userRef?: any;

  user?: {
    id: string;
    ref: any;
  };
}

export interface Mentee extends IMentee { }
export class Mentee extends Model<IMentee> {
  acceptedDate: DateTimestamp;

  patchValue(value: Partial<Mentee>) {
    delete value.userRef;
    super.patchValue(value);
    this.acceptedDate = new DateTimestamp(this.acceptedDate);
  }
}
