
import { IDiscount, Timestamps } from '@triggered/common';
import { DateTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';
import { Frequency } from './plan';

/**
 * Representation of the Subscription object
 */
export interface ISubscriptionPricing extends Timestamps {
  /** The ID used to lookup this subscription settings */
  id: 'pricing'

  /** The total amount for this subscription */
  total: number;

  /** The total tax applied */
  totalTax?: number;

  /**
   * The total discount applied
   * @deprecated Use discount field
  */
  totalDiscount?: number;

  /**
   * The base price of this subscription.
   * NOTE: Used for group pricing
   */
  basePrice?: number;

  /**
   * The unit price per user for this subscription
   * NOTE: Used for group pricing
   */
  unitPrice?: number;

  /** The billing frequency */
  frequency?: Frequency;

  discount?: IDiscount;

  /** The ID of the coupon applied */
  coupon?: string;

  /** The promo code applied*/
  promoCode?: string;

  /** The ID of the trial applied */
  trialId?: string;

  /** The length of the trial perid */
  trialPeriod?: number;

  /**
   * When the trial ends
   * @deprecated Use discount
   */
  trialEndDate?: Date;
}

export interface SubscriptionPricing extends ISubscriptionPricing { }
export class SubscriptionPricing extends Model<SubscriptionPricing> {

  patchValue(model?: ISubscriptionPricing) {
    if(!model) { return; }
    super.patchValue(model);
    this.trialEndDate = new DateTimestamp(model?.trialEndDate || model?.discount?.discount?.trialEndDate);
    this.createdAt = new DateTimestamp(model.createdAt);
    this.updatedAt = new DateTimestamp(model.updatedAt);
  }

}

