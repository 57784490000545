import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as memoryCache from 'memory-cache';

@Injectable({
  providedIn: 'root'
})
export class CacheProvider {
  constructor() {
    console.debug('Using memory cache');
  }

  static getCacheOrAdd<TReturnType>(
    cacheArray: {[key: string]: TReturnType},
    keyLookup: string,
    ifNotFound: () => TReturnType) {
    if(!cacheArray[keyLookup]) {
      cacheArray[keyLookup] = ifNotFound();
    }
    // Return the cached element
    return cacheArray[keyLookup];
  }

  static getCachedOrQuery<TReturnType>(
    obsArray: {[key: string]: Observable<TReturnType>},
    keyLookup: string,
    ifNotFound: () => Observable<TReturnType>) {
    if(!obsArray[keyLookup]) {

      // This is the original, live connection
      obsArray[keyLookup] = ifNotFound().pipe(
        // Keep it active with sharedReplay
        shareReplay(1)
      );
    }

    // This is the cached response
    return obsArray[keyLookup];
  }

  static getCachedOrAdd<TReturnType>(cache: {[key: string]: TReturnType}, keyLookup: string, ifNotFound: () => TReturnType) {
    cache[keyLookup] = cache[keyLookup] ?? ifNotFound();
    return cache[keyLookup];
  }

  get<TType>(cacheId: string, key: string): TType {
    const cacheKey = `${cacheId}+${key}`;
    return memoryCache.get(cacheKey) as TType;
  }

  getOrAdd<TType>(cacheId: string, key: string, getValue: () => TType, durationSeconds?: number) {
    return this.get<TType>(cacheId, key) ?? this.cache(cacheId, key, getValue(), durationSeconds);
  }

  cache<TType>(cacheId: string, key: string, value: TType, durationSeconds: number = 60): TType {
    const cacheKey = `${cacheId}+${key}`;
    const msDuration = (durationSeconds * 1000) || 1; // Cache needs to at least be 1 ms
    memoryCache.put(cacheKey, value, msDuration);
    return value;
  }
}
