import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnChanges, OnInit, Renderer2, SimpleChanges, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { SettingsCard } from './settings-card.provider';

@Component({
  selector: 'triggered-settings-cards',
  templateUrl: './settings-cards.component.html',
  styleUrls: ['./settings-cards.component.scss']
})
export class SettingsCardsComponent implements OnChanges, AfterViewInit {

  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

  @Input() cards: SettingsCard[];

  constructor(
    private renderer: Renderer2,
    private componentResolver: ComponentFactoryResolver) { }


  async ngOnChanges(changes: SimpleChanges) {
    if(changes.cards && this.container) {
      this.updateCards();
    }
  }

  ngAfterViewInit() {
    this.updateCards();
  }

  private updateCards() {
    this.container?.clear();
    // SetTimeout is required here: https://github.com/angular/angular/issues/10762#issuecomment-239892457
    setTimeout(() => (this.cards || []).forEach(card => this.addComponent(card.component)), 1);
  }

  private addComponent(componentClass: Type<any>) {
    // Create component dynamically inside the ng-template
    const componentFactory = this.componentResolver.resolveComponentFactory(componentClass);
    const component = this.container.createComponent(componentFactory);
    this.renderer.addClass(component.location.nativeElement, 'settings-card-container');
  }
}
