import { ContactInfo, IGroup, IUser, Reference } from '@triggered/common';
import { Record } from '../../data/record';

/**
 * Representation of the SubscriptionUser object
 */
export interface ISubscriptionUser extends Record<ISubscriptionUser> {

  /** The unique ID of the user */
  id: string;

  /** The search fields to locate this user */
  search: string[];

  /** Relevant group information */
  group?: Reference<IGroup, {id: string}>;

  /** Relevant user information */
  user: Reference<IUser, ContactInfo>;

  /** This users permissions for this subscription */
  permissions?: {
    groups?: {
      [id: string]:  Reference<IGroup, {id: string, isAdmin: boolean}>;
    };
  }
}

export interface SubscriptionUser extends ISubscriptionUser { }
export class SubscriptionUser extends Record<ISubscriptionUser> {

}

