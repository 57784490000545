import { DocumentOptions } from '@triggered/common';

export type RecordFn<TRecord> = new (value?: Partial<TRecord>) => TRecord;

export class QueryHelper {
  static toString<TModel>(options?: QueryOptions<TModel>) {
    const filterString = options?.filters?.map(filter => `&filter=${filter.propertyName}${filter.operator}${filter.value}`).join() ?? '';
    const orderBy = options?.orderBy?.map(orderBy => `&sort=${orderBy.propertyName}(${orderBy.direction})`).join() ?? '';
    const limit = options?.limit ? `&limit=${options.limit}` : '';
    const startAfter = options?.startAfterSnapshot ? `&after=${options.startAfterSnapshot.id}` : '';

    const value = (filterString + orderBy + limit + startAfter).trim();
    if(value.length) {
      return `?` + value.substr(1, value.length - 1);
    }
    return value;
  }
}

export interface QueryOptions<TModel> extends DocumentOptions<TModel> {
  filters?: FilterOperation<TModel>[];
  orderBy?: OrderByOperation<TModel>[];

  /** The snapshot to start after */
  startAfterSnapshot?: any;

  /** Also pageSize */
  limit?: number;

  recordFn?: RecordFn<TModel>
}

export class OrderByOperation<TModel> {
  constructor(public propertyName: keyof TModel | 'uid', public direction: 'asc' | 'desc' = 'asc') {
  }
}

export class FilterOperation<TModel> {
  constructor(public propertyName: keyof TModel | 'uid', public operator: '==' | '<=' | '>=' | '>' | '<' | 'in' | 'not-in' | '!=' | 'array-contains' | 'array-contains-any', public value: any) {
  }
}
