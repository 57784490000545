import { Injectable } from '@angular/core';
import { FilterOperation, OrderByOperation, RecordFn, UserNotification } from '@triggered/common';
import { ApiService, Form } from '@triggered/core';
import { FirestoreDatabase, FirestorePaginator } from '@triggered/firestore';
import { take } from 'rxjs/operators';
import { AuthenticationCoreModule } from '../core.module';
import { UserNotificationForm } from '../models/user-notification.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: AuthenticationCoreModule
})
export class NotificationService {

  constructor(private readonly authService: AuthenticationService,
    private readonly database: FirestoreDatabase,
    private readonly apiService: ApiService) {
  }

  getPager<TRecord extends UserNotification>(userId: string, unreadOnly: boolean, recordFn: RecordFn<TRecord>): FirestorePaginator<TRecord> {
    if(!userId) { null; }

    const filters: FilterOperation<UserNotification>[] = [];
    if(unreadOnly === true) {
      filters.push(new FilterOperation('isRead', '==', false));
    }
    const orderBy = [new OrderByOperation<TRecord>('dateSent', 'desc')];
    const notifications = this.database.getCollection(`users/${userId}/notifications`, {recordFn});
    const pager = notifications.getPager({ filters, orderBy, recordFn });
    // const pager = this.notificationClient.getPager(userId, { filters, orderBy }, modelMap);
    return pager;
  }

  async markAsRead(userId: string, userNotification: UserNotification) {
    if(!userNotification) {
      throw new Error(`No notification sent in to mark as read.`);
    }

    if(userNotification.isRead) {
      return; // It has already been read
    }

    const user = await this.authService.user$.pipe(take(1)).toPromise();
    if(!user) {
      throw new Error(`User not initialized. Cannot mark notification ${userNotification?.id} as read`);
    }
    const form = new UserNotificationForm(userNotification);
    form.getControl('isRead').setValue(true);
    form.getControl('isRead').markAsDirty();

    const notification = this.database.getDocument(`users/${userId}/notifications/`, userNotification.id, UserNotification);
    notification.update(form);
  }

  async markAllAsRead(userId: string) {
    await this.apiService.post(`api/users/${userId}/notifications/mark-all-as-read`, new Form({}));
  }
}
