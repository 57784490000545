import { Injectable } from '@angular/core';
import { Document, IRecord } from '@triggered/common';
import { FirestoreDatabase } from './firestore.database';

@Injectable({
  providedIn: 'root'
})
export class FirestoreDocumentProvider  {

  constructor(private db: FirestoreDatabase) { }

  getDocument<TRecord extends IRecord>(
    recordFn: new(value?: Partial<TRecord>) => TRecord,
    collectionPath: string,
    documentId?: string,
    data?: TRecord
  ): Document<TRecord> {

    return this.db.getDocument(collectionPath, documentId, {recordFn});
  }
}
