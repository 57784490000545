
import { DateTimestamp, FirebaseTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';

export enum ResourceType {
  blog,
  podcast
}

export interface IResourceImageModel {
  imageId: string;
  url: string;
}

export interface ResourceImageModel extends IResourceImageModel { }
export class ResourceImageModel extends Model<ResourceImageModel> { }

export interface IResource extends IRecord {
   /** Whether the resource is displayed for end users */
   isActive: boolean;

   /** The unique path of the resource */
   path: string;

   /** The resource title */
   title: string;

   /** The resource subtitle */
   // readonly subtitle: string;

   /** The summary of this resource */
   summary: string;

   /** The type of resource this is */
   type: ResourceType;

   /** The category this resource belongs to */
   categories: string[];

   /** Who wrote the article */
   author: string;

   /** The date this article was written */
   dateWritten: DateTimestamp | FirebaseTimestamp;

   mainImage: IResourceImageModel;
}

export interface Resource extends IResource { }
export class Resource extends Record<Resource> {
  dateWritten: DateTimestamp;

  getDefaults(): Partial<Resource> {
    return { dateWritten: new Date() };
  }

  patchValue(model: Partial<Resource>) {
    if(model) {
      super.patchValue(model);
      this.dateWritten = new DateTimestamp(model.dateWritten);
      this.mainImage = new ResourceImageModel(model?.mainImage);
    }
  }
}
