
<mat-drawer-container #drawerContainer class="w-100 h-100" [autosize]="autoSize" [hasBackdrop]="false">

  <!-- MODE Should be 'over' when mobile -->
  <mat-drawer #drawer [mode]="mode" disableClose="true" [opened]="opened">
    <div class="drawer-menu mat-elevation-z1 h-100 bg-card">
      <button id="sidenav-menu-expander" mat-mini-fab (click)="expanded = !expanded" color="warn">
        <mat-icon aria-label="Menu">{{expanded ? 'chevron_left' : (isMobile ? 'settings' : 'chevron_right')}}</mat-icon>
      </button>

      <triggered-nav-menu *ngIf="showMenu" [menuItems]="links" [collapsed]="!expanded"></triggered-nav-menu>
    </div>
  </mat-drawer>

  <router-outlet></router-outlet>
</mat-drawer-container>
