/* eslint-disable @typescript-eslint/no-explicit-any */

import { DateTimestamp, FirebaseTimestamp } from './date-timestamp';


export type DeepPartial<T> =
  // Check if T is an object
  T extends object
    // Skip the following types
    ? T extends DateTimestamp | FirebaseTimestamp
      ? T
      : { [P in keyof T]?: DeepPartial<T[P]>; }


    // Just return the type T since it is not an object
    : T


export abstract class Model<TType = Model<any>> {

  static create<TModel extends Model>(model: (new (value?: TModel) => TModel), value: TModel): TModel {
    return new model(value);
  }

  constructor(originalValue?: DeepPartial<TType> | Partial<TType>) {
    Object.assign(this, this.getDefaults());
    if (originalValue) {
      this.patchValue(originalValue);
    }
  }

  patchValue(model?: DeepPartial<TType> | Partial<TType>) {
    if (!model) { return; }
    Object.assign(this, model);
  }


  /** @deprecated No longer relevant with ord */
  getDefaults(): DeepPartial<any> | Partial<TType> { return {}; }

  clean(): any {
    return this.removeEmpty(this);
  }

  private removeEmpty(obj: any): any {
    return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? this.removeEmpty(v) : v }),
      {}
    );
  }
}
