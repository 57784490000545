<mat-nav-list class="padding-0" [@.disabled]="disableAnimation">
  <!-- Enumerate each menu item-->
  <ng-container *ngFor="let menuItem of menuItems">

    <ng-container *ngIf="!menuItem?.isVisible$ || (menuItem.isVisible$ | async)">

      <!-- If the link is an individual link, display as so-->
      <ng-container *ngIf="!menuItem.children?.length; else subMenu">
        <template [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{link: menuItem, depth: 0}"></template>
        <mat-divider></mat-divider>
      </ng-container>


      <!-- If the menu item has child items, display submenu -->
      <ng-template #subMenu>
        <template [ngTemplateOutlet]="expandedList" [ngTemplateOutletContext]="{link: menuItem, depth: 0}"></template>
        <mat-divider></mat-divider>
      </ng-template>

    </ng-container>

  </ng-container>
</mat-nav-list>


<ng-template #listItem let-link="link" let-isFirst="isFirst">
  <ng-container *ngIf="!link?.isVisible$ || (link.isVisible$ | async)">

    <mat-list-item *ngIf="link" [routerLink]="link.routerLink" [routerLinkActiveOptions]="{exact: link.exactLinkActive}" [routerLinkActive]="link.routerLinkActive || ['text-primary', 'text-bold']" (click)="onClick(link)" [class.text-primary]="link.color === 'primary'" [class.text-accent]="link.color === 'accent'">
      <mat-icon mat-list-icon *ngIf="link.icon" class="material-icons-outlined padding-0">{{link.icon}}</mat-icon>
      <h4 mat-line *ngIf="!collapsed"> {{link.display}}</h4>
    </mat-list-item>

  </ng-container>
</ng-template>

<ng-template #expandedList let-link="link" let-depth="depth">
  <mat-expansion-panel class="submenu-expander mat-elevation-z0" [class]="'depth-' + depth" [expanded]="link.expanded || false">
    <mat-expansion-panel-header [class.text-primary]="link.color === 'primary'" [class.text-accent]="link.color === 'accent'">
      <mat-panel-title>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
          <mat-icon *ngIf="link.icon" class="material-icons-outlined">{{link.icon}}</mat-icon>
          <span *ngIf="!collapsed">{{link.display}} </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <ng-container *ngFor="let childItem of link.children; first as isFirst; last as isLast">
      <ng-container *ngIf="!childItem?.isVisible$ || (childItem.isVisible$ | async)">

        <!-- If the link is an individual link, display as so-->
        <ng-container *ngIf="!childItem.children?.length; else subMenu">
          <template [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{link: childItem, depth: depth + 1}"></template>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>


        <!-- If the menu item has child items, display submenu -->
        <ng-template #subMenu>
          <template [ngTemplateOutlet]="expandedList" [ngTemplateOutletContext]="{link: childItem, depth: depth + 1}"></template>
          <mat-divider></mat-divider>
        </ng-template>

      </ng-container>

      <!-- <template [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{link: childItem, isFirst: isFirst}"></template> -->
    </ng-container>

  </mat-expansion-panel>
</ng-template>
