import { Component, forwardRef, Input, EventEmitter, Attribute, ContentChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormField } from '../form-field';
import { Output } from '@angular/core';

@Component({
  selector: 'triggered-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true,
    }
  ]
})
export class TextboxComponent extends FormField {
  @Input() hint: string;

  @Input() maxLength: string;

  @Input() label: string;

  @Input() prefix: string;

  @ContentChild('prefix', { static: true})  prefixTemplate: TemplateRef<any>;
  @ContentChild('suffix', { static: true}) suffixTemplate: TemplateRef<any>;
  @ContentChild('hint', { static: true}) hintTemplate: TemplateRef<any>;

  @Input() suffix: string;

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public readonly: boolean;

  @Input()
  public placeholder = '';

  @Output()
  public readonly blur = new EventEmitter<void>();

  constructor(
    @Attribute('type') public readonly type: string,
    sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }
}
