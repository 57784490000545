import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { NewsletterSubscriptionForm, NewsletterSubscriptionModel } from '../../core/models/newsletter-subscription.model';
import { NewsletterService } from '../../services/newsletter.service';

@Component({
  selector: 'triggered-sendgrid-form',
  templateUrl: './sendgrid.form.html',
  styleUrls: ['./sendgrid.form.scss']
})
export class SendgridForm implements OnChanges {


  isSubmitting: boolean;
  readonly form: NewsletterSubscriptionForm = new NewsletterSubscriptionForm();

  @Input() displayName: boolean = true;
  @Input() newsletterIds: string[];

  @Output() readonly cancelled = new EventEmitter();
  @Output() readonly submitted = new EventEmitter<NewsletterSubscriptionModel>();
  @Output() readonly errored = new EventEmitter<Error>();

  constructor(private readonly newsletterService: NewsletterService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.newsletterIds) {
      this.form.getControl('newsletterIds').setValue(this.newsletterIds || []);
    }
  }

  async onSubmit() {
    if(this.form.invalid){
      return this.form.markAllAsDirty();
    }

    this.isSubmitting = true;
    const response = await this.newsletterService.signUp(this.form)
      .catch(error => {
        console.error(error)
        this.errored.next(error);
      }); // TODO
    if(response){
      this.submitted.emit(new NewsletterSubscriptionModel(this.form.value));
      this.form.reset();
    }
    this.isSubmitting = false;
  }

}
