// Angular Modules
import { Inject, InjectionToken, ModuleWithProviders, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { UniversalHostProvider } from './services/universal-host.provider';
const imporModules = [ CommonModule, MatIconModule ];
const exportModules = [ MatIconModule ];

// Internal Modules
import { ErrorReporter } from '@triggered/core';
import { TriggeredIconsServicesModule } from './services/services.module';
const internalModules = [TriggeredIconsServicesModule];

export const UIIconsForRootToken = new InjectionToken<boolean>('UIForRootToken');

@NgModule({
  declarations: [],
  imports: [
    ...imporModules,
    ...internalModules
  ],
  exports: [ ...exportModules ]
})
export class TriggeredIconsModule {
  static forRoot(): ModuleWithProviders<TriggeredIconsModule> {
    return {
      ngModule: TriggeredIconsModule,
      providers: [
        { provide: UIIconsForRootToken, useValue: true },
      ]
    };
  }

  readonly hostName: string;

  constructor(
    @Inject(UIIconsForRootToken) @Optional() isForRoot: boolean, private registry: MatIconRegistry, private domSanitizer: DomSanitizer,
    private hostProvider: UniversalHostProvider, @Inject(PLATFORM_ID) private platformId: string) {
    if (isForRoot) {
      this.hostName = isPlatformServer(this.platformId) ? this.hostProvider.host : '';
      console.log(`Registering icons forRoot(). Hostname is ${this.hostName}`);
      this.registerIcon('facebook-1', 'assets/icons/facebook-1.svg');
      this.registerIcon('facebook-2', 'assets/icons/facebook-2.svg');
      this.registerIcon('linked-in-1', 'assets/icons/linked-in.svg');
      this.registerIcon('twitter-1', 'assets/icons/twitter.svg');
      this.registerIcon('pinterest-1', 'assets/icons/pinterest.svg');
      this.registerIcon('google-plus-1', 'assets/icons/google-plus.svg');
      this.registerIcon('you-tube-1', 'assets/icons/youtube.svg');
      this.registerIcon('instagram-1', 'assets/icons/instagram.svg');
      this.registerIcon('google-1', 'assets/icons/google.svg');


      this.registerIcon('quote', 'assets/icons/icons8-quote.svg');
      this.registerIcon('quote-outlined', 'assets/icons/icons8-quote-outlined.svg');
      this.registerIcon('testflight-app', 'assets/icons/icons8-testflight.svg');
      this.registerIcon('android-os', 'assets/icons/icons8-android-os-50.svg');
      this.registerIcon('apple-logo', 'assets/icons/icons8-apple-logo-50.svg');
      this.registerIcon('app-store', 'assets/icons/icons8-app-store-50.svg');
      this.registerIcon('chrome', 'assets/icons/icons8-chrome-50.svg');
      this.registerIcon('google-play', 'assets/icons/icons8-google-play-50.svg');
      this.registerIcon('ios-logo', 'assets/icons/icons8-ios-logo-50.svg');
      this.registerIcon('windows-client', 'assets/icons/icons8-windows-client-50.svg');

      this.registerIcon('download-ios', 'assets/icons/download_app_store.svg');
      this.registerIcon('download-android', 'assets/icons/download_play_store.svg');

      // Friends/Family Links
      this.registerIcon('friend', 'https://firebasestorage.googleapis.com/v0/b/solid-apps-triggered.appspot.com/o/triggered%2Fassets%2Ficons%2Ffriend.svg?alt=media');
      this.registerIcon('friends', 'https://firebasestorage.googleapis.com/v0/b/solid-apps-triggered.appspot.com/o/triggered%2Fassets%2Ficons%2Ffriends.svg?alt=media');
      this.registerIcon('family', 'https://firebasestorage.googleapis.com/v0/b/solid-apps-triggered.appspot.com/o/triggered%2Fassets%2Ficons%2Ffamily.svg?alt=media');


    }
  }

  private registerIcon(name: string, path: string) {
    try {
      this.registry.addSvgIcon(name, this.getSafeUrl(path));
    } catch(error) {
      ErrorReporter.report(error, { message: `Failed to register icon ${name} from ${path}.`});

      if(this.hostName) {
        /* Register empty icons for server-side-rendering to prevent errors */
        // https://github.com/angular/components/issues/9728#issuecomment-592402707
        console.log('Registering empty icon for SSR')
        this.registry.addSvgIconLiteral(name, this.domSanitizer.bypassSecurityTrustHtml('<svg></svg>'));
      }
    }
  }

  private getSafeUrl(relativePath: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.hostName}${relativePath}`);
  }

 }
