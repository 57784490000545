import { ComponentFactoryResolver, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { MatDivider } from '@angular/material/divider';

@Directive({
  selector: 'div[header]',

})
export class HeaderDirective implements OnChanges {

  @Input('header') header: string;
  @Input() subheader: string;
  @Input() type: 'section' | 'card'= 'section';
  @Input() hideDivider: boolean = false;
  @Input() wrap: boolean = false;

  private headerElement: any;
  subheaderElement: any;
  dividerElement: any;
  constructor(private viewContainerRef: ViewContainerRef, private resolver: ComponentFactoryResolver, private element: ElementRef, private render: Renderer2) {

  }

  ngOnChanges(changes: SimpleChanges) {
    // TODO: We may need to consider redrawing each time
    if(changes.header) {
      const headerInfo = this.getHeaderInfo();
      this.headerElement = this.header
        ? this.updateHeader(this.headerElement, headerInfo.element, this.header, headerInfo.classes)
        : this.removeChild(this.headerElement);
    }
    if(changes.subheader) {
      const subheaderInfo = this.getSubheaderInfo();
      this.subheaderElement = this.subheader
        ? this.updateHeader(this.subheaderElement, subheaderInfo.element, this.subheader, subheaderInfo.classes)
        : this.removeChild(this.subheaderElement);
    }
    if(changes.header || changes.subheader || changes.type) {
      this.render.addClass(this.element.nativeElement, 'w-100')
      if(this.hideDivider !== true) {
        this.addDivider();
      }
    }
  }

  private updateHeader(existingElement: any, elementType: string, text: string, classes: string) {
    const element = existingElement || this.render.createElement(elementType);
    this.updateText(element, text, classes);
    this.render.appendChild(this.element.nativeElement, element);
    return element;
  }


  private getHeaderInfo() {
    switch(this.type) {
      case 'card': return { element: 'h3', classes: `mat-h3 margin-0 ${ this.wrap ? ''  : 'text-ellipsis'}` };
      default: return {element: 'h1', classes: 'mat-title margin-0' };
    }
  }

  private getSubheaderInfo() {
    switch(this.type) {
      case 'card': return { element: 'h4', classes: `mat-caption margin-0 ${ this.wrap ? ''  : 'text-ellipsis'}` };
      default: return {element: 'h2', classes: 'mat-subheading-2 margin-0' };
    }
  }

  private addDivider() {
    const factory = this.resolver.resolveComponentFactory(MatDivider);
    this.dividerElement = this.viewContainerRef.createComponent(factory);
    this.render.addClass(this.dividerElement.location.nativeElement, 'margin-top-1');
    this.render.appendChild(this.element.nativeElement, this.dividerElement.location.nativeElement);
  }

  private removeChild(element: any) {
    if(!element) { return null; }
    this.render.removeChild(this.element, element);
    return null;
  }

  private updateText(element: any, text: string, classes: string) {
    (classes || '').split(' ').filter(c => c != '').forEach(c => this.render.addClass(element, c));
    this.render.setProperty(element, 'innerHTML', text);
  }
}
