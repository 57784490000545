import { DateTimestamp } from '../../data/date-timestamp';
import { Model } from '../../data/model';
import { IRecord, Record } from '../../data/record';

export const Paths = {
  documentId: 'stripe',
  collectionPath: (userId: string) => `users/${userId}/settings`,
  documentPath: (userId: string) => `users/${userId}/settings/stripe`,
}

export interface ICard {
  /** The unique payment method identifer */
  id: string;
  isDefault: boolean;
  type: string;
  last4: string;
  expirationMonth: number;
  expirationYear: number;
}

export interface Card extends ICard { }
export class Card extends Model<Card> {
  expirationDate: DateTimestamp;

  patchValue(model?: Partial<Card>) {
    super.patchValue(model);
    if(!model) { return; }
    if(model.expirationYear && model.expirationMonth) {
      this.expirationDate = new DateTimestamp(new Date(model.expirationYear, model.expirationMonth, 0))
    }
  }
}

export interface IUserStripeSettings extends IRecord {
  cards: ICard[];
}

export interface UserStripeSettings extends IUserStripeSettings {}
export class UserStripeSettings extends Record<UserStripeSettings> {
  cards: Card[];

  constructor(model?: Partial<UserStripeSettings>) {
    // Order of operations
    // 1.) super(model) is called first and sets base properties
    super(model);

    // 2.) Property initializers run and reset properties to initiated values
    // 3.) Patch value runs and sets the properties
    this.patchValue(model);
  }

  patchValue(model?: Partial<UserStripeSettings>) {
    super.patchValue(model);
    if(!model) { return; }
    this.cards = (model.cards || []).map(card => new Card(card));
  }
}
