import { Component, OnInit } from '@angular/core';
import { DialogService, PromptData, PromptDialog } from '@triggered/ui';
@Component({
  selector: 'triggered-chrome-extension',
  templateUrl: './chrome-extension.view.html',
  styleUrls: ['./chrome-extension.view.scss']
})
export class ChromeExtensionView implements OnInit {


  constructor(private readonly dialogService: DialogService) { }

  ngOnInit(): void {
  }

  async onSubmitted(model: any){
    await PromptDialog.open(this.dialogService, new PromptData('Congratulations', 'You have taken the first step in overcomming with Triggered. \n  We will inform you of product updates and when Triggered is available', 'Ok'));
  }
}
