import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
const angularModules = [
  CommonModule, FlexLayoutModule,
  MatDividerModule, MatProgressSpinnerModule
];

// Triggered Modules
import { TriggeredCardModule } from '../card/card.module';
const triggeredModules = [ TriggeredCardModule ];

import { SettingsCardsComponent } from './settings-cards.component';
const externalComponents = [ SettingsCardsComponent ];

@NgModule({
  declarations: [...externalComponents ],
  exports: [ ...externalComponents, ...triggeredModules ],
  imports: [
    ...angularModules,
    ...triggeredModules
  ]
})
export class TriggeredSettingsCardsModule { }
