import { Record } from '../../data/record';

export const userCollectionPath = () => `users`;
export const userDocumentPath = (userId: string) => `users/${userId}`;

/**
 * Representation of the Subscription object
 */
export interface IUser extends Record<IUser> {

  /** The user Id  */
  id: string;

  /** The user's unique identifier in auth */
  uid: string;

  /** The user's first name */
  firstName: string;

  /** The user's last name */
  lastName: string;

  /** The user's email address */
  email: string;

  /** The user's phoneNumber */
  phoneNumber: string;

  /** The user's time-zone */
  timezone?: string;

  /** Whether this user is an admin */
  isAdmin: boolean;

  /** Whether this user is a beta user */
  isBetaUser: boolean;

  /** The unique search strings for this user */
  search?: string[];

  /**
   * The user's identifier in stripe.
   * @deprecated Replace with a settings/stripe record instead
   */
  stripeCustomerId?: string;
}

export interface User extends IUser { }
export class User extends Record<IUser> {
  hasCompleteProfile() {
    const requiredFields = [this.firstName, this.lastName, this.email, this.phoneNumber];
    return requiredFields.every(value => (value || '').trim() != null)
  }
}
