import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig, APP_CONFIG, ErrorReporter } from '@triggered/core';
import { PrivacyPolicyComponent, TermsAndConditionsComponent } from '@triggered/legal';
import { DialogService, PromptData, PromptDialog } from '@triggered/ui';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Login, LoginForm } from '../../core/models/login.model';
import { User, UserForm } from '../../core/models/user.model';
import { AuthenticationService } from '../../core/services/authentication.service';

export enum LoginSteps {
  SelectLoginMethod = 0,
  EmailLogin = 1,
  EmailSignup = 2,
  Profile = 3,
}

export class Step {
  id: LoginSteps;
  header: string;
  subheader: string;
  isComplete$: Observable<boolean>;
  isAvailable$: Observable<boolean>;
}

@Injectable()
export class LoginStepService {

  readonly steps: Step[];
  readonly initialStep: Promise<Step>;
  readonly isComplete$: Observable<boolean>;

  currentIndex: LoginSteps = LoginSteps.SelectLoginMethod;
  currentStep: Step;

  constructor(readonly authService: AuthenticationService) {
    const isLoggedIn$ = authService.isLoggedIn$;
    const isLoggedOut$ = isLoggedIn$.pipe(map(isLoggedIn => !isLoggedIn));
    const isProfileComplete$ = authService.user$.pipe(map(user => {
      if(!user) { return false; }
      const userForm = new UserForm(user);
      return userForm.valid;
    }));

    this.steps = [
      {
        id: LoginSteps.SelectLoginMethod,
        header: 'Discover Freedom',
        subheader: 'Login or Sign Up',
        isComplete$: isLoggedIn$,
        isAvailable$: isLoggedOut$
      },
      {
        id: LoginSteps.EmailLogin,
        header: 'Discover Freedom',
        subheader: 'Login with Email',
        isComplete$: isLoggedIn$,
        isAvailable$: isLoggedOut$
      },
      {
        id: LoginSteps.EmailSignup,
        header: 'Discover Freedom',
        subheader: 'Sign up with your Email',
        isComplete$: isLoggedIn$,
        isAvailable$: isLoggedOut$
      },
      {
        id: LoginSteps.Profile,
        header: 'Profile Info',
        subheader: 'Complete your Profile',
        isComplete$: isProfileComplete$,
        isAvailable$: isLoggedIn$
      }
    ];

    this.initialStep = this.goToNext();
  }

  async goToNext() {
    const isCompletePromises = this.steps.map(step => step.isComplete$.pipe(take(1), map(isComplete => ({isComplete, step}))).toPromise());
    const completedSteps = await Promise.all(isCompletePromises);

    const firstIncomplete = completedSteps.find(step => !step.isComplete) || completedSteps[completedSteps.length - 1];
    await this.goToStep(firstIncomplete?.step?.id);
    return this.currentStep;
  }

  async goToStep(loginStep: LoginSteps) {
    const step = this.steps.find(s => s.id === loginStep);
     if(!step) { return; }

    const isAvailable = await step.isAvailable$.pipe(take(1)).toPromise();
    if(isAvailable) {
      this.currentStep = step;
      this.currentIndex = step.id.valueOf();
    }
  }
}

@Component({
  selector: 'triggered-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  readonly Steps = LoginSteps;
  readonly loginForm = new LoginForm();
  readonly signUpForm = new LoginForm(null, { requirePasswordConfirmation: true });

  isSubmitting: boolean = false;
  isSubmittingWithGoogle: boolean = false;
  isSubmittingWithApple: boolean = false;
  isEmail: boolean = false;

  @Input() requireProfile: boolean;
  @Output() readonly afterLogin = new EventEmitter<User>();
  @Output() readonly cancelled = new EventEmitter();

  constructor(readonly stepper: LoginStepService,
              private readonly router: Router,
              private readonly dialogService: DialogService,
              private readonly authService: AuthenticationService,
              @Inject(APP_CONFIG) private readonly appConfig: AppConfig) {
    this.stepper.goToNext();
  }

  async onAppleLogin() {
    this.isSubmitting = true;
    this.isSubmittingWithApple = true;
    const user = await this.authService.signInWithApple()
      .catch(error => {
        ErrorReporter.report(error, { message: `LoginComponent: Error logging in with Apple`});
        return null;
      });

    await this.afterLoggedIn(user);
    this.isSubmitting = false;
    this.isSubmittingWithApple = false;
  }

  async onGoogleLogin() {
    this.isSubmitting = true;
    this.isSubmittingWithGoogle = true;
    const user = await this.authService.signInWithGoogle()
      .catch(error => {
        ErrorReporter.report(error, { message: `LoginComponent: Error logging in with Google`});
        return null;
      });

    await this.afterLoggedIn(user);
    this.isSubmitting = false;
    this.isSubmittingWithGoogle = false;
  }

  async onSignupWithEmail(form: LoginForm) {
    if(form.invalid) { return form.markAllAsDirty(); }
    const login: Login = form.value;

    this.isSubmitting = true;
    const user = await this.authService.signUpWithPassword(login.email, login.password)      .catch(error => {
      if(error?.code === 'auth/email-already-in-use') {
        form.getControl('email').setErrors({ custom: 'An account with this email already exists.' });
        this.stepper.goToStep(LoginSteps.EmailSignup);
      }
      ErrorReporter.report(error, { message: `LoginComponent: Error signing up with Email`});
      return null;
    });
    await this.afterLoggedIn(user);
    this.isSubmitting = false;
  }

  private async afterLoggedIn(user: User){
    if(user) {
      // User signed in
      if(this.requireProfile) {
        await this.stepper.goToStep(LoginSteps.Profile);
      } else {
        // Make sure user has been updated before going forward
        await this.authService.user$.pipe(filter(u => u?.id === user.id), take(1)).toPromise();
        this.afterLogin.emit(user);
      }
    }
  }

  async onForgotPassword(form: LoginForm) {
    const emailControl = form.getControl('email');
    if(!emailControl.valid) {
      return emailControl.markAsDirty();
    }

    const returnUrl = this.appConfig.endpoints.baseAppUrl + this.router.url;
    await this.authService.forgotPassword(emailControl.value, returnUrl)
      .catch(error => {
        ErrorReporter?.report(error, { message: 'LoginComponent: there was an error resetting password. '});
        PromptDialog.open(this.dialogService, new PromptData('Error', `There was an error resetting your password`, 'Ok'));
      });
    await PromptDialog.open(this.dialogService, new PromptData('Sent', `A password reset has been sent to ${emailControl.value}. Please check your email.`, 'Ok'));
    await this.stepper.goToStep(LoginSteps.SelectLoginMethod);

  }

  async onCheckEmail(form: LoginForm) {
    const emailControl = form?.getControl('email');
    if(!emailControl?.valid) {
      return emailControl?.markAsDirty();
    }
    this.isSubmitting = true;
    const emailMethods = await this.authService.fireAuth.fetchSignInMethodsForEmail(emailControl.value);
    const emailExists = emailMethods?.length > 0;

    if(emailExists) {
      if(emailMethods.indexOf('password') >= 0){
        // They have logged in with password before. Send them to password screen
        this.stepper.goToStep(LoginSteps.EmailLogin);
      } else if(emailMethods.indexOf('google.com') >= 0){
        // They have logged in with Google before. Prompt them to sign in with Google
        await this.authService.signInWithGoogle()
          .then(async(user) => await this.afterLoggedIn(user))
          .catch(error => {
            ErrorReporter?.report(error, { message: 'LoginComponent: Attempted to sign in with google after user attempted email/password. '});
            const msg = `You have previously logged in with Google and cannot change to email/password combination. Please try to sign in with Google or contact us if you have further issues.`;
            PromptDialog.open(this.dialogService, new PromptData('Error', msg, 'Ok'));
          });
      } else if(emailMethods.indexOf('apple.com') >= 0) {
        await this.authService.signInWithApple()
          .then(async(user) => await this.afterLoggedIn(user))
          .catch(error => {
            ErrorReporter?.report(error, { message: 'LoginComponent: Attempted to sign in with apple after user attempted email/password. '});

            const msg = `You have previously logged in with Apple and cannot change to email/password combination. Please try to sign in with Apple or contact us if you have further issues.`;
            PromptDialog.open(this.dialogService, new PromptData('Error', msg, 'Ok'));
          });
      } else {
        ErrorReporter?.report(new Error('LoginComponent: Unknown error. User logging in with password but no loginMetho exists'), { emailMethods });
        this.stepper.goToStep(LoginSteps.EmailLogin);
      }

    } else {
      // They have not logged in with password before. Send them to email signup
      this.signUpForm.reset(form.value);
      this.stepper.goToStep(LoginSteps.EmailSignup);
    }
    this.isSubmitting = false;
  }

  async onLoginWithEmail(form: LoginForm) {
    if(form.invalid) { return form.markAllAsDirty(); }

    this.isSubmitting = true;
    const login = form.value as Login;

    const user = await this.authService.signInWithPassword(login.email, login.password)
      .catch(error => {
        if(error?.code === 'auth/user-not-found') {
          form.getControl('email').setErrors({ custom: 'This email account was not found.' });
          this.stepper.goToStep(LoginSteps.EmailSignup);
        } else if(error?.code === 'auth/wrong-password') {
          form.getControl('email').setErrors({ custom: 'Your password is invalid. Please try again or login using Google' });
        }
        console.log(error);
        return null;
      });

    await this.afterLoggedIn(user);
    this.isSubmitting = false;
  }

  async onOpenTermsAndConditions() {
    this.dialogService.openComponent(TermsAndConditionsComponent);
  }

  async onOpenPrivacyPolicy() {
    this.dialogService.openComponent(PrivacyPolicyComponent);
  }

  onAfterProfileUpdated(user: User) {
    this.afterLogin.emit(user);
  }

  async onCancelProfileInfo() {
    this.cancelled.emit();
  }

  async onAfterLoggedOut() {
    await this.stepper.goToStep(this.Steps.SelectLoginMethod)
  }
}
