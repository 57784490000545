import { Component, forwardRef, Input, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormField } from '../form-field';
import { Output } from '@angular/core';

@Component({
  selector: 'triggered-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    }
  ]
})
export class CheckboxComponent  extends FormField {

  @Input() hint: string;
  @Input() label: string;
  @Input() readonly: boolean;

  @Output() readonly blur = new EventEmitter<void>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }

  toggle() {
    this.formControl?.setValue(!this.value);
  }
}
